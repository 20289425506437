<ng-container *ngIf="componentName === connexStatus.CREATE_REQUEST">
  <div *ngIf="loading" class="grv-text--medium-large connex">
    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="40%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>

    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>

        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="componentName === connexStatus.DELETE_REQUEST">
  <div *ngIf="loading" class="grv-text--medium-large connex">
    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>

        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="componentName === connexStatus.DOWNLOAD_IN_PROGRESS || componentName === connexStatus.DELETE_IN_PROGRESS"
>
  <div *ngIf="loading" class="grv-text--medium-large connex">
    <c1-privacy-connex-placeholder width="100%" height="16px" spacing="57px"></c1-privacy-connex-placeholder>
    <c1-privacy-connex-placeholder width="30%" height="16px" spacing="16px"></c1-privacy-connex-placeholder>

    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="40%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>

    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>

        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="componentName === connexStatus.DOWNLOAD_READY">
  <div *ngIf="loading" class="grv-text--medium-large connex">
    <c1-privacy-connex-placeholder width="50%" height="24px" spacing="52px"></c1-privacy-connex-placeholder>

    <c1-privacy-connex-placeholder width="100%" height="16px" spacing="20px"></c1-privacy-connex-placeholder>
    <c1-privacy-connex-placeholder width="60%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>

    <c1-privacy-connex-placeholder width="40%" height="16px" spacing="47px"></c1-privacy-connex-placeholder>

    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="40%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="50%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>

    <div class="categories-container">
      <div class="header-img hidden-small">
        <c1-privacy-connex-placeholder width="36px" height="36px" spacing="0px"></c1-privacy-connex-placeholder>
      </div>
      <div class="header-contain mobile-margin">
        <c1-privacy-connex-placeholder width="50%" height="24px" spacing="8px"></c1-privacy-connex-placeholder>

        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      </div>
    </div>

    <div class="asset-section">
      <hr class="grv-divider--small grv-divider--dark" aria-hidden="true" />
      <c1-privacy-connex-placeholder width="40%" height="16px" spacing="16px"></c1-privacy-connex-placeholder>
      <div class="asset-container">
        <div class="left-column">
          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="0px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>

          <c1-privacy-connex-placeholder width="60%" height="16px" spacing="24px"></c1-privacy-connex-placeholder>

          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="20px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        </div>
        <div class="right-column">
          <c1-privacy-connex-placeholder width="100%" height="16px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>

          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="52px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
          <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
        </div>
      </div>

      <c1-privacy-connex-placeholder width="80%" height="16px" spacing="46px"></c1-privacy-connex-placeholder>
      <c1-privacy-connex-placeholder width="100%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      <c1-privacy-connex-placeholder width="60%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
      <c1-privacy-connex-placeholder width="80%" height="16px" spacing="10px"></c1-privacy-connex-placeholder>
    </div>
  </div>
</ng-container>
