<c1-privacy-banner
  class="maintenance-banner grv-margin--none"
  [alertType]="'GLOBAL'"
  [canClose]="false"
  [showMaintenance]="true"
  *ngIf="maintenance"
>
</c1-privacy-banner>
<c1-privacy-subheader-with-card [subheaderContent]="subheaderContent" class="c1-privacy-in-progress__main">
  <div>
    <c1-privacy-card>
      <div class="grv-padding__left--medium-2 grv-padding__right--medium-2">
        <h2 class="grv-text--medium-large">
          {{ "RIGHT_TO_CORRECT.ACCOUNT_CORRECTION_HEADER" | translate }}
        </h2>
        <p class="grv-text--normal-semibold my-0">
          {{ "RIGHT_TO_CORRECT.ACCOUNT_CORRECTION_SUBHEADER" | translate }}
        </p>
        <p class="grv-text--normal-normal">
          {{ "RIGHT_TO_CORRECT.ACCOUNT_CORRECTION_DESCRIPTION" | translate }}
        </p>
        <ul class="grv-list" role="list">
          <li class="grv-list__item" role="listitem">
            <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
              <a
                class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none link"
                data-cy="personal-accounts-link"
                (click)="clickPersonalAccounts()"
              >
                {{"RIGHT_TO_CORRECT.PERSONAL_ACCOUNT_LINK_TEXT" | translate}}
              </a>
            </p>
          </li>
          <li class="grv-list__item" role="listitem">
            <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
              <a
                class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none link"
                data-cy="business-accounts-link"
                (click)="clickBusinessAccounts()"
              >
                {{"RIGHT_TO_CORRECT.BUSINESS_ACCOUNTS_LINK_TEXT" | translate}}
              </a>
            </p>
          </li>
          <li class="grv-list__item" role="listitem">
            <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
              <a
                class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none link"
                data-cy="commercial-accounts-link"
                (click)="clickCommercialAccounts()"
              >
                {{"RIGHT_TO_CORRECT.COMMERCIAL_ACCOUNTS_LINK_TEXT" | translate}}
              </a>
            </p>
          </li>
        </ul>
      </div>
    </c1-privacy-card>
  </div>
  <div class="grv-margin__top--normal">
    <c1-privacy-card>
      <div class="grv-padding__left--medium-2 grv-padding__right--medium-2">
        <h2 class="grv-text--medium-large">
          {{ "RIGHT_TO_CORRECT.DOWNLOAD_CORRECTION_HEADER" | translate }}
        </h2>
        <p class="grv-text--normal-normal my-0">
          {{ "RIGHT_TO_CORRECT.DOWNLOAD_CORRECTION_DESCRIPTION" | translate }}
          <a
            class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none link"
            tabindex="0"
            data-cy="go-to-correction"
            role="link"
            (keyup.enter)="goToIntakePage()"
            (click)="goToIntakePage()"
          >{{ "RIGHT_TO_CORRECT.DOWNLOAD_CORRECTION_LINK_TEXT" | translate }}</a>.
          {{ "RIGHT_TO_CORRECT.DOWNLOAD_CORRECTION_AFTER_LINK_TEXT" | translate }}
        </p>
      </div>
    </c1-privacy-card>

    <c1-privacy-ineligible-request-dialog></c1-privacy-ineligible-request-dialog>
  </div>
  <div class="grv-margin__top--large-1">
    <a
      href="javascript:void(0)"
      type="button"
      (click)="goBackToPreviousRoute()"
      class="grv-button--regressive back-button"
      data-cy="back-button"
      tabindex="0"
      (keyup.enter)="goBackToPreviousRoute()"
      (keydown.space)="goBackToPreviousRoute()"
    >{{ "DELETE_REQUEST.BACK_BUTTON" | translate }}</a>
  </div>
</c1-privacy-subheader-with-card>
