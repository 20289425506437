import { Component, OnInit, ViewChild, OnDestroy, Inject } from "@angular/core"
import { DialogWrapper } from "../dialog/dialog-wrapper"
import { DialogComponent } from "../dialog/dialog.component"
import { SubheaderContent } from "../subheader/SubheaderContent"
import { AuthenticationService } from "src/app/services/auth/authentication.service"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { Router } from "@angular/router"

@Component({
  selector: "c1-privacy-session-dialog",
  templateUrl: "./session-dialog.component.html",
  styleUrls: ["./session-dialog.component.scss"],
})
export class SessionDialogComponent implements OnInit, DialogWrapper, OnDestroy {
  @ViewChild(DialogComponent) dialog: DialogComponent

  private countdownInterval: any
  public countdownInSeconds: number
  public countdownTimerMinutes: any
  public countdownTimerSeconds: any
  public isShowing: boolean
  subheaderContent: SubheaderContent = {
    icon: "clock",
    headerText: "SESSION_DIALOG.HEADER",
  }
  private config: AppConfig

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
    this.config = config
  }

  padZero = (n) => {
    return(n<10 ? "0"+n:n)
  }


  startCountDown() {
    this.countdownInSeconds = this.config.sessionDialogTimer
    this.countdownInterval = setInterval(() => {
      if (this.countdownInSeconds > 0) {
        this.countdownInSeconds--
        this.countdownTimerMinutes = this.padZero(Math.floor(this.countdownInSeconds/60))
        this.countdownTimerSeconds  = this.padZero(this.countdownInSeconds%60)
      } else if (this.countdownInSeconds <= 0) {
        this.signOut()
      }
    }, 1000)
  }

  closeDialogHelper = () => {
    this.cleanInterval()
    this.dialog.disableClose = false
  }

  /**
   * Public method used in other components to open the dialog.
   */
  open() {
    this.dialog.openDialog()
    this.dialog.setFocusOnElement(1)
    this.dialog.disableClose = false
    this.dialog.closeDialogHelper = this.closeDialogHelper
    this.startCountDown()
    this.isShowing = true
  }

  /**
   * Public method used to close the dialog.
   */
  closeDialog = () => {
    this.isShowing = false
    this.dialog.closeDialog()
  }

  /**
   * Upon successful response from the authenticatinoService.logout$,
   * the countdown interval will be cleared and the user will be navigated to the LogoutLandingUrl.
   */
  signOut() {
    this.authenticationService.logout$.subscribe(() => {
      clearInterval(this.countdownInterval)
      window.location.href = this.config.SessionExpiredUrl
      this.closeDialog()
    })
  }

  cleanInterval = () => {
    clearInterval(this.countdownInterval)
  }

  ngOnInit() {
    this.cleanInterval()
  }

  ngOnDestroy() {
    this.cleanInterval()
  }
}
