import { HttpClient } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"
import { map, tap } from "rxjs/operators"
import { Observable } from "rxjs"
import { privacyCenterApiOptions } from "../http-options"
import { EligibilityStatus, PrivacyRequestEligibility, PrivacyRequestResponse, PrivacyDataRequestStatus } from "src/app/models"
import { RequestChannel, RequestType } from "src/app/models/common"
import { PrivacyRequestService } from "../privacy-request/privacy-request.service"
import { IntakeFormData } from "src/app/models/intakeFormData"
import { RelatedEligibilityRequest } from "src/app/models/PrivacyRequest"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { PortalHistoryService } from "../portal-history/portal-history.service"
import { AppConfig, APP_CONFIG } from "src/app.config"

export enum CorrectionEligibility {
  IneligibleDownload = "INELIGBLE_DOWNLOAD",
  IneligibleInProgress = "INELIGBLE_IN_PROGRESS",
  IneligibleDownloadInProgress = "INELIGIBLE_DOWNLOAD_IN_PROGRESS",
  Eligible = "ELIGIBLE",
}

@Injectable({
  providedIn: "root",
})
export class IntakeFormService {
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    public privacyRequestService: PrivacyRequestService,
    public portalHistoryService: PortalHistoryService
  ) {
    this.baseUrl = config.apiURL
  }

  intakeFormData: IntakeFormData[]
  requestSuccessful: boolean = false
  baseUrl: string
  pendingRequestId: string


  formatCategories(formData: IntakeFormData[]) {
    let categories: any[] = []

    formData.forEach((data) => {
      categories.push({
        categoryId: "CORRECTION_DATA",
        categoryName: "Request to correct data",
        isCategorySelected: true,
        subcategories: [
          {
            isSubcategorySelected: true,
            subcategoryId: "FILE_NAME",
            subcategoryName: "File name from which data to be updated (from previous download)",
            subcategoryValue: data.fileName,
            subcategoryValueDescription: "",
          },
          {
            subcategoryId: "FIELD_NAME",
            subcategoryName: "Name of the field from privacy file to be corrected",
            subcategoryValue: data.field,
            subcategoryValueDescription: "",
          },
          {
            subcategoryId: "FIELD_VALUE_CHANGE_FROM",
            subcategoryName: "Value of the field changed from",
            subcategoryValue: data.incorrectValue,
            subcategoryValueDescription: "",
          },
          {
            subcategoryId: "FIELD_VALUE_CHANGE_TO",
            subcategoryName: "Value of the field changed to",
            subcategoryValue: data.correctValue,
            subcategoryValueDescription: "",
          },
          {
            subcategoryId: "CORRECTION_EXPLANATION",
            subcategoryName: "Explanation regarding the data correction",
            subcategoryValue: data.explanation,
            subcategoryValueDescription: "",
          },
        ],
      })
    })
    return categories
  }

  getSubmitPayload = (data: IntakeFormData[], relatedEligibilityRequest: RelatedEligibilityRequest, email: string) => {
    return {
      actorCommunicationPreferences: {
        email,
      },
      actorIdentifier: {
        actorType: "",
      },
      agentIdentifier: {
        verificationMethodType: "",
      },
      categories: this.formatCategories(data),
      requestChannel: RequestChannel.ONLINE,
      requestType: "CORRECTION",
      relatedRequests: [
        {
          privacyDataRequestId: relatedEligibilityRequest.privacyDataRequestId,
          requestType: relatedEligibilityRequest.requestType,
        },
      ],
    }
  }

  submitRequest$ = (data: IntakeFormData[], relatedEligibilityRequest: RelatedEligibilityRequest, email: string) =>
    this.http
      .post<PrivacyRequestResponse>(
        `${this.baseUrl}/request/submit/${RequestType.CORRECTION.toLowerCase()}`,
        this.getSubmitPayload(data, relatedEligibilityRequest, email),
        privacyCenterApiOptions
      )
      .pipe(
        retryWithBackoff(this.privacyRequestService.logHelper),
        tap(() => {
          this.privacyRequestService.bustEligibilityCache$.next(true)
          this.portalHistoryService.invalidateHistoryCache()
        })
      )

  //Returns the related request for correction
  relatedEligibilityRequest$ = this.privacyRequestService.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) => {
      return prs.filter((pr) => pr.requestType === RequestType.CORRECTION)[0].relatedEligibilityRequests[0]
    })
  )

  requestEligibility$: Observable<PrivacyRequestEligibility[]> = this.privacyRequestService.requestEligibility$

  correctionEligibility$ = this.getCorrectionEligibility()

  public getCorrectionEligibility() {
    return this.requestEligibility$.pipe(
      map((prs: PrivacyRequestEligibility[]) => {
        return prs.filter((pr) => pr.requestType === RequestType.CORRECTION || RequestType.DOWNLOAD || RequestType.PRE_RTC_DOWNLOAD || RequestType.POST_RTC_VAL_DOWNLOAD)
      }),
      map((pr) => {

        let downloadEligibility = pr.filter((pr) => pr.requestType === RequestType.DOWNLOAD)[0]
        if(downloadEligibility && downloadEligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST)
        {
          if(downloadEligibility.pendingRequestDataStatus != PrivacyDataRequestStatus.DATA_READY)
            {
              this.pendingRequestId = downloadEligibility.pendingRequestId
              return CorrectionEligibility.IneligibleDownloadInProgress
            }
        }
        let preRTCDownloadEligibility = pr.filter((pr) => pr.requestType === RequestType.PRE_RTC_DOWNLOAD)[0]
        if(preRTCDownloadEligibility && preRTCDownloadEligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST)
        {
          if(preRTCDownloadEligibility.pendingRequestDataStatus != PrivacyDataRequestStatus.DATA_READY)
            {
              this.pendingRequestId = preRTCDownloadEligibility.pendingRequestId
              return CorrectionEligibility.IneligibleDownloadInProgress
            }
        }
        let postRTCDownloadEligibility = pr.filter((pr) => pr.requestType === RequestType.POST_RTC_VAL_DOWNLOAD)[0]
        if(postRTCDownloadEligibility && postRTCDownloadEligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST)
        {
          if(postRTCDownloadEligibility.pendingRequestDataStatus != PrivacyDataRequestStatus.DATA_READY)
            {
              this.pendingRequestId = postRTCDownloadEligibility.pendingRequestId
              return CorrectionEligibility.IneligibleDownloadInProgress
            }
        }
        let remainingEligiblity = pr.filter((pr) => pr.requestType === RequestType.CORRECTION)[0]
        const ineligibleDownload = remainingEligiblity.eligibilityStatus === EligibilityStatus.INELIGIBLE_NO_VALID_DOWNLOAD_REQUEST
        const ineligibleInProgress = remainingEligiblity.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST


        if (ineligibleDownload) return CorrectionEligibility.IneligibleDownload
        else if (ineligibleInProgress) return CorrectionEligibility.IneligibleInProgress
        else return CorrectionEligibility.Eligible
      })
    )
  }
}
