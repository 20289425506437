import { NgModule } from "@angular/core"
import { RouterModule, ExtraOptions } from "@angular/router"
import { routes } from "./routes.const"
import { HttpClientModule } from "@angular/common/http"

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  urlUpdateStrategy: "eager"
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions), HttpClientModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
