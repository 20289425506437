<div class="grv-row grv-color-swatch__color-panel grv-background--core-blue-50 grv-padding--large" id="first-row">
  <div class="grv-wrapper">
    <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4 grv-color--white">
      <h1 #focusRef class="grv-text__heading--xlarge">
        {{ title | translate }}
      </h1>
      <p class="grv-text__block grv-text__block--medium">
        {{ subtitle | translate }}
      </p>
      <a class="grv-text__block grv-text--small-2 grv-weight--semibold banner-description" href={{link}} target="_blank" rel="noopener noreferrer">
        {{ description | translate }}
      </a>
    </div>
  </div>
</div>
