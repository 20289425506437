<div *ngIf="divider" class="grv-divider--normal grv-divider--dark request-divider"></div>
<div role="row" class="grv-grid">
  <div class="grv-row grv-row--xs-top">
    <!-- ICON -->
    <div role="cell" class="icon-cell">
      <c1-privacy-icon
        *ngIf="type === RequestType.DOWNLOAD || type === RequestType.DELETE"
        [iconName]="open ? 'arrowUp' : 'arrowDown'"
        [size]="'micro'"
        (click)="expand()"
        (keyup.enter)="expand()"
        class="openIcon"
        data-cy="open-icon"
        tabindex="0"
        role="button"
        aria-label="Expand for Details Button"
      ></c1-privacy-icon>
    </div>
    <!-- ID -->
    <div
      role="cell"
      aria-labelledby="Request ID"
      class="requestID grv-weight--semibold grv-padding__right--medium-2 id-cell monofonts"
    >
      <div class="grv-text--small-1 grv-weight--semibold grv-color--digital-gray-60">
        {{ "PORTAL_OF_HISTORY.REQUEST.HEADER.ID" | translate }}
      </div>
      <span class="grv-text--small-2 grv-weight--semibold">{{ request.privacyDataRequestId }}</span>
    </div>
    <!-- DATE -->
    <div role="cell" aria-labelledby="Request Date" class="grv-padding__right--medium-2 date-cell">
      <div class="grv-text--small-1 grv-weight--semibold grv-color--digital-gray-60">
        {{ "PORTAL_OF_HISTORY.REQUEST.HEADER.DATE" | translate }}
      </div>
      <span class="grv-text--small-2">{{ request.requestCreatedTimestamp | timestamp: false:false }}</span>
    </div>
    <!-- STATUS -->
    <div role="cell" aria-labelledby="Request Status" class="grv-padding__right--medium-2 status-cell">
      <div class="grv-text--small-1 grv-weight--semibold grv-color--digital-gray-60">
        {{ "PORTAL_OF_HISTORY.REQUEST.HEADER.STATUS" | translate }}
      </div>
      <span *ngIf="getShownStatus() === Status.IN_PROGRESS" class="grv-text--small-2">{{
        content.inProgress | translate
      }}</span>
      <div
        *ngIf="
          type === RequestType.DOWNLOAD &&
          (request.requestStatus === PrivacyDataRequestStatus.DATA_READY ||
            request.requestStatus === PrivacyDataRequestStatus.FULFILLED) &&
          queryParams.query !== 2
        "
        class=""
      >
        <span class="grv-text--small-2">{{ "PORTAL_OF_HISTORY.REQUEST.DOWNLOAD_READY_TEXT" | translate }}</span>
        <div class="grv-text--small-1 grv-weight--semibold expire">
          {{ "(Expires " + (expiredDate$ | async | timestamp: false:false) + ")" }}
        </div>
      </div>
      <span *ngIf="getShownStatus() === Status.EXPIRED" class="grv-text--small-2">{{
        "Expired " + (expiredDate$ | async | timestamp: false:false)
      }}</span>
      <span *ngIf="getShownStatus() === Status.VERIFICATION_FAILED" class="grv-text--small-2">{{
        content.verificationFailed | translate
      }}</span>
      <span
        *ngIf="
          (type === RequestType.DELETE || type === RequestType.CORRECTION) &&
          (request.requestStatus === PrivacyDataRequestStatus.COMPLETED ||
            request.requestStatus === PrivacyDataRequestStatus.REJECTED)
        "
        class="grv-text--small-2"
      >
        {{ "Completed " + (completedDate$ | async | timestamp: false:false) }}</span
      >
    </div>
    <!-- BUTTON -->
    <div role="cell" aria-label="Request Button" class="button-cell">
      <button
        *ngIf="getShownStatus() === Status.VERIFY_AND_DOWNLOAD"
        [innerHTML]="content.verifyAndDownload | translate"
        class="grv-button--progressive grv-button--compact status-button"
        (click)="downloadClicked('VERIFY_AND_DOWNLOAD')"
        (keyup.enter)="downloadClicked(true)"
        (keyup.space)="downloadClicked(true)"
        data-cy="verify-download-button"
      ></button>

      <button
        *ngIf="getShownStatus() === Status.DOWNLOAD"
        [innerHTML]="content.download | translate"
        class="grv-button--action grv-button--compact status-button"
        (click)="downloadClicked('DOWNLOAD')"
        (keyup.enter)="downloadClicked(true)"
        (keyup.space)="downloadClicked(true)"
        data-cy="download-button"
      ></button>

      <button
        *ngIf="getShownStatus() === Status.DOWNLOADING"
        [innerHTML]="content.downloading | translate"
        class="grv-button--action grv-button--compact status-button"
        disabled
        data-cy="download-button"
      ></button>

      <button
        *ngIf="getShownStatus() === Status.DOWNLOAD_AGAIN"
        [innerHTML]="content.downloadAgain | translate"
        class="grv-button--action grv-button--compact status-button"
        (click)="downloadClicked('DOWNLOAD_AGAIN')"
        (keyup.enter)="downloadClicked(true)"
        (keyup.space)="downloadClicked(true)"
        data-cy="download-button"
      ></button>
    </div>
  </div>
  <div role="cell" class="grv-row request-body" [ngClass]="{ open: open }">
    <!-- Spacing place holder -->
    <div aria-hidden="true" class="icon grv-col--md-1 grv-col--lg-1"></div>
    <div class="grv-col--xs-3 grv-col--sm-3 grv-col--md-7 grv-col--lg-11">
      <ng-content></ng-content>
    </div>
  </div>
</div>
