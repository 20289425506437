import { Injectable } from "@angular/core"
import { Observable, combineLatest } from "rxjs"
import { map, pluck } from "rxjs/operators"
import { PrivacyRequestService } from "../privacy-request/privacy-request.service"
import { PrivacyRequestEligibility } from "src/app/models"

@Injectable({
  providedIn: "root",
})
export class NextAvailableRequestService {
  public assetExpirationDate$: Observable<Date> = this.privacyRequestService._assets$.pipe(
    pluck("expirationTimestamp"),
    map((val) => new Date(val))
  )

  public serverNextAvailableRequestDate$ = this.privacyRequestService.pendingRequests$.pipe(
    map((pendingRequests: PrivacyRequestEligibility[]) => {
      const pendingRequestWithTimestamp = pendingRequests.find((req) => !!req.nextAvailableRequestTimestamp)
      return !!pendingRequestWithTimestamp ? new Date(pendingRequestWithTimestamp.nextAvailableRequestTimestamp) : null
    })
  )

  public nextAvailableRequestDate$: Observable<Date | null> = combineLatest(
    this.assetExpirationDate$,
    this.serverNextAvailableRequestDate$
  ).pipe(
    map(([assetExpDate, naDate]) => {
      return this.isAfterDate(naDate, assetExpDate) ? null : naDate
    })
  )

  constructor(private privacyRequestService: PrivacyRequestService) {}

  isAfterDate(a: Date, b: Date): boolean {
    return a >= b
  }
}
