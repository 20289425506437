import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { RouteName } from "../../route-name"
import { CorrectionEligibility, IntakeFormService } from "src/app/services/intake-form/intake-form.service"
import {
  InEligibileDialogType,
  IneligibleRequestDialogComponent,
} from "src/app/components/ineligible-request-dialog/ineligible-request-dialog.component"
import { AuthenticationService, AuthType } from "src/app/services/auth/authentication.service"
import { Subscription } from "rxjs"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"

@Component({
  selector: "c1-privacy-correction-landing",
  templateUrl: "./correction-landing.component.html",
  styleUrls: ["./correction-landing.component.scss"],
})
export class CorrectionLandingComponent implements OnInit, OnDestroy {
  @ViewChild(IneligibleRequestDialogComponent) ineligibleDialog: IneligibleRequestDialogComponent
  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()

  subheaderContent: SubheaderContent = {
    icon: "document",
    headerText: "RIGHT_TO_CORRECT.HEADER",
    subheaderText: "RIGHT_TO_CORRECT.SUBHEADER",
  }

  eligibilitySubscription: Subscription
  config: AppConfig

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    public router: Router,
    private newRelicService: NewRelicService,
    private intakeFormService: IntakeFormService,
    private authService: AuthenticationService,
    public maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService
  ) {
    this.config = config
  }

  ngOnInit(): void {

    this.oneTagService.setPageView();

    this.newRelicService.logCustomError("User on correction landing page", {
      event: newRelicEvent.correctionLanding,
      logLevel: ErrorLoggingLevel.Info,
    })

  }

  public goBackToPreviousRoute(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionLandingNavigateToManage');
    this.router.navigate([RouteName.Manage])
  }

  public goToIntakePage() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionLandingNavigateToIntakeForm');
    this.eligibilitySubscription = this.intakeFormService.correctionEligibility$.subscribe((eligibility) => {
      if (eligibility === CorrectionEligibility.Eligible) {
        this.newRelicService.logCustomError(`User navigated to challenge app to initiate correction request`, {
          event: newRelicEvent.correctionToStepUp,
          stateField: `${this.authService.getStateForAuthType(AuthType.CorrectionStepUp)}`,
          logLevel: ErrorLoggingLevel.Info,
        })
        window.location.href = this.authService.authUrl(AuthType.CorrectionStepUp)
      } else if (eligibility === CorrectionEligibility.IneligibleDownload) {
        this.ineligibleDialog.type = InEligibileDialogType.CorrectionNoDownload
        this.ineligibleDialog.open()
      } else if (eligibility === CorrectionEligibility.IneligibleInProgress) {
        this.ineligibleDialog.type = InEligibileDialogType.CorrectionInProgress
        this.ineligibleDialog.open()
      } else if (eligibility === CorrectionEligibility.IneligibleDownloadInProgress) {
        this.ineligibleDialog.type = InEligibileDialogType.CorrectionDownloadInProgress
        this.ineligibleDialog.open()
      }
    })
  }

  clickPersonalAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionLandingPersonalAccounts');
    window.location.href=this.config.customerOauthSignIn
  }

  clickBusinessAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionLandingBusinessAccounts');
    this.router.navigate([RouteName.SignIn])
  }

  clickCommercialAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionLandingCommercialAccounts');
    this.router.navigate([RouteName.SignIn])
  }

  ngOnDestroy() {
    if (this.eligibilitySubscription) {
      this.eligibilitySubscription.unsubscribe()
    }
  }
}
