import { Inject, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { catchError } from "rxjs/operators"
import { of } from "rxjs"

import { SupportType } from "src/app/models/common/SupportType"
import { privacyCenterApiOptions } from "../http-options"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AppConfig, APP_CONFIG } from "src/app.config"

@Injectable({
  providedIn: "root",
})
export class SupportService {
  baseUrl: string


  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private newRelicService: NewRelicService,
  ) {
    this.baseUrl = config.apiURL
  }

  getSupportPayload(
    errorTypeInput: SupportType,
    requestIdInput: string,
  ) {
    let payload = {
      errorType: errorTypeInput,
      requestId: requestIdInput
    }

    return payload
  }

  submitSupport$ = (
    errorType: SupportType,
    requestId: string,
  ) => {

    const supportType = errorType

    return this.http.post(`${this.baseUrl}/support`, this.getSupportPayload(errorType, requestId), privacyCenterApiOptions).pipe(
      retryWithBackoff((msg) =>
        this.newRelicService.logCustomError(msg, {
          logLevel: ErrorLoggingLevel.Error,
        })
      ),
      catchError((err) => {
        this.newRelicService.logCustomError(
          `Error in the POST call to /support for requested ID: ${requestId}.${JSON.stringify(
            err
          )}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        return of(err)
      })
    )
  }
}
