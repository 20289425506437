export enum NotificationType {
  EMAIL_DATA_READY_SENT = "EMAIL_DATA_READY_SENT",
  EMAIL_DOWNLOAD_REMINDER_SKIPPED = "EMAIL_DOWNLOAD_REMINDER_SKIPPED",
  EMAIL_DELETE_DELAYED_SENT = "EMAIL_DELETE_DELAYED_SENT",
  EMAIL_REQUEST_RECEIVED_SENT = "EMAIL_REQUEST_RECEIVED_SENT",
  EMAIL_REQUEST_RECEIVED_ON_FILE_SENT = "EMAIL_REQUEST_RECEIVED_ON_FILE_SENT",
  EMAIL_FULFILLMENT_DELAYED_SENT = "EMAIL_FULFILLMENT_DELAYED_SENT",
  EMAIL_DOWNLOAD_REMINDER_SENT = "EMAIL_DOWNLOAD_REMINDER_SENT",
  EMAIL_DELETE_COMPLETED_SENT = "EMAIL_DELETE_COMPLETED_SENT",
  EMAIL_ASSET_DOWNLOAD_SENT = "EMAIL_ASSET_DOWNLOAD_SENT",
}

export enum NotificationStatus {
  TEMPORARY_FAILURE = "TEMPORARY_FAILURE",
  SUCCESS = "SUCCESS",
  INTERNAL_FAILURE = "INTERNAL_FAILURE",
  TERMINAL_FAILURE = "TERMINAL_FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED_UNVERIFIED = "COMPLETED_UNVERIFIED",
  CREATED = "CREATED",
}

export enum SelectedActorCommunicationPreference {
  EMAIL_ON_FILE = "EMAIL_ON_FILE",
  EMAIL_ALTERNATE = "EMAIL_ALTERNATE",
}

export interface NotificationEvent {
  notificationType: NotificationType
  notificationStatus: NotificationStatus
  notificationSentTimestamp: string
  notificationFailureTimestamp: string
  selectedActorCommunicationPreference: SelectedActorCommunicationPreference
}
