import { Component, Inject, OnInit } from "@angular/core"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { RouteName } from "../../route-name"
import { AuthenticationService } from "src/app/services/auth/authentication.service"
import { AppConfig, APP_CONFIG } from "src/app.config"

@Component({
  selector: "c1-privacy-session-expired",
  templateUrl: "./session-expired.component.html",
  styleUrls: ["./session-expired.component.scss"],
})
export class SessionExpiredComponent implements OnInit {
  subheaderContent: SubheaderContent = {
    icon: "clock",
    headerText: "SESSION_EXPIRED.HEADER",
  }

  signIn = "/" + RouteName.SignIn
  logoutLandingUrl: string
  private countdownInterval: any
  public countdownInSeconds: any
  public countdownTimerMinutes: any
  public countdownTimerSeconds: any

  constructor(@Inject(APP_CONFIG) config: AppConfig, private authenticationService: AuthenticationService) {
    this.logoutLandingUrl = config.LogoutLandingUrl
  }

  ngOnInit() {
  }
}
