import { Component, ElementRef, Renderer2, ViewChild, ChangeDetectorRef, OnDestroy, Input } from "@angular/core"

enum KeyCode {
  TAB = 9,
  ESCAPE = 27,
}

enum DialogClass {
  Active = "grv-dialog--active",
  ScrollLock = "grv-scroll--lock",
}

@Component({
  selector: "c1-privacy-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnDestroy {
  triggerElement: HTMLElement
  focusableElements: NodeListOf<HTMLElement>
  firstFocusableElement: HTMLElement
  lastFocusableElement: HTMLElement
  keydownListener: () => void
  @Input() closeDialogHelper: () => any | undefined

  public disableClose: boolean = true

  @ViewChild("dialog") dialog: ElementRef

  constructor(private renderer: Renderer2, private ref: ChangeDetectorRef) {}

  ngOnDestroy() {
    if (this.keydownListener) {
      this.keydownListener()
    }
    this.renderer.removeClass(this.dialog.nativeElement, DialogClass.Active)
    this.renderer.removeClass(document.body, DialogClass.ScrollLock)
  }

  public openDialog(): void {
    this.triggerElement = document.activeElement as HTMLElement
    this.renderer.addClass(this.dialog.nativeElement, DialogClass.Active)
    this.renderer.addClass(document.body, DialogClass.ScrollLock)
    this.ref.detectChanges()
    this.resetFocusableElements()
    this.setDialogFocus()
    this.keydownListener = this.renderer.listen(
      this.dialog.nativeElement,
      "keydown",
      (keyboardEvent: KeyboardEvent) => {
        this.handleKeyDownEvents(keyboardEvent)
      }
    )
  }

  public closeDialog(): void {
    if (!this.disableClose) {
      if (this.closeDialogHelper && typeof this.closeDialogHelper === "function") {
        this.closeDialogHelper()
      }
      this.renderer.removeClass(this.dialog.nativeElement, DialogClass.Active)
      this.renderer.removeClass(document.body, DialogClass.ScrollLock)
      setTimeout(() => {
        this.triggerElement.focus()
      }, 100)
    }
  }

  public setFocusOnElement(elementToReceiveFocusIndex: number): void {
    if (this.focusableElements && this.focusableElements[elementToReceiveFocusIndex]) {
      setTimeout(() => {
        this.focusableElements[elementToReceiveFocusIndex].focus()
      }, 100)
    }
  }

  private resetFocusableElements() {
    const selectors: string =
      "a[href], area[href], input:not([disabled]), select:not([disabled])," +
      " textarea:not([disabled]), button:not([disabled]), [tabindex='0']"
    this.focusableElements = this.dialog.nativeElement.querySelectorAll(selectors)
    if (this.focusableElements && this.focusableElements.length) {
      this.firstFocusableElement = this.focusableElements[0]
      this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1]
    }
  }

  private setDialogFocus(): void {
    if (this.firstFocusableElement) {
      setTimeout(() => {
        this.firstFocusableElement.focus()
      }, 100)
    }
  }

  private handleKeyDownEvents(event: KeyboardEvent): void {
    this.resetFocusableElements()
    switch (event.keyCode) {
      case KeyCode.TAB:
        if (this.focusableElements.length === 1) {
          event.preventDefault()
          break
        }

        if (event.shiftKey) {
          if (document.activeElement === this.firstFocusableElement) {
            event.preventDefault()
            this.lastFocusableElement.focus()
          }
        } else {
          if (document.activeElement === this.lastFocusableElement) {
            event.preventDefault()
            this.firstFocusableElement.focus()
          } else if (
            document.activeElement === this.focusableElements[this.focusableElements.length - 2] &&
            (this.lastFocusableElement as any).disabled
          ) {
            // The above is used to trap the focus within the modal if the last input is an element that can be enabled/disabled
            event.preventDefault()
            this.firstFocusableElement.focus()
          }
        }

        break

      case KeyCode.ESCAPE:
        this.closeDialog()
      default:
        break
    }
  }
}
