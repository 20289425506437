import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ReactiveFormsModule, FormsModule } from "@angular/forms"
import { InlineSVGModule } from "ng-inline-svg-2"
import { TranslateModule } from "@ngx-translate/core"
import { SharedRoutingModule } from "./shared-routing.module"

import { IconComponent } from "src/app/components/icon/icon.component"
import { CheckboxComponent } from "src/app/components/checkbox/checkbox.component"
import { SubheaderComponent } from "src/app/components/subheader/subheader.component"
import { CallCenterDialogComponent } from "src/app/components/call-center-dialog/call-center-dialog.component"
import { DialogComponent } from "src/app/components/dialog/dialog.component"
import { CheckboxListComponent } from "src/app/components/checkbox-list/checkbox-list.component"
import { EmailDialogComponent } from "src/app/components/email-dialog/email-dialog.component"
import { SubheaderWithCardComponent } from "src/app/components/subheader-with-card/subheader-with-card.component"
import { CardComponent } from "../components/card/card.component"
import { RequestComponent } from "../components/request/request.component"

import { MaskCardNumberDirective } from "src/app/directives/mask-card-number.directive"
import { TranslateWithHtmlDirective } from "src/app/directives/translate-with-html.directive"
import { LoadingDirective } from "src/app/directives/loading.directive"

import { TimestampPipe } from "src/app/utils/pipes/timestamp.pipe"
import { IntakeFormComponent } from "../components/intake-form/intake-form.component"
import { NocopyDirective } from "../directives/nocopy.directive"
import { ConnexSkeletonComponent } from "../components/connex-skeleton/connex-skeleton.component"
import { ConnexPlaceholderComponent } from "../components/connex-skeleton/connex-placeholder/connex-placeholder.component"
import { IneligibleRequestDialogComponent } from "../components/ineligible-request-dialog/ineligible-request-dialog.component"
import { RequestTableComponent } from "../components/request-table/request-table/request-table.component"

@NgModule({
  declarations: [
    IconComponent,
    CheckboxComponent,
    SubheaderComponent,
    CallCenterDialogComponent,
    DialogComponent,
    CheckboxListComponent,
    EmailDialogComponent,
    IntakeFormComponent,
    SubheaderWithCardComponent,
    CardComponent,
    RequestComponent,
    MaskCardNumberDirective,
    TranslateWithHtmlDirective,
    LoadingDirective,
    NocopyDirective,
    TimestampPipe,
    ConnexSkeletonComponent,
    ConnexPlaceholderComponent,
    IneligibleRequestDialogComponent,
    RequestTableComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InlineSVGModule.forRoot(),
  ],
  exports: [
    IconComponent,
    CheckboxComponent,
    SubheaderComponent,
    CallCenterDialogComponent,
    DialogComponent,
    CheckboxListComponent,
    EmailDialogComponent,
    IntakeFormComponent,
    SubheaderWithCardComponent,
    CardComponent,
    RequestComponent,
    MaskCardNumberDirective,
    TranslateWithHtmlDirective,
    LoadingDirective,
    NocopyDirective,
    TranslateModule,
    TimestampPipe,
    ConnexSkeletonComponent,
    ConnexPlaceholderComponent,
    IneligibleRequestDialogComponent,
    RequestTableComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
