import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router"
import { combineLatest, Observable } from "rxjs"
import { map } from "rxjs/operators"
import { PrivacyRequestService } from "../services/privacy-request/privacy-request.service"
import { RouteName } from "../route-name"
import { PortalHistoryService } from "../services/portal-history/portal-history.service"
import { CorrectionEligibility, IntakeFormService } from "../services/intake-form/intake-form.service"
import { EligibilityStatus } from "../models"
import { QUERY_PARAMS } from "../common"
import { MaintenanceBannerService } from "../services/maintenance-banner/maintenance-banner.service"
import {MaintenanceTypes} from "../enums/maintenance-types";

/*tslint:disable:max-classes-per-file*/
export enum DOWNLOAD_TYPE {
  PRE_VALIDATION = "preValidationRequest",
  POST_VALIDATION = "postValidationRequest",
}

@Injectable({ providedIn: "root" })
export class CreateRequestGuard  {
  constructor(
    private requestService: PrivacyRequestService,
    private historyService: PortalHistoryService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this.requestService.requestMultipleSso$,
      this.requestService.pendingRequestsGaurdEligibilty$,
      this.historyService.getPortalHistoryData(),
      this.requestService.requestLimitExceeded$,
    ]).pipe(
      map(([multipleSso, pendingRequests, historyData, annualLmiit]) => {
        const bypassLimit =
          route.queryParams &&
          (route.queryParams?.q === DOWNLOAD_TYPE.POST_VALIDATION ||
            route.queryParams?.q === DOWNLOAD_TYPE.PRE_VALIDATION) &&
          pendingRequests.length === 0
        if (multipleSso) {
          return this.router.parseUrl(RouteName.MultipleSso)
        } else if (annualLmiit) {
          // if annual limit, but with bypass && no pending requests, allow access
          if (bypassLimit) {
            return true
          }
          // otherwise, send user to manage hub page
          return this.router.parseUrl(RouteName.Manage)
        } else if (pendingRequests.length === 0) {
          return true
        } else {
          return this.router.parseUrl(RouteName.Manage)
        }
      })
    )
  }
}

@Injectable({ providedIn: "root" })
export class IntakeFormGaurd  {
  constructor(private router: Router, private intakeFormService: IntakeFormService) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this.intakeFormService.correctionEligibility$.pipe(
      map((elg) => {
        if (elg === CorrectionEligibility.Eligible) {
          return true
        } else {
          return this.router.parseUrl(RouteName.CorrectionLanding)
        }
      })
    )
  }
}

@Injectable({ providedIn: "root" })
export class PortalHistoryGuard  {
  constructor(
    private requestService: PrivacyRequestService,
    private historyService: PortalHistoryService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.requestService.requestMultipleSso$]).pipe(
      map(([multipleSso]) => {
        if (multipleSso) {
          return this.router.parseUrl(RouteName.MultipleSso)
        } else {
          return true
        }
      })
    )
  }
}

@Injectable({ providedIn: "root" })
export class MultipleSsoGuard  {
  constructor(
    private requestService: PrivacyRequestService,
    private historyService: PortalHistoryService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.requestService.requestMultipleSso$]).pipe(
      map(([multipleSso]) => {
        if (multipleSso) {
          return true
        } else {
          return this.router.parseUrl(RouteName.Manage)
        }
      })
    )
  }
}

@Injectable({ providedIn: "root" })
export class DeleteRequestGuard  {
  constructor(private requestService: PrivacyRequestService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.requestService.deleteRequestStatus$.pipe(
      map((eligibility) => {
        const stepUpSucceeded = route.queryParams && route.queryParams?.q === QUERY_PARAMS.STEP_UP_SUCCESS
        if (stepUpSucceeded && eligibility.eligibilityStatus !== EligibilityStatus.INELIGIBLE_PENDING_REQUEST) {
          return true
        } else {
          return this.router.parseUrl(RouteName.Manage)
        }
      })
    )
  }
}

@Injectable({ providedIn: "root" })
export class CorrectionLandingGuard  {
  constructor(
    private requestService: PrivacyRequestService,
    private router: Router,
    private maintenanceBannerService: MaintenanceBannerService
  ) {}
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()
  maintenanceType: MaintenanceTypes = this.maintenanceBannerService.getType()
  canActivate(): Observable<boolean | UrlTree> {
    return this.requestService.requestEligibility$.pipe(
      map((eligibility) => {
        if (eligibility.length) {
          return true
        } else {
          if(this.maintenance) {
            return this.router.parseUrl(RouteName.Unavailable)
          } else {
            return this.router.parseUrl(RouteName.Snag)
          }
        }
      })
    )
  }
}
