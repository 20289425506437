<main class="c1-privacy-page-unavailable grv-padding__bottom--xxlarge">
  <ng-container *ngIf="type === 'MAINTENANCE'">
    <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent"></c1-privacy-subheader>
    <p class="grv-text--medium-1 grv-text--light margin-fix grv-weight--normal" [innerHTML]="'MAINTENANCE.INFO' | translate">
    </p>

    <div class="grv-row grv-row--sm-center grv-text--normal grv-padding__top--tiny">
    <span>
      {{ "MAINTENANCE.LINK_TEXT" | translate }}
    </span>
      <span>
      <a
        class="grv-text__link"
        href="javascript:void(0)"
        (click)="goToFrontDoor()"
        tabindex="0">
        {{ "MAINTENANCE.LINK" | translate }}
      </a>
    </span>
    </div>
  </ng-container>
  <ng-container *ngIf="type !== 'MAINTENANCE'">
  <ng-container *ngIf="type === 'FULL_IMPACT'">
    <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent1"></c1-privacy-subheader>
  </ng-container>
  <ng-container *ngIf="type === 'PARTIAL_IMPACT'">
    <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent2"></c1-privacy-subheader>
  </ng-container>
  <ng-container *ngIf="type === 'FEATURE_SPECIFIC'">
    <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent3"></c1-privacy-subheader>
  </ng-container>

  <p class="grv-text--medium grv-text--light info-text margin-fix">
    {{ "UNAVAILABLE.INFO_1" | translate }}
    <ng-container *ngIf="type === 'PARTIAL_IMPACT'">
      {{ "UNAVAILABLE.INFO_2" | translate }}
    </ng-container>
    <br />
    <br />
    {{ "UNAVAILABLE.PHONE" | translate }}
    <br />
    {{ "UNAVAILABLE.DAYS" | translate }}
  </p>
  </ng-container>
</main>
