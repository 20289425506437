import { Component, ViewChild, ElementRef, AfterViewInit, Inject, OnInit } from "@angular/core"
import { RouteName } from "src/app/route-name"
import { fromEvent, Observable } from "rxjs"
import { map, startWith, pluck } from "rxjs/operators"
import { HTMLConfig } from "../../interfaces/htmlConfig.interface"
import { privacyPolicy, privacyPolicyCa, privacyPolicyUk } from "src/app/common"
import { FocusTitleService } from "src/app/services/focusTitle/focus-title.service"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"
import { Router } from "@angular/router"

@Component({
  selector: "c1-privacy-front-door",
  templateUrl: "./front-door.component.html",
  styleUrls: ["./front-door.component.scss"],
})
export class FrontDoorComponent implements OnInit, AfterViewInit {
  @ViewChild("focusRef") focusRef: ElementRef
  config: AppConfig
  htmlConfig: HTMLConfig

  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private focusTitleService: FocusTitleService,
    public maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService,
    private router: Router
  ) {
    this.config = config
  }

  ngOnInit() {
    this.oneTagService.setPageView();
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.appStart);
  }

  ngAfterViewInit() {
    this.focusTitleService.focusElement(this.focusRef)
  }

  signIn = RouteName.SignIn
  breakpoint$: Observable<any> = fromEvent(window, "resize").pipe(
    pluck("target", "innerWidth"),
    startWith(window.innerWidth)
  )

  iconSize$ = this.breakpoint$.pipe(
    map((width) => {
      if (width < 600) {
        return "small"
      } else if (width < 1024) {
        return "medium"
      }
      return "large"
    }),
    map((breakpoint: string) => (breakpoint === "small" ? "small" : "large"))
  )

  clickGetStarted() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorGetStarted');
    this.router.navigate([RouteName.SignIn])
  }

  clickDownloadYourData() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorDownloadYourData');
    this.router.navigate([RouteName.SignIn])
  }

  clickPersonalAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorPersonalAccounts');
    window.location.href=this.config.customerOauthSignIn
  }

  clickBusinessAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorBusinessAccounts');
    this.router.navigate([RouteName.SignIn])
  }

  clickCommercialAccounts() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorCommercialAccounts');
    this.router.navigate([RouteName.SignIn])
  }

  privacyPolicy() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorPrivacyPolicy');
    window.location.href=privacyPolicy
  }

  caPrivacyPolicy() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorCanadaPrivacyPolicy');
    window.location.href=privacyPolicyCa
  }

  ukPrivacyPolicy() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'frontDoorUKPrivacyPolicy');
    window.location.href=privacyPolicyUk
  }
}
