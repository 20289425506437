import { HttpClient } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"
import {
  map,
  shareReplay,
  take,
  flatMap,
  pluck,
  catchError,
  tap,
  filter,
  switchMap,
  publishReplay,
  refCount,
} from "rxjs/operators"
import { Observable, combineLatest, pipe, of, BehaviorSubject, Subject } from "rxjs"

import { privacyCenterApiOptions } from "../http-options"
import { RouteName } from "src/app/route-name"
import {
  Category,
  EligibilityStatus,
  FetchResponse,
  PrivacyDataRequestStatus,
  PrivacyRequestEligibility,
  PrivacyRequestEligibilityResponse,
  PrivacyRequestResponse,
} from "src/app/models"
import { RequestChannel, RequestType, Subcategory } from "src/app/models/common"
import { mapIconsToCategories } from "../category/category.service"
import { NewRelicService } from "../new-relic/new-relic.service"
import { Assets } from "src/app/models/FetchResponse"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AssetCategory } from "src/app/models/assetCategory"
import { NotificationEvent, NotificationStatus } from "src/app/models/NotificationEvent"
import { PortalHistoryService } from "../portal-history/portal-history.service"
import { RelatedEligibilityRequest } from "src/app/models/PrivacyRequest"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "../maintenance-banner/maintenance-banner.service"
import {MaintenanceTypes} from "../../enums/maintenance-types";

@Injectable({
  providedIn: "root",
})
export class PrivacyRequestService {
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private router: Router,
    private newRelicService: NewRelicService,
    public portalHistoryService: PortalHistoryService,
    private maintenanceBannerService: MaintenanceBannerService
  ) {
    this.baseUrl = config.apiURL
  }

  // invalidates the cached elibility response when a submit request is successful
  // successful submits should update eligibility on the backend immediately
  bustEligibilityCache$ = new BehaviorSubject(true)
  baseUrl: string
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()
  maintenanceType: MaintenanceTypes = this.maintenanceBannerService.getType()

  logHelper = (msg) =>
    this.newRelicService.logCustomError(msg, {
      logLevel: ErrorLoggingLevel.Error,
    })

  requestEligibility$: Observable<PrivacyRequestEligibility[]> = this.bustEligibilityCache$.pipe(
    switchMap(() =>
      this.http
        .get<PrivacyRequestEligibilityResponse>(`${this.baseUrl}/request/eligibility`, privacyCenterApiOptions)
        .pipe(
          retryWithBackoff(this.logHelper),
          catchError((err) => {
            const errMessage = `Error in the GET call to /request/eligibility. The user was navigated to the Snag page. ${JSON.stringify(
              err
            )}`
            this.logHelper(errMessage)
            if (this.maintenance) {
              this.router.navigate([`/${RouteName.Unavailable}`])
            } else {
              this.router.navigate([`/${RouteName.Snag}`])
            }
            return of({
              eligibilityPrivacyDataRequests: [],
            } as PrivacyRequestEligibilityResponse)
          }),

          map((response) => {
            if (response.eligibilityPrivacyDataRequests) {
              return response.eligibilityPrivacyDataRequests.map((dr) => new PrivacyRequestEligibility(dr))
            }
            return []
          })
        )
    ),
    tap(() => this.publishRequestEligibility$.next()),
    shareReplay(1)
  )

  postCorrectionDownloadEligibility$: Observable<boolean> = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) => {
      return (
        prs.filter((pr) => pr.requestType === RequestType.POST_RTC_VAL_DOWNLOAD)[0].eligibilityStatus ===
        EligibilityStatus.ELIGIBLE
      )
    })
  )

  publishRequestEligibility$ = new Subject()

  pendingRequests$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) =>
      prs.filter((pr) => {
        return pr.pendingRequest && pr.pendingRequest.privacyDataRequestId && pr.requestType === RequestType.DOWNLOAD
      })
    )
  )

  pendingRequestsGaurdEligibilty$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) =>
      prs.filter((pr) => {
        return (
          pr.pendingRequest &&
          pr.pendingRequest.privacyDataRequestId &&
          (pr.requestType === RequestType.DOWNLOAD ||
            pr.requestType === RequestType.POST_RTC_VAL_DOWNLOAD ||
            pr.requestType === RequestType.PRE_RTC_DOWNLOAD)
        )
      })
    )
  )

  deleteRequestStatus$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) =>
      prs.filter((pr) => {
        return pr.requestType === RequestType.DELETE
      })
    ),
    map((prs: PrivacyRequestEligibility[]) => prs[0])
  )

  downloadRequestStatus$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) =>
      prs.filter((pr) => {
        return (
          pr.requestType === RequestType.DOWNLOAD ||
          pr.requestType === RequestType.POST_RTC_VAL_DOWNLOAD ||
          pr.requestType === RequestType.PRE_RTC_DOWNLOAD
        )
      })
    ),
    map((prs: PrivacyRequestEligibility[]) => prs[0])
  )

  requestLimitExceeded$ = this.limitExceeded(RequestType.DOWNLOAD)

  limitExceeded(requestType: RequestType) {
    return this.requestEligibility$.pipe(
      map((prs: PrivacyRequestEligibility[]) =>
        prs.some((pr) => {
          const isSameRequestType = pr.requestType === requestType
          const eligibilityStatus = pr.eligibilityStatus === EligibilityStatus.INELIGIBLE_MAX_LIMIT
          return isSameRequestType && eligibilityStatus
        })
      )
    )
  }

  requestMultipleSso$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) =>
      prs.some((pr: PrivacyRequestEligibility) => pr.eligibilityStatus === EligibilityStatus.INELIGIBLE_MULTIPLE_SSO)
    )
  )

  ineligbleRequests$ = combineLatest([
    this.pendingRequests$,
    this.requestLimitExceeded$,
    this.requestMultipleSso$,
  ]).pipe(take(1))

  relatedEligibilityRequest$ = this.requestEligibility$.pipe(
    map((prs: PrivacyRequestEligibility[]) => {
      console.debug(prs)
      return prs.filter((pr) => pr.requestType === RequestType.POST_RTC_VAL_DOWNLOAD)[0].relatedEligibilityRequests[0]
    })
  )

  submitRequest$ = (
    categories: Category[],
    requestType: RequestType,
    email: string,
    relatedEligibilityRequest?: RelatedEligibilityRequest
  ) => {
    const submissionType = requestType === RequestType.DELETE ? RequestType.DELETE : RequestType.DOWNLOAD

    return this.http
      .post<PrivacyRequestResponse>(
        `${this.baseUrl}/request/submit/${submissionType.toLowerCase()}`,
        this.getSubmitPayload(categories, email, requestType, relatedEligibilityRequest),
        privacyCenterApiOptions
      )
      .pipe(
        retryWithBackoff(this.logHelper),
        tap(() => {
          this.bustEligibilityCache$.next(true)
          this.portalHistoryService.invalidateHistoryCache()
        })
      )
  }

  filterRequestType = (requestType: string) =>
    pipe(
      map((prs: PrivacyRequestEligibility[]) =>
        prs.filter((pr: PrivacyRequestEligibility) => pr.requestType === requestType)
      )
    )

  filterRequestStatus = (requestStatus: string) =>
    pipe(
      map((prs: PrivacyRequestEligibility[]) =>
        prs.filter(
          (pr: PrivacyRequestEligibility) =>
            pr.pendingRequest &&
            pr.pendingRequest.privacyDataRequestStatus &&
            pr.pendingRequest.privacyDataRequestStatus === requestStatus
        )
      )
    )

  createdDate$ = this.requestEligibility$.pipe(
    this.filterRequestType(RequestType.DOWNLOAD),
    this.filterRequestStatus(PrivacyDataRequestStatus.IN_PROGRESS),
    // If the data is in the correct format, the filter should return an array of one element.
    pluck(0, "pendingRequest", "privacyDataRequestCreatedTimestamp")
  )

  /**
   * Gets request ID to fetch pending requests.
   */
  requestId$ = this.pendingRequests$.pipe(
    map(
      (prs: PrivacyRequestEligibility[]) =>
        prs &&
        prs[0] &&
        prs[0].requestType &&
        prs[0].requestType === RequestType.DOWNLOAD &&
        prs[0].pendingRequest &&
        prs[0].pendingRequest.privacyDataRequestId
    )
  )

  filterCategory = map((accountInfo: FetchResponse) =>
    accountInfo.categories.filter((category) => {
      let result: Category = null
      if (category.isCategorySelected === true || category.isCategorySelected.toString().toLowerCase() === "true") {
        if (category.subcategories && Array.isArray(category.subcategories)) {
          category.subcategories = this.filterSubCategory(category.subcategories)
        }
        result = category
      }
      return result
    })
  )

  filterSubCategory = (subCategories: Subcategory[]) =>
    subCategories.filter((subcategory) => subcategory.isSubcategorySelected)

  fetchPendingRequests = this.requestId$.pipe(
    flatMap((requestId) =>
      this.http.get<FetchResponse>(`${this.baseUrl}/request/${requestId}`, privacyCenterApiOptions)
    ),
    retryWithBackoff(this.logHelper),
    shareReplay(1)
  )

  selectedCategories$ = this.fetchPendingRequests.pipe(this.filterCategory, mapIconsToCategories)

  parseAssets(assets: Assets) {
    const parsedAssets = {
      untilDate: "",
      downloadSize: "",
      downloadIds: [],
    }

    parsedAssets.untilDate = assets.expirationTimestamp
    let accumulatedDownloadSize = 0

    assets.entries.forEach((entry, index) => {
      const { ownerId, assetId, assetSizeInKilobytes } = entry

      parsedAssets.downloadIds.push({ ownerId, assetId })
      accumulatedDownloadSize += assetSizeInKilobytes
    })
    parsedAssets.downloadSize = `${accumulatedDownloadSize / 1000} MB`

    return parsedAssets
  }

  _assets$ = this.fetchPendingRequests.pipe(
    pluck("assets"),
    filter((assets) => !!assets),
    shareReplay(1)
  )

  assetCategories$: Observable<AssetCategory[]> = this.fetchPendingRequests.pipe(pluck("assetsDataCategories"))
  assets$ = this._assets$.pipe(map(this.parseAssets))

  getSubmitPayload(
    categories: Category[],
    email: string,
    requestType: RequestType,
    relatedEligibilityRequest?: RelatedEligibilityRequest
  ) {
    let payload = {
      actorCommunicationPreferences: {
        email,
      },
      actorIdentifier: {
        actorType: "",
      },
      agentIdentifier: {
        verificationMethodType: "",
      },
      categories: this.formatCategories(categories),
      requestChannel: RequestChannel.ONLINE,
      requestType: requestType,
    }

    if (relatedEligibilityRequest) {
      payload["relatedRequests"] = [
        {
          privacyDataRequestId: relatedEligibilityRequest.privacyDataRequestId,
          requestType: relatedEligibilityRequest.requestType,
        },
      ]
    }

    return payload
  }

  formatCategories(categories: Category[]) {
    return categories.map((category) => {
      const formatted: any = {}
      if (category.subcategories && category.subcategories.length > 0) {
        formatted.subcategories = category.subcategories.map((sc) => ({
          subcategoryId: sc.subcategoryId,
          subcategoryName: sc.subcategoryName,
          subcategoryValue: sc.subcategoryValue,
          subcategoryValueDescription: sc.subcategoryValueDescription,
          isSubcategorySelected: sc.isSubcategorySelected,
        }))
      }
      formatted.categoryId = category.categoryId
      formatted.categoryName = category.categoryName
      formatted.isCategorySelected = category.isCategorySelected
      return formatted
    })
  }

  requestIdCalls = {}

  getRequestByRequestId(requestId) {
    let cachedReq = this.requestIdCalls[requestId]
    if (cachedReq) return cachedReq
    let req = this.http
      .get<FetchResponse>(`${this.baseUrl}/request/${requestId}`, privacyCenterApiOptions)
      .pipe(publishReplay(1), refCount())
    this.requestIdCalls[requestId] = req
    return req
  }

  hasEmailFailed(requestId): Observable<{ failed: boolean; date: string }> {
    return this.getRequestByRequestId(requestId).pipe(
      pluck("notificationEvents"),
      map((events: NotificationEvent[]) => {
        if (events.length > 0) {
          let lastEvent = events[events.length - 1]
          let failed =
            lastEvent.notificationStatus === NotificationStatus.INTERNAL_FAILURE ||
            lastEvent.notificationStatus === NotificationStatus.TERMINAL_FAILURE
          return { failed: failed, date: failed ? lastEvent.notificationFailureTimestamp : "" }
        }
        return { failed: false, date: "" }
      })
    )
  }

  getDownloadCategories(requestId) {
    let os: Observable<Category[]> = null
    os = this.getRequestByRequestId(requestId).pipe(this.filterCategory, mapIconsToCategories)
    return os
  }

  getAssets(requestId) {
    return this.getRequestByRequestId(requestId).pipe(pluck("assets"), map(this.parseAssets))
  }

  getAssetDataCategories(requestId) {
    let os: Observable<AssetCategory[]> = null
    os = this.getRequestByRequestId(requestId).pipe(pluck("assetsDataCategories"))
    return os
  }

  getExpirationDate(requestId) {
    return this.getRequestByRequestId(requestId).pipe(pluck("assets", "expirationTimestamp"))
  }

  getRequestEmail(requestId) {
    return this.getRequestByRequestId(requestId).pipe(pluck("actorCommunicationPreferences", "email"))
  }

  getCompletedDate(requestId) {
    return this.getRequestByRequestId(requestId).pipe(
      map((req) => {
        let events = <any[]>req["privacyDataRequestEvents"]
        return events.filter((e) => e["requestStatus"] === PrivacyDataRequestStatus.COMPLETED)[0]
      }),
      pluck("eventCreatedTimestamp")
    )
  }
}
