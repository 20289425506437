<ng-container *ngIf="showMaintenance" >
  <div *ngIf="!closed" [ngClass]="alertClasses"  class="grv-maintenance" role="alert" id="bannerAlert">
    <p [ngClass]="hasLinkButton ? 'grv-alert__message' : 'grv-alert__message_no_button'">
      <ng-content></ng-content>
    </p>
    <div class="grid-example">
      <div class="grv-wrapper">
        <div class="grv-col--xs-1 grv-col--md-1 grv-col--lg-1 icon-block">
          <c1-privacy-icon
            id="alert"
            [iconName]="'caution2'"
            [size]="'micro'"
            class="grv-margin__right--medium ccol"
          ></c1-privacy-icon>
            <ng-container *ngIf="type === 'MAINTENANCE'" >
              {{ "MAINTENANCE_BANNER.TEXT1" | translate }}
              {{ bannerText | translate }}
              {{ "MAINTENANCE_BANNER.TEXT2" | translate }}
            </ng-container>
            <ng-container *ngIf="type === 'FULL_IMPACT'">
              {{ "MAINTENANCE_BANNER.FULL_IMPACT" | translate }}
              {{ "MAINTENANCE_BANNER.PHONE" | translate }}
            </ng-container>
            <ng-container *ngIf="type === 'PARTIAL_IMPACT'">
              {{ "MAINTENANCE_BANNER.PARTIAL_IMPACT" | translate }}
              {{ "MAINTENANCE_BANNER.PHONE" | translate }}
            </ng-container>
            <ng-container *ngIf="type === 'FEATURE_SPECIFIC'">
              {{ "MAINTENANCE_BANNER.FEATURE_SPECIFIC" | translate }}
              {{ "MAINTENANCE_BANNER.PHONE" | translate }}
            </ng-container>
          </div>
      </div>
    <button
      id="closeButton0"
      *ngIf="canClose"
      class="grv-alert__close grv-button--text grv-button--compact"
      (click)="close()"
    >
      Close
    </button>
    <button
      id="linkButton"
      *ngIf="hasLinkButton"
      class="grv-alert__close grv-button--text grv-button--compact"
      (click)="linkClick()"
    >
      {{linkMessage}}
    </button>
    <c1-privacy-icon
      id="closeButton"
      *ngIf="alertType === 'GLOBAL'"
      [iconName]="'xclose'"
      [size]="'micro'"
      (click)="close()"
      class="grv-alert__close grv-button--text grv-button--compact"
      data-cy="xclose-icon"
      role="button"
      aria-label="Close Alert Banner"
    ></c1-privacy-icon>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!showMaintenance" >
  <div *ngIf="!closed" [ngClass]="alertClasses"  class="grv-alert__div" role="alert" id="bannerAlert1">
    <p [ngClass]="hasLinkButton ? 'grv-alert__message' : 'grv-alert__message_no_button'">
      <ng-content></ng-content>
    </p>
      <button
        id="closeButton1"
        *ngIf="canClose"
        class="grv-alert__close grv-button--text grv-button--compact"
        (click)="close()"
      >
        Close
      </button>
      <button
        id="linkButton1"
        *ngIf="hasLinkButton"
        class="grv-alert__close grv-button--text grv-button--compact"
        (click)="linkClick()"
      >
        {{linkMessage}}
      </button>
      <c1-privacy-icon
        id="closeButton3"
        *ngIf="alertType === 'GLOBAL'"
        [iconName]="'xclose'"
        [size]="'micro'"
        (click)="close()"
        class="grv-alert__close grv-button--text grv-button--compact"
        data-cy="xclose-icon"
        role="button"
        aria-label="Close Alert Banner"
      ></c1-privacy-icon>
  </div>
</ng-container>
