<footer id="universal-nav-footer" class="universal-nav footer-wrapper fluid-width grv-font">
  <div id="site-footer" class="site-footer">
    <section id="footer-section" class="section-2">
      <div class="grv-wrapper">
        <div class="grv-grid">
          <div class="grv-row">
            <div class="grv-col--sm-4">
              <div class="site-footer__footNotes">
                <div class="grv-accordion">
                  <div class="grv-accordion__item">
                    <button
                      #footerAccordionButton
                      type="button"
                      (click)="toogleFootNote()"
                      class="grv-text__heading--normal site-footer__footNotes-title site-footer__footNotes-title--inactive"
                      id="footer-footnotes-accordion-button"
                      aria-controls="footer-footnotes-accordion-section"
                      aria-expanded="false"
                      aria-label="Footnotes section content"
                      data-initial-value=" Footnotes "
                    >
                      {{ content.labels.footnotesLabel | translate }}
                    </button>
                    <div
                      #footerAccordionSection
                      class="grv-accordion__section grv-accordion__section--inactive hide-contents"
                      id="footer-footnotes-accordion-section"
                      aria-labelledby="footer-footnotes-accordion-button"
                      aria-hidden="true"
                    >
                      <p class="grv-text__body--tiny site-footer__fdic">
                        {{ content.labels.fdicTextLabel | translate }}
                        <a class="site-footer__disclosures-link" [href]="content.links.fdicCoverage">{{ content.labels.fdicTextButtonLabel | translate }}</a>.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-3">
      <div class="grv-wrapper">
        <div class="grv-grid">
          <div class="grv-row">
            <div class="grv-col--sm-4">
              <div class="grv-wrapper copyright">
                <div class="grv-row">
                  <div class="grv-col--sm-4 grv-col--md-2 grv-col--lg-4 site-footer__copyright">
                    <p class="grv-text__body--tiny">{{ content.labels.copyrightLabel | translate }}</p>
                  </div>
                  <div class="grv-col--sm-4 grv-col--md-4 grv-col--lg-4 site-footer__center-content">
                    <a class="grv-text__body--tiny" [href]="content.links.privacy">{{
                      content.labels.privacyButtonLabel | translate
                    }}</a
                    ><a class="grv-text__body--tiny" [href]="content.links.security">{{
                      content.labels.securityButtonLabel | translate
                    }}</a
                    ><a class="grv-text__body--tiny" [href]="content.links.adChoices">{{
                      content.labels.adChoicesButtonLabel | translate
                    }}</a
                    ><a class="grv-text__body--tiny" [href]="content.links.termsAndCondition">{{
                      content.labels.termsAndConditionButtonLabel | translate
                    }}</a>
                  </div>
                  <div class="grv-col--sm-4 grv-col--md-2 grv-col--lg-4 site-footer__government-icons">
                    <a
                      class="site-footer__fdic-icon"
                      [href]="content.links.fdicHome"
                      aria-label="Member: FDIC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <c1-privacy-icon [iconName]="'fdic'" [size]="'xsmall'"></c1-privacy-icon>
                    </a>

                    <c1-privacy-icon
                      [iconName]="'equal_opportunity'"
                      [size]="'footer_icon_size'"
                      class="site-footer__equal-housing-icon"
                    ></c1-privacy-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</footer>
