import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
} from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"
import { IntakeFormData } from "src/app/models/intakeFormData"

@Component({
  selector: "c1-privacy-intake-form",
  templateUrl: "./intake-form.component.html",
  styleUrls: ["./intake-form.component.scss"],
})
export class IntakeFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showRemoveButton: boolean = true
  @Output() onRemoved: EventEmitter<null> = new EventEmitter<null>()
  @Output() onFormChanged: EventEmitter<{ isValid: boolean; hasDirtyAndInvalidInputs: boolean }> = new EventEmitter<{
    isValid: boolean
    hasDirtyAndInvalidInputs: boolean
  }>()
  formValueChangeSubcription: Subscription

  intakeFormGroup: UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.intakeFormGroup = this.formBuilder.group({
      intakeGroup: this.formBuilder.group({
        fileName: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        field: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        incorrectValue: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        correctValue: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
        explanation: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      }),
    })
  }

  ngAfterViewInit(): void {
    this.formValueChangeSubcription = this.intakeFormGroup.get("intakeGroup").valueChanges.subscribe((form) => {
      this.trimInputs()
      let isValid = this.intakeFormGroup.get("intakeGroup").valid
      let hasDirtyAndInvalidInputs =
        this.showFileNameError() ||
        this.showFieldError() ||
        this.showIncorrectValueError() ||
        this.showCorrectValueError() ||
        this.showExplanationError()

      this.onFormChanged.emit({ isValid, hasDirtyAndInvalidInputs })
    })
  }

  public setFormData(data: IntakeFormData) {
    let intakeGroup = this.intakeFormGroup.get("intakeGroup")
    let fileName = intakeGroup.get("fileName")
    let field = intakeGroup.get("field")
    let incorrectValue = intakeGroup.get("incorrectValue")
    let correctValue = intakeGroup.get("correctValue")
    let explanation = intakeGroup.get("explanation")

    setTimeout(() => {
      fileName.setValue(data.fileName)
      field.setValue(data.field)
      incorrectValue.setValue(data.incorrectValue)
      correctValue.setValue(data.correctValue)
      explanation.setValue(data.explanation)
    })
  }

  public getValue(id) {
    return this.intakeFormGroup.get("intakeGroup").get(id).value
  }

  trimInputs() {
    let intakeGroup = this.intakeFormGroup.get("intakeGroup")
    let fileName = intakeGroup.get("fileName")
    let field = intakeGroup.get("field")
    let incorrectValue = intakeGroup.get("incorrectValue")
    let correctValue = intakeGroup.get("correctValue")
    let explanation = intakeGroup.get("explanation")

    if (fileName.value.length > 100) {
      fileName.setValue(fileName.value.slice(0, 100))
    }

    if (field.value.length > 100) {
      field.setValue(field.value.slice(0, 100))
    }

    if (incorrectValue.value.length > 100) {
      incorrectValue.setValue(incorrectValue.value.slice(0, 100))
    }

    if (correctValue.value.length > 100) {
      correctValue.setValue(correctValue.value.slice(0, 100))
    }

    if (explanation.value.length > 250) {
      explanation.setValue(explanation.value.slice(0, 250))
    }
  }

  public showFileNameError(): boolean {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const fileName = intakeGroup.get("fileName")
    return fileName.invalid && fileName.dirty
  }

  public showFieldError(): boolean {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const field = intakeGroup.get("field")
    return field.invalid && field.dirty
  }

  public showIncorrectValueError(): boolean {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const incorrectValue = intakeGroup.get("incorrectValue")
    return incorrectValue.invalid && incorrectValue.dirty
  }

  public showCorrectValueError(): boolean {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const correctValue = intakeGroup.get("correctValue")
    return correctValue.invalid && correctValue.dirty
  }

  public showExplanationError(): boolean {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const explanation = intakeGroup.get("explanation")
    return explanation.invalid && explanation.dirty
  }

  public getInputLength(key): number {
    const intakeGroup = this.intakeFormGroup.get("intakeGroup")
    const explanation = intakeGroup.get(key)
    return explanation.value.length
  }

  removeForm() {
    this.onRemoved.emit()
  }

  ngOnDestroy(): void {
    this.formValueChangeSubcription.unsubscribe()
  }
}
