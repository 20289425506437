import { HttpHeaders } from "@angular/common/http"

export const privacyCenterApiOptions = {
  headers: new HttpHeaders({
    "api-key": "RTM",
    "Content-Type": "application/json; v=1",
    Accept: "application/json; v=1",
  }),
  withCredentials: true,
}

export let downloadApiOptions = {
  headers: new HttpHeaders({
    Accept: "application/zip; v=1",
  }),
  responseType: "blob" as "blob",
  observe: "events" as "events",
  reportProgress: true,
}
