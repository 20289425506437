import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { Observable } from "rxjs"
import { QUERY_PARAMS } from "src/app/common"
import { downloadStatus } from "src/app/features/download-ready-data/download-ready-data.component"
import { RequestType } from "src/app/models/common/RequestType"
import { PrivacyDataRequestStatus, PrivacyDataRequestSummaryItem } from "src/app/models/PrivacyRequest"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"

enum Status {
  IN_PROGRESS = 1,
  EXPIRED = 2,
  VERIFICATION_FAILED = 3,
  VERIFY_AND_DOWNLOAD = 4,
  DOWNLOAD = 5,
  DOWNLOAD_AGAIN = 6,
  DOWNLOADING = 7,
  NONE = 8,
}

@Component({
  selector: "c1-privacy-request",
  templateUrl: "./request.component.html",
  styleUrls: ["./request.component.scss"],
})
export class RequestComponent implements OnInit {
  @Input() request: PrivacyDataRequestSummaryItem
  @Input() queryParams: any = {}
  @Input() downloadStatus: string = ""
  @Input() type: RequestType
  @Input() divider: boolean = false
  @Output() onExpanded: EventEmitter<null> = new EventEmitter<null>()
  @Output() onDownloadClicked: EventEmitter<any> = new EventEmitter<any>()
  open = false
  expiredDate$: Observable<string>
  completedDate$: Observable<string>

  content: { [key: string]: string } = {
    download: "PORTAL_OF_HISTORY.REQUEST.DOWNLOAD",
    verifyAndDownload: "PORTAL_OF_HISTORY.REQUEST.VERIFY_AND_DOWNLOAD",
    verificationFailed: "PORTAL_OF_HISTORY.REQUEST.VERIFICATION_FAILED",
    downloadAgain: "PORTAL_OF_HISTORY.REQUEST.DOWNLOAD_AGAIN",
    inProgress: "PORTAL_OF_HISTORY.REQUEST.IN_PROGRESS_TEXT",
    downloading: "PORTAL_OF_HISTORY.REQUEST.DOWNLOADING",
  }

  public get PrivacyDataRequestStatus() {
    return PrivacyDataRequestStatus
  }

  public get RequestType() {
    return RequestType
  }

  public get Status() {
    return Status
  }

  constructor(
    public privacyRequestService: PrivacyRequestService,
    private oneTagService: OneTagService
  ) {}

  ngOnInit(): void {
    if (this.type === RequestType.DOWNLOAD) {
      this.expiredDate$ = this.privacyRequestService.getExpirationDate(this.request.privacyDataRequestId)
    }
    if (this.type === RequestType.DELETE && this.request.requestStatus === PrivacyDataRequestStatus.COMPLETED) {
      this.completedDate$ = this.privacyRequestService.getCompletedDate(this.request.privacyDataRequestId)
    }
  }

  expand() {
    this.open = !this.open

    this.onExpanded.emit()
  }

  downloadClicked(button) {
    if (this.onDownloadClicked) {
      this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'downloadClicked');
      this.onDownloadClicked.emit({ button: button })
    }
  }

  getShownStatus(): Status {
    if (
      this.request.requestStatus === PrivacyDataRequestStatus.IN_PROGRESS ||
      this.request.requestStatus === PrivacyDataRequestStatus.CREATED ||
      this.request.requestStatus === PrivacyDataRequestStatus.NOTIFIED
    ) {
      return Status.IN_PROGRESS
    } else if (
      this.request.requestStatus === PrivacyDataRequestStatus.COMPLETED &&
      this.type === RequestType.DOWNLOAD
    ) {
      return Status.EXPIRED
    } else if (
      (this.request.requestStatus === PrivacyDataRequestStatus.FULFILLED ||
        this.request.requestStatus === PrivacyDataRequestStatus.DATA_READY) &&
      (!this.queryParams.q || this.queryParams.q !== QUERY_PARAMS.STEP_UP_SUCCESS) &&
      this.queryParams.query !== 2
    ) {
      return Status.VERIFY_AND_DOWNLOAD
    } else if (
      (this.request.requestStatus === PrivacyDataRequestStatus.FULFILLED ||
        this.request.requestStatus === PrivacyDataRequestStatus.DATA_READY) &&
      this.queryParams.q &&
      this.queryParams.q === QUERY_PARAMS.STEP_UP_SUCCESS &&
      this.queryParams.query !== 2 &&
      this.downloadStatus === downloadStatus.DOWNLOAD
    ) {
      return Status.DOWNLOAD
    } else if (
      (this.request.requestStatus === PrivacyDataRequestStatus.FULFILLED ||
        this.request.requestStatus === PrivacyDataRequestStatus.DATA_READY) &&
      this.queryParams.q &&
      this.queryParams.q === QUERY_PARAMS.STEP_UP_SUCCESS &&
      this.queryParams.query !== 2 &&
      this.downloadStatus === downloadStatus.DOWNLOADING
    ) {
      return Status.DOWNLOADING
    } else if (
      (this.request.requestStatus === PrivacyDataRequestStatus.FULFILLED ||
        this.request.requestStatus === PrivacyDataRequestStatus.DATA_READY) &&
      this.queryParams.q &&
      this.queryParams.q === QUERY_PARAMS.STEP_UP_SUCCESS &&
      this.queryParams.query !== 2 &&
      this.downloadStatus === downloadStatus.DOWNLOAD_AGAIN
    ) {
      return Status.DOWNLOAD_AGAIN
    } else {
      return Status.NONE
    }
  }
}

//decreate portal history api calls
