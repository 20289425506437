import { ActorType, RequestChannel, RequestType } from "./common"

export enum EligibilityStatus {
  INELIGIBLE_PENDING_REQUEST = "INELIGIBLE_PENDING_REQUEST",
  INELIGIBLE_MAX_LIMIT = "INELIGIBLE_MAX_LIMIT",
  INELIGIBLE_MULTIPLE_SSO = "INELIGIBLE_PENDING_REQUEST_WITH_DIFFERENT_PROFILE",

  INELIGIBLE_DOWNLOAD_FILE_NOT_DOWNLOADED = "INELIGIBLE_DOWNLOAD_FILE_NOT_DOWNLOADED",
  INELIGIBLE_NO_VALID_DOWNLOAD_REQUEST = "INELIGIBLE_NO_VALID_DOWNLOAD_REQUEST",

  INELIGIBLE_PENDING_DOWNLOAD_REQUEST = "INELIGIBLE_PENDING_DOWNLOAD_REQUEST",
  INELIGIBLE_PENDING_CORRECTION_REQUEST = "INELIGIBLE_PENDING_CORRECTION_REQUEST",

  INELIGIBLE_NO_VALID_RTC_COMPLETED = "INELIGIBLE_NO_VALID_RTC_COMPLETED",

  ELIGIBLE = "ELIGIBLE",
}

export enum PrivacyDataRequestStatus {
  CREATED = "CREATED",
  UNACTIONABLE = "UNACTIONABLE",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  FULFILLED = "FULFILLED",
  DATA_READY = "DATA_READY",
  EXPIRED = "EXPIRED",
  NOTIFIED = "NOTIFIED",
}

export interface PrivacyRequestCategory {
  accounts: [
    {
      accountReferenceId: string
    }
  ]
  actorIdentifier: {
    actorType: ActorType
  }
  applicationWebUsageData: {
    categoryIds: string[]
  }
  customerData: {
    categoryIds: string[]
  }
  requestChannel: RequestChannel
}

export interface PrivacyRequestResponse {
  privacyDataRequestId: string
}

export interface PrivacyRequest {
  privacyDataRequestId: string
  privacyDataRequestCreatedTimestamp: string
  privacyDataRequestStatus: PrivacyDataRequestStatus
}

export interface PrivacyRequestEligibilityResponse {
  eligibilityPrivacyDataRequests: PrivacyRequestEligibility[]
}

export interface PrivacyDataRequestSummaryItem {
  privacyDataRequestId: string
  requestCreatedTimestamp: string
  requestStatus: PrivacyDataRequestStatus
  requestStatusTimestamp: string
}

export interface PrivacyPortalHistoryResponse {
  privacyDataRequestSummaryItems: PrivacyDataRequestSummaryItem[]
  limit: number
  offset: number
  count: number
}

export interface RelatedEligibilityRequest {
  privacyDataRequestId: string
  requestType: string
}

export class PrivacyRequestEligibility {
  eligibilityStatus: EligibilityStatus
  nextAvailableRequestTimestamp?: string
  requestType: RequestType
  pendingRequest?: PrivacyRequest
  completedRequestHistory?: PrivacyRequest[]
  relatedEligibilityRequests?: RelatedEligibilityRequest[]
  constructor(pr: PrivacyRequestEligibility) {
    this.eligibilityStatus = pr.eligibilityStatus
    this.requestType = pr.requestType
    this.pendingRequest = pr.pendingRequest
    this.completedRequestHistory = pr.completedRequestHistory
    this.nextAvailableRequestTimestamp = pr.nextAvailableRequestTimestamp
    this.relatedEligibilityRequests = pr.relatedEligibilityRequests
  }

  get pendingRequestId(): string {
    return this.pendingRequest.privacyDataRequestId
  }

  get pendingRequestDataStatus(): PrivacyDataRequestStatus {
    return this.pendingRequest.privacyDataRequestStatus
  }
}
