<c1-privacy-request
  [request]="request"
  [type]="type"
  [queryParams]="queryParams"
  [downloadStatus]="currentStatus"
  (onDownloadClicked)="onDownloadButtonClicked()"
  (onExpanded)="onExpanded()"
  [queryParams]="queryParams"
  [divider]="divider"
>
  <c1-privacy-connex-skeleton
    [data]="downloadPageData$ | async"
    [componentName]="ConnexStatus.DOWNLOAD_READY"
  ></c1-privacy-connex-skeleton>

  <ng-container *ngIf="hasEmailFailed$ | async as emailFailed">
    <c1-privacy-banner
      [alertType]="'WARNING'"
      [showMaintenance]="false"
      [canClose]="false"
      *ngIf="
        emailFailed.failed &&
        (this.request.requestStatus === PrivacyDataRequestStatus.FULFILLED ||
          this.request.requestStatus === PrivacyDataRequestStatus.DATA_READY)
      "
    >
      {{
        "PORTAL_OF_HISTORY.DOWNLOAD.EMAIL_FAILED"
          | translate: { email: email$ | async, date: emailFailed.date | timestamp: false:false }
      }}
    </c1-privacy-banner>
  </ng-container>

  <ng-container
    *ngIf="{
      vm: downloadPageData$ | async,
      nullState: nullState$ | async,
      noAssetDataCategories: noAssetDataCategories$ | async
    } as data"
  >
    <ng-container *ngIf="data.vm">
      <div aria-hidden="true" class="icon grv-col--md-1 grv-col--lg-1"></div>
      <div class="request-detail grv-col--xs-3 grv-col--sm-3 grv-col--md-7 grv-col--lg-11">
        <h3 class="grv-text--medium">{{ "DOWNLOAD_DATA.EXPLAINED.HEADER" | translate }}</h3>
        <p class="grv-text--normal">
          {{ "DOWNLOAD_DATA.EXPLAINED.SUBHEADER" | translate }} <br />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="{{ 'DOWNLOAD_DATA.EXPLAINED.LINK' | translate }}"
            class="c1-privacy-download-data__main__anchor grv-text--normal grv-text--semibold grv-color--interaction-blue-50"
            >{{ "DOWNLOAD_DATA.EXPLAINED.MORE" | translate }}</a
          >
        </p>

        <p class="grv-text__heading--normal" id="download-heading">
          {{ "DOWNLOAD_READY_DATA.DATA_HEADER" | translate: { downloadSize: data.vm.assets.downloadSize } }}
        </p>

        <div *ngIf="data.nullState" id="null-data">
          <c1-privacy-icon iconName="fraud" class="grv-float--left icon" [size]="'tiny'"> </c1-privacy-icon>

          <div class="grv-text--normal grv-padding__left--xlarge grv-padding__bottom--medium">
            {{ "DOWNLOAD_DATA.NULL" | translate }}
          </div>
        </div>

        <div *ngIf="!data.nullState">
          <div
            *ngFor="let category of data.vm.selectedCategories"
            class="grv-margin__bottom--small"
            id="download-categories"
          >
            <div class="grv-text--medium-large grv-margin__top--small title-row">
              <div>
                <c1-privacy-icon
                  *ngIf="category.iconName"
                  [iconName]="category.iconName"
                  class="grv-float--left icon"
                  [size]="'tiny'"
                ></c1-privacy-icon>
                <div class="category-title-container">
                  <h2 class="category-title__header grv-padding__left--medium">
                    {{ category.categoryName }}
                  </h2>
                </div>
              </div>
              <div class="grv-margin__right--normal checkmark">
                <c1-privacy-icon iconName="checkmark" size="{{ checkmarkSize }}"></c1-privacy-icon>
              </div>
            </div>

            <div *ngIf="category.subcategories && category.subcategories.length > 0">
              <div
                *ngFor="let subcategory of category.subcategories; first as first"
                class="grv-text--normal grv-padding__left--xlarge"
                [class.grv-padding__top--small]="!first"
                c1PrivacyMaskCardNumber
                [content]="subcategory.subcategoryValueDescription"
              ></div>
            </div>
            <div
              *ngIf="category.categoryName == 'Non-Account Data'"
              class="grv-text--normal grv-padding__left--xlarge grv-padding__bottom--normal"
            >
              {{ "DOWNLOAD_DATA.NON_ACCOUNT.SUBHEADER" | translate }}
            </div>
          </div>
        </div>

        <div>
          <div *ngIf="assetCategories$ | async as assetCategories" class="asset-categories grv-text--normal">
            <c1-privacy-asset-categories *ngIf="assetCategories.length > 0" [assetCategories]="assetCategories">
            </c1-privacy-asset-categories>
          </div>
        </div>

        <div id="card-content-bottom">
          <ng-container>
            <div *ngIf="!data.nullState && !data.noAssetDataCategories" id="text-download-ready">
              <p class="grv-text--normal">
                {{ "DOWNLOAD_DATA.NON_ACCOUNT.HEADER_TEXT" | translate }}
              </p>
              <p class="grv-text--normal" *ngIf="queryParams.query !== 2">
                {{ "DOWNLOAD_DATA.RECOMMEND" | translate }}
              </p>
            </div>
            <p *ngIf="data.nullState && !data.noAssetDataCategories" class="grv-text--normal" id="text-null-download">
              {{ "DOWNLOAD_DATA.NULL_DOWNLOAD" | translate }}
            </p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</c1-privacy-request>
