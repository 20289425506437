import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs"
import { Category, Introspect } from "../../models"
import { ActorType } from "src/app/models/common"
import { ccpaCookies } from "src/app/common"
import { ActorTypeOLMap } from "src/app/models/common/ActorType"

export interface CategoryResponse {
  categories: Category[]
  introspect: Introspect
}

@Injectable({
  providedIn: "root",
})
export class IntrospectService {
  actorType$: Observable<ActorType> = of(this._cookieReader())

  public getActorType(): string {
    const actor = this._cookieReader()
    return typeof actor === "string" ? actor.toLowerCase() : ""
  }

  private _cookieReader(): ActorType {
    let actorCookie = document.cookie
      .split(";")
      .find((item) => item.trim().indexOf(`${ccpaCookies.ccpaActorType}=`) === 0)
    actorCookie = actorCookie ? actorCookie.split("=")[1] : null
    if (actorCookie && actorCookie === ActorTypeOLMap.CUSTOMER) {
      return ActorType.CUSTOMER
    } else if (actorCookie && actorCookie === ActorTypeOLMap.NON_CUSTOMER) {
      return ActorType.NON_CUSTOMER
    } else {
      return {} as ActorType
    }
  }
  constructor() {}
}
