<c1-privacy-request
  [request]="request"
  [type]="type"
  (onExpanded)="onExpanded()"
  [queryParams]="queryParams"
  [divider]="divider"
>
  <!--in progress ui-->

  <div class="grv-margin__top--small">
    <c1-privacy-connex-skeleton
      [data]="selectedCategories$ | async"
      [componentName]="
        type == RequestType.DOWNLOAD ? ConnexStatus.DOWNLOAD_IN_PROGRESS : ConnexStatus.DELETE_IN_PROGRESS
      "
    ></c1-privacy-connex-skeleton>

    <ng-container *ngIf="selectedCategories$ | async as selectedCategories">
      <p
        class="grv-text__block info-section in_progress_text"
        *ngIf="
          request.requestStatus != PrivacyDataRequestStatus.COMPLETED &&
          request.requestStatus != PrivacyDataRequestStatus.EXPIRED
        "
      >
        {{ "REQUEST_IN_PROGRESS.NO_CHANGES" | translate }}
      </p>

      <p *ngIf="type == RequestType.DOWNLOAD" class="grv-text__block info-section grv-text--small-semibold">
        {{ "REQUEST_IN_PROGRESS.CATEGORIES_HEADING" | translate }}
      </p>

      <p *ngIf="type == RequestType.DELETE" class="grv-text__block info-section grv-text--small-semibold">
        {{ "DELETION_IN_PROGRESS.CATEGORIES_HEADING" | translate }}
      </p>

      <div *ngFor="let category of selectedCategories">
        <p class="grv-text--medium-large grv-margin__top--small">
          <span class="category-container">
            <span class="grv-float--left icon">
              <c1-privacy-icon
                *ngIf="category.iconName"
                [iconName]="category.iconName"
                [size]="'tiny'"
              ></c1-privacy-icon>
            </span>

            <span>
              {{ category.categoryName }}
              <div *ngIf="category.subcategories && category.subcategories.length > 0">
                <div
                  class="subcategory-container grv-margin__top--tiny"
                  *ngFor="let subcategory of category.subcategories"
                >
                  <div *ngIf="subcategory.isSubcategorySelected">
                    <br />
                    <span
                      class="grv-text--small"
                      c1PrivacyMaskCardNumber
                      [content]="subcategory.subcategoryValueDescription"
                    >
                    </span>
                  </div>
                </div>
              </div>
              <div class="grv-text__block info-section" *ngIf="category.categoryId == 'NON-ACCOUNT_DATA_DOWNLOAD'">
                {{ "REQUEST_IN_PROGRESS.NON_ACCOUNT_DESCRIPTION" | translate }}
              </div>

              <div class="grv-text__block info-section" *ngIf="category.categoryId == 'NON-ACCOUNT_DATA_DELETE'">
                {{ "DELETION_IN_PROGRESS.NON_ACCOUNT_DESCRIPTION" | translate }}
              </div>
            </span>
            <span class="grv-float--right">
              <span class="grv-margin__right--normal checkmark">
                <c1-privacy-icon iconName="checkmark" [size]="checkmarkSize"></c1-privacy-icon>
              </span>
            </span>
          </span>
        </p>
      </div>
    </ng-container>
  </div>
</c1-privacy-request>
