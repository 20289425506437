<div class="c1-privacy-subheader__main">
  <c1-privacy-icon [iconName]="subheaderContent.icon" [size]="'large'"></c1-privacy-icon>

  <div class="grv-grid">
    <div class="grv-row grv-row--sm-center">
      <div class="cp1-privacy__max-width">
        <h1
          #focusRef
          class="grv-text__heading--large c1-privacy-subheader__h1--margin"
          [innerHTML]="subheaderContent.headerText | translate"
        ></h1>
        <h2
          *ngIf="subheaderContent.subheaderText && subheaderContent.subheaderText.length > 0"
          class="grv-text__body--medium grv-weight--light c1-privacy-subheader__h2--margin"
          [innerHTML]="subheaderContent.subheaderText | translate"
        ></h2>
        <ng-content select="[subheader-action]"></ng-content>
      </div>
    </div>
  </div>
</div>
