import { Inject, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms"
import { Router } from "@angular/router"
import { Observable, throwError } from "rxjs"
import { map, pluck, shareReplay, catchError } from "rxjs/operators"

import { privacyCenterApiOptions } from "../http-options"
import { Category, Introspect } from "../../models"
import { RouteName } from "src/app/route-name"
import { RequestType } from "src/app/models/common"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "../maintenance-banner/maintenance-banner.service"
import {MaintenanceTypes} from "../../enums/maintenance-types";

const categoryIcons = {
  YOUR_IDENTITY: "user",
  YOUR_SECURITY: "fraud",
  OFFERS_PROMOTIONS_AND_SERVICES: "offers",
  ANALYTICS_AND_DIGITAL_EXPERIENCES: "gauge",
  ACCOUNT_DATA: "features",
  "NON-ACCOUNT_DATA_DOWNLOAD": "dataStaggered",
  "NON-ACCOUNT_DATA_DELETE": "dataStaggered",
}

export const mapIconsToCategories = map((categories: Category[]) =>
  categories.map((category) => ({
    ...category,
    iconName: categoryIcons[category.categoryId] || categoryIcons.YOUR_IDENTITY,
  }))
)

export interface CategoryResponse {
  categories: Category[]
  introspect: Introspect
}

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private router: Router,
    private newRelicService: NewRelicService,
    private maintenanceBannerService: MaintenanceBannerService
  ) {
    this.baseUrl = config.apiURL
  }

  categoryValue: Category[]
  selectedCategories: Category[]
  requestSuccessful = false
  baseUrl: string
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()
  maintenanceType: MaintenanceTypes = this.maintenanceBannerService.getType()

  private _requestCache: {
    // @ts-ignore
    [key: RequestType]: Observable<CategoryResponse>
  } = {}

  private _categoryResponse$: (requestType: RequestType) => Observable<CategoryResponse> = (requestType) => {
    if (this._requestCache.hasOwnProperty(requestType)) {
      return this._requestCache[requestType]
    } else {
      this._requestCache[requestType] = this.http
        .get<CategoryResponse>(`${this.baseUrl}/request/categories?requestType=${requestType}`, privacyCenterApiOptions)
        .pipe(
          retryWithBackoff((msg) =>
            this.newRelicService.logCustomError(msg, {
              logLevel: ErrorLoggingLevel.Error,
            })
          ),
          catchError((err) => {
            this.newRelicService.logCustomError(
              // tslint:disable-next-line:max-line-length
              `Error in the GET call to /request/categories?requestType=${requestType}. The user was navigated to the Snag page. ${JSON.stringify(
                err
              )}`,
              { logLevel: ErrorLoggingLevel.Error }
            )
            if (this.maintenance) {
              this.router.navigate([`/${RouteName.Unavailable}`])
            } else {
              this.router.navigate([`/${RouteName.Snag}`])
            }
            // return of({} as CategoryResponse)
            return throwError(err)
          }),
          shareReplay(1)
        )
      return this._requestCache[requestType]
    }
  }

  categories$: (requestType: RequestType) => Observable<Category[]> = (requestType) =>
    this._categoryResponse$(requestType).pipe(
      pluck("categories"),
      this.castSubcategoryIndices(),
      mapIconsToCategories,
      this.applyEditability()
    )

  introspect$: (requestType: RequestType) => Observable<Introspect> = (requestType) =>
    this._categoryResponse$(requestType).pipe(pluck("introspect"), this.defaultPrimaryEmail())

  castSubcategoryIndices() {
    return map((categories: Category[]) => {
      categories.map((category) => {
        if (category.subcategories) {
          category.subcategories = category.subcategories.map((sc) => ({
            ...sc,
            subcategoryDisplayIndex: sc.subcategoryDisplayIndex.toString(),
          }))
        }
      })
      return categories
    })
  }

  applyEditability() {
    return map((categories: Category[]) => {
      categories.forEach((category) => (category.editable = true))
      if (categories.length === 1 && categories[0].categoryId === "NON-ACCOUNT_DATA_DELETE") {
        categories[0].editable = false
      }
      return categories
    })
  }

  defaultPrimaryEmail() {
    return map((introspect: Introspect) => {
      if (!introspect.primaryEmailAddress) {
        introspect.primaryEmailAddress = ""
      }
      return introspect
    })
  }

  atLeastOne(control: UntypedFormGroup): ValidationErrors | null {
    const flattenObject = (object) => {
      const _flatten = (o) => {
        return [].concat(...Object.keys(o).map((k) => (typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] })))
      }
      return Object.assign({}, ..._flatten(object))
    }
    const flatValues = flattenObject(control.value)
    return Object.values(flatValues).some((val) => !!val) ? null : { atLeastOne: true }
  }

  testCategoryArray(categoryArray: Category[]): Category[] | null {
    if (categoryArray && categoryArray.length > 0) {
      return categoryArray
    }
    return null
  }

  toForm(categories: Category[]): UntypedFormGroup {
    const group: any = {}
    categories.forEach((category) => {
      if (category.subcategories && category.subcategories.length > 0) {
        const subcategoryGroup: any = {}
        category.subcategories.forEach(
          (subcategory) =>
            (subcategoryGroup[subcategory.subcategoryDisplayIndex] = this.initializeFormControl(category))
        )
        group[category.categoryName] = new UntypedFormGroup(subcategoryGroup)
      } else {
        group[category.categoryName] = this.initializeFormControl(category)
      }
    })
    return new UntypedFormGroup(group, { validators: this.atLeastOne })
  }

  private initializeFormControl(category: Category): UntypedFormControl {
    return category.editable ? new UntypedFormControl(false) : new UntypedFormControl({ value: true, disabled: true })
  }

  saveCategoryForm(form: UntypedFormGroup, categories: Category[]): void {
    const _categories: Category[] = []
    const _selectedCategories: Category[] = []
    const categoryMap = new Map<string, Category>(categories.map((x) => [x.categoryName, x]))

    Object.keys(form.value).forEach((key) => {
      const category = categoryMap.get(key)
      if (typeof form.value[key] === "object") {
        let oneSelected = false
        const updatedSubcategories = []
        const selectedSubcategories = []
        Object.keys(form.value[key]).forEach((subkey) => {
          const subcategory = category.subcategories.find((sc) => sc.subcategoryDisplayIndex === subkey)
          const updatedSubcategoryValue = {
            ...subcategory,
            isSubcategorySelected: form.value[key][subkey],
          }
          if (form.value[key][subkey]) {
            oneSelected = form.value[key][subkey]
            selectedSubcategories.push(updatedSubcategoryValue)
          }
          updatedSubcategories.push(updatedSubcategoryValue)
        })
        _categories.push({
          ...category,
          isCategorySelected: oneSelected,
          subcategories: updatedSubcategories,
        })
        if (oneSelected) {
          _selectedCategories.push({
            ...category,
            isCategorySelected: oneSelected,
            subcategories: selectedSubcategories,
          })
        }
      } else {
        _categories.push({
          ...category,
          isCategorySelected: form.value[key],
        })
        if (form.value[key]) {
          _selectedCategories.push({
            ...category,
            isCategorySelected: form.value[key],
          })
        }
      }
    })

    this.selectedCategories = _selectedCategories
    this.categoryValue = _categories
  }
}
