<h3 class="category-title grv-text--normal grv-float--left grv-padding__bottom--small">
  {{ title }}
  <button
    *ngIf="!hasData"
    aria-label="Learn more"
    class="info-link"
    (click)="infoOpen()"
    (keyup.enter)="infoOpen()"
    (keyup.space)="infoOpen()"
  >
    <c1-privacy-icon iconName="info" size="{{ infoSize }}"></c1-privacy-icon>
  </button>
</h3>
<c1-privacy-data-excluded-info></c1-privacy-data-excluded-info>

<div class="category-list">
  <div class="category-entry" *ngFor="let category of assetCategories">
    <c1-privacy-icon class="catergory-icon" iconName="{{ iconName }}" size="{{ iconSize }}"></c1-privacy-icon>
    <span class="category-name">{{ category.dataCategoryName }}</span>
  </div>
</div>
