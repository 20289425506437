import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AuthenticationService } from "../../services/auth/authentication.service"
import { clientCorrelationId } from "../../common"

@Injectable({
  providedIn: "root",
})
export class PrivacyRequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the tracking cookie from the service.
    const trackingCookie = this.authService.getTrackingCookie()

    // Clone the request and replace the original headers with
    // cloned headers, updated with the tracking cookie.
    const authReq = req.clone({
      headers: req.headers.set(clientCorrelationId, trackingCookie),
    })

    // send cloned request with header to the next handler.
    return next.handle(authReq)
  }
}
