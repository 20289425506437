<c1-privacy-banner
  class="maintenance-banner grv-margin--none"
  [alertType]="'GLOBAL'"
  [canClose]="false"
  [showMaintenance]="true"
  *ngIf="maintenance"
>
</c1-privacy-banner>
<main class="c1-privacy-sign-in-options__main grv-padding__bottom--xxlarge">
  <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent"></c1-privacy-subheader>
  <p class="grv-text--medium grv-text--light description">
    {{ "SIGN_IN_OPTIONS.DESCRIPTION" | translate }}
  </p>
  <div>
    <button (click)="goToGWLogin()" class="grv-button--progressive" data-cy="personal-sb-button">
      {{ "SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_LABEL" | translate }}
    </button>
  </div>
  <p
    class="grv-text--normal description"
    c1PrivacyTranslateWithHtml
    [translateKey]="'SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_DESCRIPTION.TEXT'"
    [htmlConfig]="htmlConfig"
  ></p>

  <div *ngFor="let button of otherIdentityButtonContent" class="grv-margin__bottom--small">
    <button class="grv-button--progressive" [id]="button.id" (click)="goToGovId()">
      {{ button.label | translate }}
    </button>
  </div>

  <div class="grv-margin__bottom--large grv-margin__top--large">
    <a
      (click)="callCenterDialogOpen()"
      class="grv-text__link--no-style grv-text--normal link"
      data-cy="phone-number-link"
      tabindex="0"
      href="javascript:void(0)"
      routerLink="."
      (keyup.enter)="callCenterDialogOpen()"
    >
      {{ "SIGN_IN_OPTIONS.CALL_BUTTON_LABEL" | translate }}
    </a>
    <c1-privacy-call-center-dialog> </c1-privacy-call-center-dialog>
  </div>

  <div>
    <span
    class="grv-text--normal small-text-on-mobile"
    >{{"FRONT_DOOR.ALT_CUSTOMER" | translate}}
      <b>{{"FRONT_DOOR.CNDA_HTML_TEXT" | translate}}</b>
      {{"FRONT_DOOR.ALT_CUSTOMER_2" | translate}}
      {{"FRONT_DOOR.CNDA" | translate}}
      <a (click)="caPrivacyPolicy()" role="button" tabindex="0" class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none cursor" >{{"FRONT_DOOR.CNDA_LINK_TEXT" | translate}}</a>.
    </span>
  </div>
  <div>
    <span
    class="grv-text--normal small-text-on-mobile">
    {{"FRONT_DOOR.ALT_CUSTOMER" | translate}}
      <b>{{"FRONT_DOOR.UK_HTML_TEXT" | translate}}</b>
      {{"FRONT_DOOR.ALT_CUSTOMER_2" | translate}}
      {{"FRONT_DOOR.UK" | translate}}
      <a (click)="ukPrivacyPolicy()" role="button" tabindex="0" class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none cursor" >{{"FRONT_DOOR.UK_LINK_TEXT" | translate}}</a>.
    </span>
  </div>
</main>
