<ng-template [ngIf]="!isMobile" [ngIfElse]="mobileView">
  <div class="grid-format">
    <table class="grv-table grv-table--small">
      <caption class="grv-table__caption">
        <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.DOWNLOAD_HEADING" | translate }}</p>
      </caption>
      <thead class="grv-table__head">
        <tr class="grv-table__row">
          <th scope="col" class="grv-table__header grv-col--sm-12">{{ "TRANSPARENCY_REPORT.RESIDENCY" | translate }}</th>
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></th>
          <th scope="col" class="grv-table__header grv-table__header--right-align" style="width:30%">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_PROVIDED" | translate }}
          <th scope="col" class="grv-table__header grv-table__header--right-align">
            {{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_PROVIDED" | translate }}<sub>2</sub>
          </th>
        </tr>
      </thead>
      <tbody class="grv-table__body">
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_TOTAL" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_PROVIDED_CA" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_NOT_PROVIDED_CA" | translate }}</td>
        </tr>
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_TOTAL_NON_CA" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_PROVIDED_NON_CA" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_NOT_PROVIDED_NON_CA" | translate }}</td>
        </tr>
      </tbody>
    </table>
    <table class="grv-table grv-table--small">
      <caption class="grv-table__caption grv-margin__top--large-2">
        <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.DELETE_HEADING" | translate }}</p>
      </caption>
      <thead class="grv-table__head">
        <tr class="grv-table__row">
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.RESIDENCY" | translate }}</th>
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_DELETED" | translate }}
          </th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">
            {{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_DELETED" | translate }}<sub>2</sub>
          </th>
        </tr>
      </thead>
      <tbody class="grv-table__body">
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.DELETE_TOTAL" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            1
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.DATA.DELETE_NOT_PROVIDED_CA" | translate }}</td>
        </tr>
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.DELETE_TOTAL_NON_CA" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.DATA.DELETE_PROVIDED_NON_CA" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.DATA.DELETE_NOT_PROVIDED_NON_CA" | translate }}</td>
        </tr>
      </tbody>
    </table>

    <table class="grv-table grv-table--small">
      <caption class="grv-table__caption grv-margin__top--large-2">
        <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.CORRECT_HEADING" | translate }}</p>
      </caption>
      <thead class="grv-table__head">
        <tr class="grv-table__row">
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.RESIDENCY" | translate }}</th>
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_CORRECTED" | translate }}
          </th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">
            {{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_CORRECTED" | translate }}<sub>2</sub>
          </th>
        </tr>
      </thead>
      <tbody class="grv-table__body">
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.CORRECTION" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.NA" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
        </tr>
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.CORRECTION" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.NA" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
        </tr>
      </tbody>
    </table>

    <table class="grv-table grv-table--small">
      <caption class="grv-table__caption grv-margin__top--large-2">
        <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.MEDIAN_RESPONSE" | translate }} {{ "TRANSPARENCY_REPORT.MEDIAN_RESPONSE_DAYS" | translate }}<sub
            class="grv-text--small-2">3</sub></p>
      </caption>
      <thead class="grv-table__head">
        <tr class="grv-table__row">
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.RESIDENCY" | translate }}</th>
          <th scope="col" class="grv-table__header">{{ "TRANSPARENCY_REPORT.DOWNLOAD_HEADING" | translate }}</th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">{{ "TRANSPARENCY_REPORT.DELETE_HEADING" | translate }}
          </th>
          <th scope="col" class="grv-table__header grv-table__header--right-align">
            {{ "TRANSPARENCY_REPORT.CORRECT_HEADING" | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="grv-table__body">
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DOWNLOAD" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DELETE" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
        </tr>
        <tr class="grv-table__row">
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</td>
          <td class="grv-table__cell">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DOWNLOAD" | translate }}</td>
          <td class="grv-table__cell grv-table__cell--right-align">
            {{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DELETE" | translate }}
          </td>
          <td class="grv-table__cell grv-table__cell--right-align">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #mobileView>
  <table class="grv-table grv-table--small no-border">
    <caption class="grv-table__caption ">
      <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.DOWNLOAD_HEADING" | translate }}</p>
    </caption>
    <thead class="grv-table__head grv-margin__top--small-1">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_TOTAL" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_PROVIDED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_PROVIDED_CA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_PROVIDED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_NOT_PROVIDED_CA" | translate }}</td>
      </tr>
    </tbody>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_TOTAL_NON_CA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_PROVIDED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_PROVIDED_NON_CA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_PROVIDED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DOWNLOAD_NOT_PROVIDED_NON_CA" | translate }}</td>
      </tr>
    </tbody>
  </table>

  <hr class="solid">

  <table class="grv-table grv-table--small no-border">
    <caption class="grv-table__caption">
      <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.DELETE_HEADING" | translate }}</p>
    </caption>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DELETE_TOTAL" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_DELETED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">1</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_DELETED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DELETE_NOT_PROVIDED_CA" | translate }}</td>
      </tr>
    </tbody>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DELETE_TOTAL_NON_CA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_DELETED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DELETE_PROVIDED_NON_CA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_DELETED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.DELETE_NOT_PROVIDED_NON_CA" | translate }}</td>
      </tr>
    </tbody>
  </table>

  <hr class="solid">

  <table class="grv-table grv-table--small no-border">
    <caption class="grv-table__caption">
      <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.CORRECT_HEADING" | translate }}</p>
    </caption>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.CORRECTION" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_CORRECTED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_CORRECTED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.TOTAL_REC" | translate }}<sub>1</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.CORRECTION" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_DATA_CORRECTED" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.REQUESTS_NO_DATA_CORRECTED" | translate }}<sub>2</sub></td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
  </table>

  <hr class="solid">

  <table class="grv-table grv-table--small no-border">
    <caption class="grv-table__caption">
      <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.MEDIAN_RESPONSE" | translate }}</p>
      <p class="grv-text--medium-2 grv-margin--none">{{ "TRANSPARENCY_REPORT.MEDIAN_RESPONSE_DAYS" | translate }}<sub class="grv-text--small-2">3</sub></p>
    </caption>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.DOWNLOAD_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DOWNLOAD" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.DELETE_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DELETE" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.CORRECT_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
    <thead class="grv-table__head">
      <tr class="grv-table__row">
        <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">{{ "TRANSPARENCY_REPORT.NON_CALIFORNIA" | translate }}</th>
      </tr>
    </thead>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.DOWNLOAD_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DOWNLOAD" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.DELETE_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.DATA.MEDIAN_DELETE" | translate }}</td>
      </tr>
    </tbody>
    <tbody class="grv-table__body">
      <tr class="grv-table__row">
        <td class="grv-table__cell table-pad">{{ "TRANSPARENCY_REPORT.CORRECT_HEADING" | translate }}</td>
        <td class="grv-table__cell grv-table__cell--right-align table-pad">{{ "TRANSPARENCY_REPORT.NA" | translate }}</td>
      </tr>
    </tbody>
  </table>

  <hr class="solid no-margin">

</ng-template>
