import { Injectable, ElementRef } from "@angular/core"

@Injectable({
  providedIn: "root",
})
export class FocusTitleService {
  constructor() {}

  focusElement(elementRef: ElementRef) {
    elementRef.nativeElement.setAttribute("tabindex", "-1")
    elementRef.nativeElement.setAttribute("style", "outline: none;")
    elementRef.nativeElement.focus()
  }
}
