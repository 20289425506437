import { ActorType, RequestChannel, RequestType, VerificationMethodType } from "./common"

export interface Introspect {
  actorType: ActorType
  primaryEmailAddress: string
  requestChannel: RequestChannel
  requestType: RequestType
  verificationMethodType: VerificationMethodType
}

export class UserIntrospect {
  actorType: ActorType
  primaryEmailAddress: string
  requestChannel: RequestChannel
  requestType: RequestType
  verificationMethodType: VerificationMethodType

  constructor(introspect: Introspect) {
    this.actorType = introspect.actorType
    this.primaryEmailAddress = introspect.primaryEmailAddress
    this.requestChannel = introspect.requestChannel
    this.requestType = introspect.requestType
    this.verificationMethodType = introspect.verificationMethodType
  }

  isCustomer() {
    return this.actorType === ActorType.CUSTOMER
  }
}
