import { Inject, Injectable } from "@angular/core"
import { Subject, timer, fromEvent, merge, Observable } from "rxjs"
import { PrivacyRequestService } from "../privacy-request/privacy-request.service"
import { takeUntil, skipUntil, repeat, switchMap } from "rxjs/operators"
import { AppConfig, APP_CONFIG } from "src/app.config"

@Injectable({
  providedIn: "root",
})
export class TokenManagementService {
  constructor(@Inject(APP_CONFIG) config: AppConfig, private privacyRequestService: PrivacyRequestService) {
    this.config = config
    this.idleDialogTimer = timer(this.config.idleTimer, this.config.idleTimer)
    this.refreshTokenTimer = timer(this.config.refreshTimer, this.config.refreshTimer)
    this.refreshTokenTimer$ = this.refreshTokenTimer.pipe(
      skipUntil(this.privacyRequestService.publishRequestEligibility$)
    )
  }

  private config: AppConfig
  private idleDialogTimer: Observable<number>
  private _restartIdleTimer = new Subject()
  public refreshTokenTimer: Observable<number>
  public refreshTokenTimer$

  public eventStream$ = merge(
    fromEvent(document, "keydown"),
    fromEvent(document, "scroll"),
    fromEvent(document, "mouseover")
  )

  public idleDialogTimer$ = this.privacyRequestService.publishRequestEligibility$.pipe(
    switchMap(() => this.idleDialogTimer.pipe(takeUntil(merge(this._restartIdleTimer, this.eventStream$)), repeat()))
  )

  restartIdleTimer(): void {
    this._restartIdleTimer.next()
  }
}
