<c1-privacy-subheader-with-card [subheaderContent]="subheaderContent" class="c1-privacy-in-progress__main">
  <div class="page-content">
    <div class="grv-card">
      <div class="grv-card__content">
        <div *ngFor="let correction of corrections; index as i">
          <hr *ngIf="i > 0" class="grv-divider--normal grv-divider--dark" />

          <div>
            <span class="grv-text--medium-1 grv-weight--semibold"> {{ "INTAKE_FORM.CARD_HEADER" | translate }}</span>
          </div>

          <div class="grv-margin__top--medium-2">
            <span class="grv-text--medium-1 grv-weight--semibold">
              {{ "INTAKE_FORM.FILE_NAME_LABEL" | translate }}:</span
            >
            <br />
            <span id="file-name">{{ correction.fileName }}</span>
          </div>

          <div class="grv-margin__top--medium-2">
            <span class="grv-text--medium-1 grv-weight--semibold"> {{ "INTAKE_FORM.FIELD_LABEL" | translate }}:</span>
            <br />
            <span id="field">{{ correction.field }}</span>
          </div>

          <div class="correct-value-inputs">
            <div class="grv-margin__top--medium-2">
              <span class="grv-text--medium-1 grv-weight--semibold">
                {{ "INTAKE_FORM.INCORRECT_CONFIRMATION_LABEL" | translate }}:</span
              >
              <br />
              <span id="incorrect">{{ correction.incorrectValue }}</span>
            </div>

            <c1-privacy-icon class="" [iconName]="'arrowRight'" [size]="'angstrem'"> </c1-privacy-icon>

            <div class="grv-margin__top--medium-2">
              <span class="grv-text--medium-1 grv-weight--semibold">
                {{ "INTAKE_FORM.CORRECT_VALUE_LABEL" | translate }}:</span
              >
              <br />
              <span id="incorrect">{{ correction.correctValue }}</span>
            </div>
          </div>

          <div class="grv-margin__top--medium-2">
            <span class="grv-text--medium-1 grv-weight--semibold">
              {{ "INTAKE_FORM.EXPLANATION_CONFIRMATION_LABEL" | translate }}:</span
            >
            <br />
            <span id="explanation">{{ correction.explanation }}</span>
          </div>
        </div>

        <hr class="grv-divider--normal grv-divider--dark" />

        <div class="edit-container grv-margin__top--medium-2">
          <div class="email-content">
            <div class="grv-text--medium-1 grv-weight--semibold">{{ "INTAKE_FORM.EMAIL_NOTICE" | translate }}:</div>
            <div class="grv-margin__top--medium-2" id="email">{{ emailAddress }}</div>
          </div>

          <div class="edit-button-content">
            <span class="grv-text--medium-1">
              <c1-privacy-icon [iconName]="'pen'" [size]="'quark'"> </c1-privacy-icon>
              <a class="edit-text" role="button" tabindex="0" (click)="openDialog()">
                {{ "INTAKE_FORM.EDIT_BUTTON" | translate }}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="grv-margin__top--normal margin-fix submit-button-container">
      <button
        type="button"
        class="grv-button--regressive back-button grv-margin__right--medium-2"
        data-cy="back-button"
        tabindex="0"
        (click)="goBack()"
      >
        {{ "INTAKE_CONFIRMATION.GO_BACK_BUTTON" | translate }}
      </button>

      <button
        class="grv-button--action submit-button"
        [ngClass]="buttonClass"
        type="button"
        id="confirm-button"
        [disabled]="disableSubmitButton"
        (click)="submit()"
        (keyup)="submit()"
        data-cy="confirm-button"
      >
        {{ "INTAKE_CONFIRMATION.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </div>
</c1-privacy-subheader-with-card>

<c1-privacy-email-dialog [emailAddress]="emailAddress" (updatedEmailAddress)="updateEmail($event)">
</c1-privacy-email-dialog>
