<div #dialog class="grv-dialog">
  <div class="grv-dialog__overlay--dark" (click)="closeDialog()" (keyup)="closeDialog()"></div>
  <div class="grv-dialog__container" role="dialog" aria-modal="true" tabindex="-1">
    <button
      *ngIf="!disableClose"
      class="grv-dialog__close close"
      (click)="closeDialog()"
      (keyup)="closeDialog()"
      data-cy="close-dialog"
    >
      Close
    </button>
    <div class="grv-dialog__content dialog-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
