import { Component, Input, ViewChild } from "@angular/core"
import { DialogComponent } from "../dialog/dialog.component"
import { DialogWrapper } from "../dialog/dialog-wrapper"
import { RouteName } from "src/app/route-name"
import { DOWNLOAD_TYPE } from "src/app/guards/eligibility.guard"
import { Router } from "@angular/router"
import { Observable } from "rxjs"
import { PrivacyRequestEligibility } from "src/app/models/PrivacyRequest"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { AuthenticationService, AuthType } from "src/app/services/auth/authentication.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { IntakeFormService } from "src/app/services/intake-form/intake-form.service"

export enum InEligibileDialogType {
  AnnualLimit = "ANNUAL_LIMIT",
  DownloadInProgress = "DOWNLOAD_IN_PROGRESS",
  DeleteInProgress = "DELETE_IN_PROGRESS",
  CorrectionInProgress = "CORRECTION_IN_PROGRESS",
  CorrectionNoDownload = "CORRECTION_NO_DOWNLOAD",
  DownloadAvailable = "DOWNLOAD_AVAILABLE",
  CorrectionDownloadInProgress = "CORRECTION_DOWNLOAD_IN_PROGRESS"
}

@Component({
  selector: "c1-privacy-ineligible-request-dialog",
  templateUrl: "./ineligible-request-dialog.component.html",
  styleUrls: ["./ineligible-request-dialog.component.scss"],
})
export class IneligibleRequestDialogComponent implements DialogWrapper {
  content = {
    helpLink: "https://www.capitalone.com/support-center",
    createRequestLink: `/${RouteName.CreateRequest}`,
    createRequestQueryParam: { q: DOWNLOAD_TYPE.PRE_VALIDATION },
  }
  @ViewChild(DialogComponent) dialog: DialogComponent
  @Input() type: InEligibileDialogType

  public get InEligibileDialogType() {
    return InEligibileDialogType
  }

  constructor(
    public router: Router,
    public privacyRequestService: PrivacyRequestService,
    private newRelicService: NewRelicService,
    private authService: AuthenticationService,
    private intakeFormService: IntakeFormService
  ) {}

  eligibility$: Observable<PrivacyRequestEligibility> = this.privacyRequestService.downloadRequestStatus$
  requestId: String

  open() {
    this.requestId = this.intakeFormService.pendingRequestId
    this.dialog.disableClose = false
    this.dialog.openDialog()
  }

  close() {
    this.dialog.closeDialog()
  }

  goToCreate() {
    this.dialog.closeDialog()
    this.router.navigate([RouteName.CreateRequest], { queryParams: { q: DOWNLOAD_TYPE.PRE_VALIDATION } })
  }

  goToDownloadStepUp() {
    this.dialog.closeDialog()
    this.newRelicService.logCustomError(`User navigated to challenge app to initiate correction request`, {
      event: newRelicEvent.correctionToStepUp,
      stateField: `${this.authService.getStateForAuthType(AuthType.DownloadStepUp)}`,
      logLevel: ErrorLoggingLevel.Info,
    })
    window.location.href = this.authService.authUrl(AuthType.DownloadStepUp)
  }
}
