import { Component, ViewChild } from "@angular/core"
import { DialogWrapper } from "../dialog/dialog-wrapper"
import { DialogComponent } from "../dialog/dialog.component"
import { SubheaderContent } from "../subheader/SubheaderContent"

@Component({
  selector: "c1-privacy-data-excluded-info",
  templateUrl: "./data-excluded-info.component.html",
  styleUrls: ["./data-excluded-info.component.scss"],
})
export class DataExcludedInfoComponent implements DialogWrapper {
  @ViewChild(DialogComponent, { static: false })
  dialog: DialogComponent

  open() {
    this.dialog.disableClose = false
    this.dialog.openDialog()
  }
}
