import { Component, Input, OnInit, ViewChild } from "@angular/core"
import { fromEvent } from "rxjs"
import { DataExcludedInfoComponent } from "src/app/components/data-excluded-info/data-excluded-info.component"
import { AssetCategory } from "src/app/models/assetCategory"

@Component({
  selector: "c1-privacy-asset-categories-list",
  templateUrl: "./asset-categories-list.component.html",
  styleUrls: ["./asset-categories-list.component.scss"],
})
export class AssetCategoriesListComponent implements OnInit {
  @Input() assetCategories: AssetCategory[]
  @Input() hasData: boolean
  @ViewChild(DataExcludedInfoComponent, { static: false })
  private dataExcludedInfoComponent: DataExcludedInfoComponent

  title: string

  iconName: string
  iconSize = document.body.offsetWidth <= 600 ? "nano" : "micro"
  infoSize = document.body.offsetWidth <= 600 ? "quark" : "angstrem"
  constructor() {}

  ngOnInit() {
    this.iconName = this.hasData ? "hasdata" : "hasnodata"
    this.title = this.hasData ? "Your data includes:" : "Your data doesn't include:"
    const resize = fromEvent(window, "resize").subscribe((x) => {
      if (document.body.offsetWidth <= 600 && this.iconSize === "micro") {
        this.iconSize = "nano"
        this.infoSize = "quark"
      }

      if (document.body.offsetWidth > 600 && this.iconSize === "nano") {
        this.iconSize = "micro"
        this.infoSize = "angstrem"
      }
    })
  }

  infoOpen() {
    this.dataExcludedInfoComponent.open()
  }
}
