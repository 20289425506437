<main class="c1-privacy-multiple-sso grv-padding__bottom--xxlarge">
  <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent"></c1-privacy-subheader>
  <p class="subheading-text grv-text--medium grv-text--light margin-fix">
    {{ "MULTIPLE_SSO.SUBHEADING" | translate }}
  </p>
  <a
    class="grv-row grv-button--progressive sign-out-button"
    (click)="signOutButton()"
    data-cy="sign-out-button"
    tabindex="0"
    (keyup.enter)="signOutButton()"
    (keydown.space)="signOutButton()"
  >
    {{ "MULTIPLE_SSO.SIGN_OUT" | translate }}
  </a>
  <div class="grv-row grv-row--sm-center grv-text--normal grv-padding__top--small">
    <a
      class="grv-text__link--no-style c1-privacy-multiple-sso__main__anchor delete-link"
      [routerLink]="content.deleteLink"
      data-cy="delete-request-link"
    >
      {{ "MULTIPLE_SSO.REDIRECT_DELETE" | translate }}
    </a>
    <div class="grv-margin__top--small">
      <span>
        {{ "MULTIPLE_SSO.QUESTIONS" | translate }}
      </span>
      <a
        class="grv-text__link--no-style c1-privacy-multiple-sso__main__anchor phone-number-link"
        (click)="callCenterDialogOpen()"
        data-cy="phone-number-link"
        tabindex="0"
        (keyup.enter)="callCenterDialogOpen()"
      >
        {{ "MULTIPLE_SSO.QUESTIONS_LINK" | translate }}
      </a>
      <c1-privacy-call-center-dialog> </c1-privacy-call-center-dialog>
    </div>
  </div>
</main>
