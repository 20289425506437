<c1-privacy-dialog class="grv-dialog__center">
  <c1-privacy-subheader [subheaderContent]="dialogContent"> </c1-privacy-subheader>
  <p>
    {{ "CALL_CENTER_DIALOG.SUBTITLE" | translate }}
  </p>
  <div>
    <div>
      <strong>{{ "CALL_CENTER_DIALOG.CALL_NUMBER" | translate }}</strong>
    </div>
    {{ "CALL_CENTER_DIALOG.CALL_TIME" | translate }}
  </div>
</c1-privacy-dialog>
