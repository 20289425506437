<c1-privacy-banner
  class="maintenance-banner grv-margin--none"
  [alertType]="'GLOBAL'"
  [canClose]="false"
  [showMaintenance]="true"
  *ngIf="maintenance"
>
</c1-privacy-banner>
<main class="c1-privacy-snag grv-padding__bottom--xxlarge">
  <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent"></c1-privacy-subheader>
  <p class="grv-text--medium grv-text--light margin-fix">
    {{ "SNAG.INFO" | translate }}
  </p>
  <div class="grv-row">
    <a
      href="javascript:void(0)"
      class="grv-button--progressive try-again-button"
      (click)="tryAgainButton()"
      data-cy="try-again-button"
      tabindex="0"
      (keyup.enter)="tryAgainButton()"
      (keydown.space)="tryAgainButton()"
    >
      {{ "SNAG.TRY_AGAIN_BUTTON" | translate }}
    </a>
  </div>
  <div class="grv-row grv-row--sm-center grv-text--normal grv-padding__top--tiny">
    <span>
      {{ "SNAG.QUESTIONS" | translate }}
    </span>
    <span>
      <a
        href="javascript:void(0)"
        class="grv-text__link--no-style c1-privacy-create-request__main__anchor"
        (click)="callCenterDialogOpen()"
        data-cy="phone-number-link"
        tabindex="0"
        (keyup.enter)="callCenterDialogOpen()"
        (keydown.space)="callCenterDialogOpen()"
      >
        {{ "SNAG.QUESTIONS_LINK" | translate }}
      </a>
    </span>
    <c1-privacy-call-center-dialog> </c1-privacy-call-center-dialog>
  </div>
</main>
