import { Component, Inject, OnInit } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { filter } from "rxjs/operators"
import { AppConfig, APP_CONFIG } from "src/app.config"

import { RouteName } from "../../route-name"
import { AuthenticationService } from "../../services/auth/authentication.service"

@Component({
  selector: "c1-privacy-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  headerContent = {
    c1Logo: "assets/icons/capital_one_logo.svg",
    c1Logo_altText: "ACCESSIBILITY_LABELS.CAPITAL_ONE_IMAGE_ALT",
    showNav: false,
    navLabel: "End Session",
  }

  readonly baseUrl = "/"
  unauthenticatedHeaders = [
    this.baseUrl,
    this.baseUrl + RouteName.SignIn,
    this.baseUrl + RouteName.SessionExpired,
    this.baseUrl + RouteName.GovIdVerification,
    this.baseUrl + RouteName.TransparencyReport,
    this.baseUrl + RouteName.TransparencyReportSpanish
  ]

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.logoutLandingUrl = config.LogoutLandingUrl
  }
  logoutLandingUrl: string

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.headerContent.showNav = !this.unauthenticatedHeaders.some((path) => this.router.url === path)
    })
  }

  logout(keyboardEvent: KeyboardEvent): void {
    const spaceBarKeyCode = 32
    if (keyboardEvent.keyCode === spaceBarKeyCode) {
      keyboardEvent.preventDefault()
    }

    this.authenticationService.logout$.subscribe(
      () => {
        window.location.href = this.logoutLandingUrl
      },
      (err) => {
        console.error(err)
        window.location.href = this.logoutLandingUrl
      }
    )
  }
}
