<ng-template [ngIf]="!loadTransparencyHeader" [ngIfElse]="transparencyHeader">
  <c1-privacy-header id="privacy-header"></c1-privacy-header>
</ng-template>

<ng-template #transparencyHeader>
  <universal-header></universal-header>
</ng-template>
<section id="main-content" tabindex="-1">
  <router-outlet></router-outlet>
</section>

<c1-privacy-footer></c1-privacy-footer>

<div *ngIf="loading" class="spinner-container" aria-busy="true">
  <div class="grv-spinner grv-spinner--active"></div>
</div>

<c1-privacy-session-dialog></c1-privacy-session-dialog>
