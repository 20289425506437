import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { combineLatest, Observable, Subscription } from "rxjs"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { PortalHistoryService } from "src/app/services/portal-history/portal-history.service"
import { CategoryService } from "src/app/services/category/category.service"
import { SupportService } from "src/app/services/support/support.service"
import { PrivacyDataRequestStatus, EligibilityStatus, PrivacyDataRequestSummaryItem } from "src/app/models"
import { SupportType } from "src/app/models/common/SupportType"
import { RouteName } from "../../route-name"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"
import { ActivatedRoute, Router } from "@angular/router"
import { map } from "rxjs/internal/operators/map"
import {
  InEligibileDialogType,
  IneligibleRequestDialogComponent,
} from "src/app/components/ineligible-request-dialog/ineligible-request-dialog.component"
import { DOWNLOAD_TYPE } from "src/app/guards/eligibility.guard"
import { AuthenticationService, AuthType } from "src/app/services/auth/authentication.service"
import { DownloadReadyDataComponent } from "../download-ready-data/download-ready-data.component"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"

import('@cof/fs-pagination-wc/fs-pagination.js')

@Component({
  selector: "c1-privacy-portal-history",
  templateUrl: "./portal-history.component.html",
  styleUrls: ["./portal-history.component.scss"],
})
export class PortalHistoryComponent implements OnInit, OnDestroy {
  @ViewChild(IneligibleRequestDialogComponent) ineligibleDialog: IneligibleRequestDialogComponent
  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()

  subheaderContent: SubheaderContent = {
    icon: "goOnline",
    headerText: "PORTAL_OF_HISTORY.HEADER",
    subheaderText: "PORTAL_OF_HISTORY.SUBHEADING",
  }

  requestContent = {
    downloadIcon: "paper",
    correctionIcon: "edit",
    deleteIcon: "trash",
  }

  // TODO: implement eligibility API for "request another copy" text block
  shouldRequestAnotherCopy$: Observable<boolean> = this.privacyRequestService.postCorrectionDownloadEligibility$
  downloadRequests$: Observable<PrivacyDataRequestSummaryItem[]> = this.portalHistoryService.getDownloadHistory()
  correctionRequests$: Observable<PrivacyDataRequestSummaryItem[]> = this.portalHistoryService.getCorrectionHistory()
  deleteRequests$: Observable<PrivacyDataRequestSummaryItem[]> = this.portalHistoryService.getDeleteHistory()
  pendingRequest$: Observable<{
    download: PrivacyDataRequestSummaryItem[]
    correction: PrivacyDataRequestSummaryItem[]
    delete: PrivacyDataRequestSummaryItem[]
  }> = combineLatest([
    this.portalHistoryService.getDownloadPendingRequests(),
    this.portalHistoryService.getCorrectionPendingRequests(),
    this.portalHistoryService.getDeletePendingRequests(),
  ]).pipe(map(([dwn, crt, dlt]) => ({ download: dwn, correction: crt, delete: dlt })))

  queryParams$ = this.route.queryParams
  email$: Observable<string>
  downloadAvailable: boolean
  pendingRequestsSubscription: Subscription
  downloadEligibilitySubscription: Subscription
  deleteEligibilitySubscription: Subscription


  pageSize: number = 6

  downloadSublists: PrivacyDataRequestSummaryItem[][]
  correctionSublists: PrivacyDataRequestSummaryItem[][]
  deleteSublists: PrivacyDataRequestSummaryItem[][]

  indexMap = {
    downloads: 0,
    corrections: 0,
    deletes: 0
  }

  downloadFailures:number = 0
  showSubsequentError:boolean = false

  handleSubsequentLink(event)
  {
    this.showSubsequentError = false
  }

  handleDownloadEnd(event: DownloadReadyDataComponent){
    if(!event.downloadCompleted)
    {
      this.downloadFailures+=1;
      if (this.downloadFailures > 1)
      {
        this.showSubsequentError = true
      }
      if (this.downloadFailures == 2)
      {
        this.supportService.submitSupport$(SupportType.DOWNLOAD_FAILURE, event.request.privacyDataRequestId).subscribe()
      }
    }
    else
    {
      this.downloadFailures = 0;
    }
  }

  public get PrivacyDataRequestStatus() {
    return PrivacyDataRequestStatus
  }

  public get EligibilityStatus() {
    return EligibilityStatus
  }

  constructor(
    public categoryService: CategoryService,
    public privacyRequestService: PrivacyRequestService,
    public supportService: SupportService,
    private newRelicService: NewRelicService,
    public portalHistoryService: PortalHistoryService,
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthenticationService,
    public maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService
  ) {}

  ngOnInit(): void {
    this.newRelicService.logCustomError("Landed on manage hub page", {
      event: newRelicEvent.manage,
      logLevel: ErrorLoggingLevel.Info,
    })

    this.oneTagService.setPageView();

    this.downloadRequests$.subscribe(
      requests => this.downloadSublists = this.partitionRequestList(requests)
    )

    this.correctionRequests$.subscribe(
      requests => this.correctionSublists = this.partitionRequestList(requests)
    )

    this.deleteRequests$.subscribe(
      requests => this.deleteSublists = this.partitionRequestList(requests)
    )

    this.pendingRequestsSubscription = this.pendingRequest$.subscribe((requests) => {
      if (requests.download.length > 0) {
        this.email$ = this.privacyRequestService.getRequestEmail(requests.download[0].privacyDataRequestId)
        this.downloadAvailable =
          requests.download[0].requestStatus === PrivacyDataRequestStatus.FULFILLED ||
          requests.download[0].requestStatus === PrivacyDataRequestStatus.DATA_READY
      } else if (requests.delete.length > 0) {
        this.email$ = this.privacyRequestService.getRequestEmail(requests.delete[0].privacyDataRequestId)
      }
    })
  }

  navigateToDeleteRequest() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'manageNavigateToDeleteRequest');
    this.deleteEligibilitySubscription = this.privacyRequestService.deleteRequestStatus$.subscribe((eligibility) => {
      if (eligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST) {
        this.ineligibleDialog.type = InEligibileDialogType.DeleteInProgress
        this.ineligibleDialog.open()
      } else {
        this.newRelicService.logCustomError(`User navigated to challenge app to initiate delete request`, {
          event: newRelicEvent.deleteToStepUp,
          stateField: `${this.authService.getStateForAuthType(AuthType.DeleteStepUp)}`,
          logLevel: ErrorLoggingLevel.Info,
        })
        window.location.href = this.authService.authUrl(AuthType.DeleteStepUp)
      }
    })
  }

  navigateToCorrectionRequest() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'manageNavigateToCorrectionRequest');
    this.router.navigate([RouteName.CorrectionLanding])
  }

  navigateToCreateRequest(bypassAnnualLimit?: boolean) {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'manageNavigateToCreateRequest');
    this.downloadEligibilitySubscription = this.privacyRequestService.downloadRequestStatus$.subscribe(
      (eligibility) => {
        if (bypassAnnualLimit && eligibility.eligibilityStatus !== EligibilityStatus.INELIGIBLE_PENDING_REQUEST) {
          this.router.navigate([RouteName.CreateRequest], { queryParams: { q: DOWNLOAD_TYPE.POST_VALIDATION } })
        } else if (
          eligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_REQUEST ||
          eligibility.eligibilityStatus === EligibilityStatus.INELIGIBLE_PENDING_DOWNLOAD_REQUEST
        ) {
          if (this.downloadAvailable) {
            this.ineligibleDialog.type = InEligibileDialogType.DownloadAvailable
          } else {
            this.ineligibleDialog.type = InEligibileDialogType.DownloadInProgress
          }
          this.ineligibleDialog.open()
        } else {
          this.router.navigate([RouteName.CreateRequest])
        }
      }
    )
  }

  partitionRequestList(requestList: PrivacyDataRequestSummaryItem[]): PrivacyDataRequestSummaryItem[][] {
    const pageCount = Math.ceil(requestList.length / 6)
    const sublist: PrivacyDataRequestSummaryItem[][] = new Array(pageCount)

    let i = 0
    for (let j = 0; j < pageCount - 1; j++) {
      sublist[j] = requestList.slice(i, i + this.pageSize)
      i += this.pageSize
    }

    sublist[pageCount - 1] = requestList.slice(i, requestList.length)

    return sublist
  }

  paginate(e: CustomEvent<number>, type: String) {
    switch(type) {
      case 'downloads':
        this.indexMap.downloads = e.detail - 1
        break;

      case 'corrections':
        this.indexMap.corrections = e.detail - 1
        break;

      case 'deletes':
        this.indexMap.deletes = e.detail - 1
    }
  }

  ngOnDestroy() {
    if (this.pendingRequestsSubscription) {
      this.pendingRequestsSubscription.unsubscribe()
    }

    if (this.downloadEligibilitySubscription) {
      this.downloadEligibilitySubscription.unsubscribe()
    }

    if (this.deleteEligibilitySubscription) {
      this.deleteEligibilitySubscription.unsubscribe()
    }
  }

  onRemove() {
    console.log("removing")
  }
}
