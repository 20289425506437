import { Directive, Input, ElementRef, Renderer2, OnChanges } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { HTMLConfig, ElementConfig } from "../interfaces/htmlConfig.interface"
import { take } from "rxjs/operators"

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[c1PrivacyTranslateWithHtml]",
})
export class TranslateWithHtmlDirective implements OnChanges {
  private captureReg = /{{html_([\w_]+)}}/g
  private nonCaptureReg = /{{html_[\w_]+}}/g
  private matchedKeys = []

  @Input() translateKey: string
  @Input() translateParams
  @Input() htmlConfig: HTMLConfig

  constructor(private el: ElementRef, private renderer: Renderer2, private translate: TranslateService) {}

  ngOnChanges(): void {
    this.translate
      .get(this.translateKey, this.translateParams)
      .pipe(take(1))
      .subscribe((value) => {
        let match
        do {
          match = this.captureReg.exec(value)
          if (match != null) {
            this.matchedKeys.push(match[1])
          }
        } while (match)

        const mainElement = this.renderer.createElement("span")

        value.split(this.nonCaptureReg).forEach((text, index) => {
          const textElement = this.renderer.createText(text)
          this.renderer.appendChild(mainElement, textElement)

          if (this.matchedKeys[index]) {
            const injectedHtml = this.createHtmlElement(this.htmlConfig[this.matchedKeys[index]])
            this.renderer.appendChild(mainElement, injectedHtml)
          }
        })

        this.renderer.appendChild(this.el.nativeElement, mainElement)
      })
  }

  private createHtmlElement(elementConfig: ElementConfig) {
    const tag = this.renderer.createElement(elementConfig.tag)

    this.translate
      .get(elementConfig.textKey)
      .pipe(take(1))
      .subscribe((value) => {
        const elementText = this.renderer.createText(value)
        this.renderer.appendChild(tag, elementText)
      })

    if (elementConfig.classes) {
      elementConfig.classes.split(" ").forEach((styleClass) => this.renderer.addClass(tag, styleClass))
    }
    if (elementConfig.attributes) {
      elementConfig.attributes.forEach((attr) => {
        this.renderer.setAttribute(tag, attr.key, attr.value)
      })
    }
    return tag
  }
}
