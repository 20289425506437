import { InjectionToken } from "@angular/core"

export class AppConfig {
  production: boolean
  apiURL: string
  gatewayOauthSignIn: string
  LogoutLandingUrl: string
  SessionExpiredUrl: string
  capitalOneUrl: string
  oauthClientID: string
  idleTimer: number
  refreshTimer: number
  sessionDialogTimer: number
  redirectUri: string
  cookieDomain: string
  customerOauthSignIn: string
  otherProductsOauthSignIn: string
  maintenanceType: string
  maintenanceStart: number
  maintenanceDuration: number
  maintenancePreviewDuration: number
}

export const APP_CONFIG = new InjectionToken<AppConfig>("APP_CONFIG")
