<div class="grv-card">
  <div class="grv-card__content">
    <div class="form-header">
      <span class="grv-text--medium-1 grv-weight--semibold"> {{ "INTAKE_FORM.CARD_HEADER" | translate }}</span>
      <span id="remove-form" *ngIf="showRemoveButton">
        <c1-privacy-icon [iconName]="'trash_blue'" [size]="'quark'"> </c1-privacy-icon>
        <a id="remove-button" role="button" tabindex="0" (click)="removeForm()">
          {{ "INTAKE_FORM.REMOVE_BUTTON" | translate }}
        </a>
      </span>
    </div>
    <div>
      <form [formGroup]="intakeFormGroup">
        <div formGroupName="intakeGroup">
          <div class="grv-form-field">
            <div class="grv-label__header">
              <label for="incorrectValueInput" class="grv-label grv-label--textfield">{{
                "INTAKE_FORM.FILE_NAME_LABEL" | translate
              }}</label>
              <span class="grv-label__info" id="textarea1a-chars"> {{ getInputLength("fileName") }}/100 </span>
            </div>
            <input
              type="text"
              id="fileNameInput"
              formControlName="fileName"
              class="grv-textfield__input"
              [ngClass]="{ 'grv-textfield__input--error': showFileNameError() }"
              aria-label="file name"
            />
            <span class="grv-form-field__helper grv-form-field__helper--error" *ngIf="showFileNameError()">{{
              "INTAKE_FORM.REQUIRED_ERROR_LABEL" | translate
            }}</span>
            <span class="grv-form-field__helper">{{ "INTAKE_FORM.FILE_NAME_EXAMPLE" | translate }}</span>
          </div>

          <div class="grv-form-field">
            <div class="grv-label__header">
              <label for="incorrectValueInput" class="grv-label grv-label--textfield">{{
                "INTAKE_FORM.FIELD_LABEL" | translate
              }}</label>
              <span class="grv-label__info" id="textarea1a-chars"> {{ getInputLength("field") }}/100 </span>
            </div>
            <input
              type="text"
              id="fieldInput"
              formControlName="field"
              class="grv-textfield__input"
              [ngClass]="{ 'grv-textfield__input--error': showFieldError() }"
              aria-label="field or column name"
            />
            <span class="grv-form-field__helper grv-form-field__helper--error" *ngIf="showFieldError()">{{
              "INTAKE_FORM.REQUIRED_ERROR_LABEL" | translate
            }}</span>
          </div>

          <div class="correct-value-inputs">
            <div class="grv-form-field">
              <div class="grv-label__header">
                <label for="incorrectValueInput" class="grv-label grv-label--textfield">{{
                  "INTAKE_FORM.INCORRECT_VALUE_LABEL" | translate
                }}</label>
                <span class="grv-label__info" id="textarea1a-chars"> {{ getInputLength("incorrectValue") }}/100 </span>
              </div>
              <input
                type="text"
                id="incorrectValueInput"
                formControlName="incorrectValue"
                class="grv-textfield__input"
                [ngClass]="{ 'grv-textfield__input--error': showIncorrectValueError() }"
              />
              <span class="grv-form-field__helper grv-form-field__helper--error" *ngIf="showIncorrectValueError()">{{
                "INTAKE_FORM.REQUIRED_ERROR_LABEL" | translate
              }}</span>
            </div>

            <c1-privacy-icon class="" [iconName]="'arrowRight'" [size]="'angstrem'"> </c1-privacy-icon>

            <div class="grv-form-field force-bottom-margin-24">
              <div class="grv-label__header">
                <label for="correctValueInput" class="grv-label grv-label--textfield">{{
                  "INTAKE_FORM.CORRECT_VALUE_LABEL" | translate
                }}</label>
                <span class="grv-label__info" id="textarea1a-chars"> {{ getInputLength("correctValue") }}/100 </span>
              </div>
              <input
                type="text"
                id="correctValueInput"
                formControlName="correctValue"
                class="grv-textfield__input"
                [ngClass]="{ 'grv-textfield__input--error': showCorrectValueError() }"
              />
              <span class="grv-form-field__helper grv-form-field__helper--error" *ngIf="showCorrectValueError()">{{
                "INTAKE_FORM.REQUIRED_ERROR_LABEL" | translate
              }}</span>
            </div>
          </div>

          <div class="grv-form-field">
            <div class="grv-label__header">
              <label for="explanationInput" class="grv-label grv-label--textfield">{{
                "INTAKE_FORM.EXPLANATION_LABEL" | translate
              }}</label>
              <span class="grv-label__info" id="textarea1a-chars"> {{ getInputLength("explanation") }}/250 </span>
            </div>
            <textarea
              type="text"
              id="explanationInput"
              formControlName="explanation"
              class="grv-textfield__textarea"
              [ngClass]="{ 'grv-textfield__textarea--error': showExplanationError() }"
            ></textarea>
            <span class="grv-form-field__helper grv-form-field__helper--error" *ngIf="showExplanationError()">{{
              "INTAKE_FORM.REQUIRED_ERROR_LABEL" | translate
            }}</span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
