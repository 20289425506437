import { Component, ViewChild } from "@angular/core"
import { Router } from "@angular/router"
import { AuthenticationService } from "../../services/auth/authentication.service"
import { SubheaderContent } from "../../components/subheader/SubheaderContent"
import { CallCenterDialogComponent } from "../../components/call-center-dialog/call-center-dialog.component"
import { RouteName } from "../../route-name"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { OneTagService } from "src/app/services/one-tag/one-tag.service"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"

@Component({
  selector: "c1-privacy-snag",
  templateUrl: "./snag.component.html",
  styleUrls: ["./snag.component.scss"],
})
export class SnagComponent {
  subheaderContent: SubheaderContent = {
    icon: "error",
    headerText: "SNAG.HEADER",
  }

  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()

  @ViewChild(CallCenterDialogComponent)
  callCenterDialog: CallCenterDialogComponent

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    private newRelicService: NewRelicService,
    public maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService
  ) {
    if (document.referrer.includes(".capitalone.com")) {
      this.newRelicService.logCustomError("User was shown the snag page.", {
        event: newRelicEvent.snag,
        logLevel: ErrorLoggingLevel.Error,
      })
    }
  }

  ngOnInit() {
    this.oneTagService.setPageView();
  }

  callCenterDialogOpen() {
    this.callCenterDialog.open()
  }

  tryAgainButton() {
    this.authenticationService.logout$.subscribe(() => {
      this.router.navigate([`/${RouteName.FrontDoor}`])
    })
  }
}
