<main class="c1-privacy__main">
  <div class="c1-privacy__main__subheader">
    <c1-privacy-subheader [subheaderContent]="subheaderContent">
      <ng-content select="[subheader-action]" subheader-action></ng-content>
    </c1-privacy-subheader>
  </div>

  <section class="c1-privacy__main__content grv-padding__top--medium">
    <!-- Guidence Bar View -->
    <div class="grv-grid">
      <div class="grv-row grv-row--sm-center">
        <div class="grv-card cp1-privacy__max-width">
          <ng-content select="[alert_content]"></ng-content>
        </div>
      </div>
    </div>

    <!-- One Or more centered cards View -->
    <div class="grv-grid" [class.card-space]="!isLast" *ngFor="let cardContent of cardRefs; last as isLast">
      <div class="grv-row grv-row--sm-center">
        <div class="grv-card cp1-privacy__max-width">
          <div class="grv-card__content">
            <ng-container *ngTemplateOutlet="cardContent"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Any additional centered content that isn't wrapped in card -->
    <div class="grv-grid">
      <div class="grv-row grv-row--sm-center">
        <div class="cp1-privacy__max-width">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </section>
</main>
