import { Directive, Input, ElementRef, Renderer2, OnInit } from "@angular/core"

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[c1PrivacyMaskCardNumber]",
})
export class MaskCardNumberDirective implements OnInit {
  private reg = /(?:^|\s)(\d{4}|\d{16})(?:$|\s)/g
  private _content: string
  private maskedMatches = []
  private DELIMITER = "#{MASKED_NUM}"

  @Input() maskClass = "card-account-number__text"

  @Input() set content(value: string) {
    if (value && value.match(this.reg)) {
      this._content = value.replace(this.reg, (group1, card) => {
        this.maskedMatches.push(` ...${card.substring(card.length - 4, card.length)} `)
        return this.DELIMITER
      })
    } else {
      this._content = value
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const mainText = this.renderer.createElement("span")

    this._content.split(this.DELIMITER).forEach((text, index) => {
      const trimmedText = this.renderer.createText(text.trim())
      this.renderer.appendChild(mainText, trimmedText)
      if (this.maskedMatches[index]) {
        const numberWrapper = this.renderer.createElement("span")
        this.renderer.addClass(numberWrapper, this.maskClass)
        const maskedNum = this.renderer.createText(this.maskedMatches[index])
        this.renderer.appendChild(numberWrapper, maskedNum)
        this.renderer.appendChild(mainText, numberWrapper)
      }
    })

    this.renderer.appendChild(this.el.nativeElement, mainText)
  }
}
