import { Component, ViewChild, OnInit } from "@angular/core"
import { ActivatedRoute, ParamMap, Router } from "@angular/router"
import { map } from "rxjs/operators"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { RouteName } from "src/app/route-name"
import { CallCenterDialogComponent } from "src/app/components/call-center-dialog/call-center-dialog.component"
import { Subscription } from "rxjs"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { newRelicEvent, QUERY_PARAMS } from "src/app/common"

type ErrorType = "GOV_ID" | "MNO"

@Component({
  selector: "c1-privacy-verification-error",
  templateUrl: "./verification-error.component.html",
  styleUrls: ["./verification-error.component.scss"],
})
export class VerificationErrorComponent implements OnInit {
  @ViewChild(CallCenterDialogComponent)
  callCenterDialog: CallCenterDialogComponent
  errorTypeSubscription: Subscription
  definedError: ErrorType

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newRelicService: NewRelicService
  ) {}

  errorType$ = this.activatedRoute.queryParamMap.pipe(
    map((params: ParamMap) => {
      const param = params.get("query")
      this.definedError = param && param.toLowerCase() === QUERY_PARAMS.GOV_ID_FAILURE ? "GOV_ID" : "MNO"

      const baseText = "VERIFICATION_ERROR."
      const tryText = ".TRY_TEXT"
      const reasonsText = ".REASONS_TEXT"

      this.content.reasonsText = baseText + this.definedError + reasonsText
      this.content.tryText = baseText + this.definedError + tryText

      // Update NR logging dependent on error type
      if (document.referrer.includes(".capitalone.com")) {
        let message: string
        let errorEvent: string
        if (this.definedError === "GOV_ID") {
          message = "User was redirected to GovID Snag page after failing GovID verification."
          errorEvent = newRelicEvent.govIdSnag
        } else {
          message = "User was redirected to Step-Up Snag page after failing to step-up."
          errorEvent = newRelicEvent.mnoSnag
        }
        this.newRelicService.logCustomError(message, {
          event: errorEvent,
          logLevel: ErrorLoggingLevel.Error,
        })
      }

      return true
    })
  )

  subheaderContent: SubheaderContent = {
    icon: "caution",
    headerText: "VERIFICATION_ERROR.HEADER",
    subheaderText: "VERIFICATION_ERROR.SUBHEADING",
  }

  content = {
    preTryText: "VERIFICATION_ERROR.PRE_TRY_TEXT",
    postTryText: "VERIFICATION_ERROR.POST_TRY_TEXT",
    tryText: "VERIFICATION_ERROR.MNO.RETURN_TEXT",
    reasonsText: "VERIFICATION_ERROR.MNO.REASONS_TEXT",
    questions: "VERIFICATION_ERROR.QUESTIONS",
    questionsLink: "VERIFICATION_ERROR.QUESTIONS_LINK",
    dataCategory: "VERIFICATION_ERROR.DATA_CATEGORY",
    mnoReturnToManage: "VERIFICATION_ERROR.MNO.RETURN_TO_MANAGE_TEXT",
  }

  ngOnInit() {}

  navigateToDataCategories() {
    this.router.navigate([`../${RouteName.DataCategories}`])
  }

  navigateToGovId() {
    this.router.navigate([`../${RouteName.GovIdVerification}`])
  }

  callCenterDialogOpen() {
    this.callCenterDialog.open()
  }

  navigateToManage() {
    this.newRelicService.logCustomError("Navigating user to manage portal after step-up failure", {
      event: newRelicEvent.mnoDownload,
      logLevel: ErrorLoggingLevel.Info,
    })
    this.router.navigate([`../${RouteName.Manage}`], { queryParams: { query: QUERY_PARAMS.MNO_FAILURE } })
  }
}
