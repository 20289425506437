import { retryWhen, mergeMap, delay } from "rxjs/operators"
import { Observable, throwError, of } from "rxjs"

const statusCodeCheckList = [429]
const DEFAULT_WAIT = 1000
const DEFAULT_ATTEMPTS = 2
const DEFAULT_INCREMENT = 1000

export const messageCreator = {
  noStatusCode: (error) =>
    `Invalid Status Code - no retry request attempted. Error - ${JSON.stringify(error)} - timestamp: `,
  retryAttemptExceeded: (actualAttempt, maxAttempts, initialWait, backoffIncrement, error) =>
    `Max Retry Attempts Reached - retried ${actualAttempt} out of ${maxAttempts} times but failed.
    initialWait: ${initialWait}, backoffIncrement: ${backoffIncrement} - Error - ${JSON.stringify(
      error
    )} - timestamp: `,
  retryingMessage: (actualAttempt, maxAttempts, error) =>
    `Retrying request ${actualAttempt} out of ${maxAttempts} times due to Error - ${JSON.stringify(
      error
    )} - timestamp: `,
}

export const retryWithBackoff = (
  loggingCb: (p: any) => void,
  initialWait = DEFAULT_WAIT,
  maxAttempts = DEFAULT_ATTEMPTS,
  backoffIncrement = DEFAULT_INCREMENT
) => {
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          mergeMap((error, attempt) => {
            const timeStamp = Date.now()
            const actualAttempt = attempt + 1

            const retryAttemptExceeded = actualAttempt > maxAttempts
            const noStatusCode = statusCodeCheckList.find((code) => code !== error.status)

            let response
            let errorMessage

            if (noStatusCode) {
              errorMessage = messageCreator.noStatusCode(error) + timeStamp

              response = throwError(error)
            } else if (retryAttemptExceeded) {
              errorMessage =
                messageCreator.retryAttemptExceeded(attempt, maxAttempts, initialWait, backoffIncrement, error) +
                timeStamp

              response = throwError(error)
            } else {
              const backoffTime = initialWait + attempt * backoffIncrement

              errorMessage = messageCreator.retryingMessage(actualAttempt, maxAttempts, error) + timeStamp

              response = of(error).pipe(delay(backoffTime))
            }
            loggingCb(errorMessage)
            return response
          })
        )
      )
    )
}
