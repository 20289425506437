import { BrowserModule, Title } from "@angular/platform-browser"
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core"
import { TranslateModule, TranslateLoader } from "@ngx-translate/core"
import { TranslateHttpLoader } from "@ngx-translate/http-loader"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { HttpClientModule, HttpClient } from "@angular/common/http"
import { RouterModule } from "@angular/router"
import { CookieService } from "ngx-cookie-service"
import { HeaderComponent } from "./components/header/header.component"
import { FooterComponent } from "./components/footer/footer.component"
import { NewRelicService } from "./services/new-relic/new-relic.service"
import { SignInOptionsComponent } from "./features/sign-in-options/sign-in-options.component"
import { GovIdVerificationComponent } from "./features/sign-in-options/gov-id-verification/gov-id-verification.component"
import { FrontDoorComponent } from "./features/front-door/front-door.component"
import { SnagComponent } from "./features/snag/snag.component"
import { MultipleSsoComponent } from "./features/multiple-sso/multiple-sso.component"
import { SessionExpiredComponent } from "./features/session-expired/session-expired.component"
import { PageNotFoundComponent } from "./features/page-not-found/page-not-found.component"
import { UnavailableComponent } from "./features/unavailable/unavailable.component"
import { FlagshipPrivacyComponent } from "./features/flagship-privacy/flagship-privacy.component"
import { SessionDialogComponent } from "./components/session-dialog/session-dialog.component"
import { DataCategoriesComponent } from "./features/data-categories/data-categories.component"
import { InlineSVGModule } from "ng-inline-svg-2"
import { VerificationErrorComponent } from "./features/verification-error/verification-error.component"
import { SharedModule } from "./shared/shared.module"
import { CoverageTableComponent } from "./components/coverage-table/coverage-table.component"
import { TransparencyReportComponent } from "./features/transparency-report/transparency-report/transparency-report.component"
import { TitleBannerComponent } from "./components/title-banner/title-banner.component"
import { NgApexchartsModule } from "ng-apexcharts"
import { TransparencyReportSpanishComponent } from "./features/transparency-report-spanish/transparency-report-spanish.component"
import { httpInterceptorProviders } from "./utils/interceptors"
import { InProgressComponent } from "./components/in-progress/in-progress.component"
import { PortalHistoryComponent } from "./features/portal-history/portal-history.component"
import { DownloadReadyDataComponent } from "./features/download-ready-data/download-ready-data.component"
import { BannerComponent } from "./components/banner/banner.component"
import { AssetCategoriesComponent } from "./features/create-request/asset-categories/asset-categories.component"
import { IntakeFormPageComponent } from "./features/intake-form-page/intake-form-page.component"
import { AssetCategoriesListComponent } from "./features/create-request/asset-categories-list/asset-categories-list.component"
import { CorrectionLandingComponent } from "./features/correction-landing/correction-landing.component"
import { IntakeFormConfirmationPageComponent } from "./features/intake-form-page/intake-form-confirmation-page/intake-form-confirmation-page.component"

export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http)

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FlagshipPrivacyComponent,
    FrontDoorComponent,
    GovIdVerificationComponent,
    PageNotFoundComponent,
    UnavailableComponent,
    SignInOptionsComponent,
    SnagComponent,
    SessionExpiredComponent,
    MultipleSsoComponent,
    SessionDialogComponent,
    DataCategoriesComponent,
    VerificationErrorComponent,
    CoverageTableComponent,
    TransparencyReportComponent,
    TitleBannerComponent,
    TransparencyReportSpanishComponent,
    InProgressComponent,
    PortalHistoryComponent,
    DownloadReadyDataComponent,
    BannerComponent,
    AssetCategoriesComponent,
    AssetCategoriesListComponent,
    IntakeFormPageComponent,
    CorrectionLandingComponent,
    IntakeFormConfirmationPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgApexchartsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    InlineSVGModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [CookieService, Title, httpInterceptorProviders, { provide: ErrorHandler, useClass: NewRelicService }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
