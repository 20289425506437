import { Component, ViewChild, OnDestroy, OnInit, Input, Output, EventEmitter } from "@angular/core"
import { map } from "rxjs/operators"
import { combineLatest, Observable, Subscription } from "rxjs"
import { saveAs } from "file-saver"
import { CallCenterDialogComponent } from "../../components/call-center-dialog/call-center-dialog.component"
import { CategoryService } from "src/app/services/category/category.service"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { AuthenticationService, AuthType } from "src/app/services/auth/authentication.service"
import { Category } from "src/app/models"
import { DownloadService } from "src/app/services/download/download.service"
import { HttpEventType } from "@angular/common/http"
import { RouteName } from "src/app/route-name"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { NextAvailableRequestService } from "src/app/services/next-available-request/next-available-request.service"
import { newRelicEvent, QUERY_PARAMS } from "src/app/common"
import { AssetCategory } from "src/app/models/assetCategory"
import { fromEvent } from "rxjs/internal/observable/fromEvent"
import { PrivacyDataRequestStatus, PrivacyDataRequestSummaryItem } from "src/app/models/PrivacyRequest"
import { RequestType } from "src/app/models/common"
import { Status } from "src/app/components/connex-skeleton/connex-skeleton.component"

export enum downloadStatus {
  VERIFY = "VERIFY",
  DOWNLOADING = "DOWNLOADING",
  DOWNLOAD = "DOWNLOAD",
  UNVERIFIED = "UNVERIFIED",
  DOWNLOAD_AGAIN = "DOWNLOAD_AGAIN",
}
interface ButtonContent {
  text: string
  style: string
  disabled: boolean
  click: () => void
}

@Component({
  selector: "c1-privacy-download-ready-data",
  templateUrl: "./download-ready-data.component.html",
  styleUrls: ["./download-ready-data.component.scss"],
})
export class DownloadReadyDataComponent implements OnInit, OnDestroy {
  @ViewChild(CallCenterDialogComponent)
  private callCenterDialog: CallCenterDialogComponent
  private downloadSubscription: Subscription

  @Input() request: PrivacyDataRequestSummaryItem
  @Input() type: RequestType
  @Input() queryParams: any = {}
  @Input() divider: boolean = false
  @Output() downloadEndEvent = new EventEmitter<DownloadReadyDataComponent>();

  reportDownloadEnd(component: DownloadReadyDataComponent) {
    this.downloadEndEvent.emit(this);
  }

  public assetCategories$: Observable<AssetCategory[]>

  requestId: string
  selectedCategories: Category[]
  nullState$: any

  //Used for old requests where there may not be any assset data categories returned
  noAssetDataCategories$: any

  currentStatus: string = downloadStatus.VERIFY
  downloadCompleted: boolean = false
  downloadDataText = "DOWNLOAD_DATA.DOWNLOAD_BUTTON"
  deleteLink = `/${RouteName.DeleteRequest}`

  subheaderContent = {
    icon: "document",
    headerText: "DOWNLOAD_READY_DATA.HEADER",
  }

  checkmarkSize = document.body.offsetWidth <= 600 ? "angstrem" : "micro"

  buttonContent: { [key: string]: ButtonContent } = {
    VERIFY: {
      text: this.downloadDataText + "VERIFY_BUTTON",
      style: "grv-col--sm-3 grv-col--md-5 grv-col--lg-6 grv-button grv-button--progressive",
      click: () => {
        this.newRelicService.logCustomError(`User navigated to challenge app from /download`, {
          event: newRelicEvent.downloadToStepUp,
          stateField: `${this.authService.getStateForAuthType(AuthType.DownloadStepUp)}`,
          logLevel: ErrorLoggingLevel.Info,
        })
        window.location.href = this.authService.authUrl(AuthType.DownloadStepUp)
      },
      disabled: false,
    },
    DOWNLOAD: {
      text: this.downloadDataText + "DOWNLOAD",
      style: "grv-col--sm-3 grv-col--md-5 grv-col--lg-6 grv-button grv-button--action",
      click: () => {
        this.downloadAsset()
      },
      disabled: false,
    },
    DOWNLOAD_AGAIN: {
      text: this.downloadDataText + "DOWNLOAD",
      style: "grv-col--sm-3 grv-col--md-5 grv-col--lg-6 grv-button grv-button--action",
      click: () => {
        this.downloadAsset()
      },
      disabled: false,
    },
    UNVERIFIED: {
      text: "",
      style: "unverified-button",
      click: () => {},
      disabled: true,
    },
    VERIFYDOWNLOAD: {
      text: "Verify & Download",
      style: "grv-col--sm-3 grv-col--md-5 grv-col--lg-6 grv-button grv-button--action verify-download-button",
      click: () => {
        this.downloadAsset()
      },
      disabled: false,
    },
  }

  downloadPageData$: any
  hasEmailFailed$: Observable<{ failed: boolean; date: string }>
  email$: Observable<string>

  public get downloadStatus() {
    return downloadStatus
  }

  public get ConnexStatus() {
    return Status
  }

  public get PrivacyDataRequestStatus() {
    return PrivacyDataRequestStatus
  }

  constructor(
    public categoryService: CategoryService,
    public authService: AuthenticationService,
    public privacyRequestService: PrivacyRequestService,
    public downloadService: DownloadService,
    public nextAvailableRequestService: NextAvailableRequestService,
    private newRelicService: NewRelicService
  ) {}



  downloadAsset() {
    this.downloadCompleted = false
    const msg = "Initiating download"

    this.newRelicService.logCustomError(msg, {
      event: newRelicEvent.downloadingRequest,
      logLevel: ErrorLoggingLevel.Info,
    })
    this.newRelicService.logInteraction(msg)

    this.currentStatus = downloadStatus.DOWNLOADING


    this.downloadSubscription = this.downloadService
      .downloadAsset(this.request.privacyDataRequestId)
      .subscribe((data) => {
        const { type, body } = data
        const fileName = data.headers ? data.headers.get("Content-Disposition").split("filename=")[1] : "download.zip"
        if (type < HttpEventType.Response) {
          this.currentStatus = downloadStatus.DOWNLOADING
        } else if (type === HttpEventType.Response) {
          if (body && body.size > 0 && body.type === "application/zip") {
            const blob = new Blob([body], { type: "application/zip" })
            saveAs(blob, fileName)
            this.newRelicService.logCustomError(`User downloaded asset ${fileName}`, {
              event: newRelicEvent.downloadingComplete,
              logLevel: ErrorLoggingLevel.Info,
            })
            this.downloadCompleted = true
            this.currentStatus = downloadStatus.DOWNLOAD_AGAIN
          }
        }
      }).add(()=> {
        //Called when operation is complete
        if(!this.downloadCompleted)
        {
          this.currentStatus = downloadStatus.DOWNLOAD
        }
        this.reportDownloadEnd(this)
      });
    }


  ngOnInit(): void {
    this.hasEmailFailed$ = this.privacyRequestService.hasEmailFailed(this.request.privacyDataRequestId)
    this.email$ = this.privacyRequestService.getRequestEmail(this.request.privacyDataRequestId)

    if (this.queryParams.q === QUERY_PARAMS.STEP_UP_SUCCESS) {
      this.currentStatus = downloadStatus.DOWNLOAD
    }

    this.newRelicService.logCustomError("User on download page", {
      event: newRelicEvent.download,
      logLevel: ErrorLoggingLevel.Info,
    })

    const resize = fromEvent(window, "resize").subscribe((x) => {
      if (document.body.offsetWidth <= 600 && this.checkmarkSize === "micro") {
        this.checkmarkSize = "angstrem"
      }

      if (document.body.offsetWidth > 600 && this.checkmarkSize === "angstrem") {
        this.checkmarkSize = "micro"
      }
    })
  }

  onExpanded() {
    this.downloadPageData$ = combineLatest(
      this.privacyRequestService.getDownloadCategories(this.request.privacyDataRequestId),
      this.privacyRequestService.getAssets(this.request.privacyDataRequestId)
    ).pipe(
      map(([selectedCategories, assets]) => {
        return { selectedCategories, assets }
      })
    )

    this.assetCategories$ = this.privacyRequestService.getAssetDataCategories(this.request.privacyDataRequestId)

    this.nullState$ = combineLatest([
      this.privacyRequestService.getDownloadCategories(this.request.privacyDataRequestId),
      this.assetCategories$,
    ]).pipe(
      map(([categories, assetsDataCategories]) => {
        return (
          assetsDataCategories.filter((asset) => asset.hasData).length === 0 &&
          categories.filter((category) => category.categoryId == "ACCOUNT_DATA").length === 0
        )
      })
    )

    this.noAssetDataCategories$ = this.assetCategories$.pipe(
      map((res) => {
        return res.length === 0
      })
    )
  }

  onDownloadButtonClicked() {
    this.buttonContent[this.currentStatus].click()
  }

  callCenterDialogOpen() {
    this.callCenterDialog.open()
  }

  ngOnDestroy(): void {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe()
    }
  }
}
