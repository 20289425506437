import { Component, OnInit} from "@angular/core"
import { Observable, fromEvent, Subscription } from "rxjs"
import { ccpaDisclosure, newRelicEvent } from "src/app/common"
import { HTMLConfig } from "src/app/interfaces/htmlConfig.interface"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"

import { TranslateService } from "@ngx-translate/core"
import { Renderer2 } from "@angular/core"
import { OneTagService } from "src/app/services/one-tag/one-tag.service"


@Component({
  selector: "c1-privacy-transparency-report",
  templateUrl: "./transparency-report.component.html",
  styleUrls: ["./transparency-report.component.scss"],
})
export class TransparencyReportComponent implements OnInit {
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  //For positioning gray shaded area on both charts




  htmlConfig: HTMLConfig = {
    ccpa_disclosure: {
      tag: "a",
      classes: "grv-text--normal-normal small-text-on-mobile grv-color--core-blue-40 no-underline",
      attributes: [
        {
          key: "href",
          value: ccpaDisclosure,
        },
        {
          key: "data-cy",
          value: "ccpa-disclosure-link",
        },
      ],
      textKey: "TRANSPARENCY_REPORT.CCPA_LINK_TEXT",
    },
  }

  content: { [key: string]: string } = {
    title: "TRANSPARENCY_REPORT.HEADER",
    subtitle: "TRANSPARENCY_REPORT.SUBHEADING",
    description: "TRANSPARENCY_REPORT.PDF",
    link: "https://ecm.capitalone.com/WCM/privacy/transparency-report-for-requests-from-2023.pdf"
  }

  coverageTableEn: [string[], string[], string[]] = [
    [
      "COVERAGE_TABLE.COVERAGE.RESIDENCY",
      "COVERAGE_TABLE.TOTAL_RECEIVED",
      "COVERAGE_TABLE.REQUESTS_DATA_PROVIDED",
      "COVERAGE_TABLE.REQUESTS_NO_DATA_PROVIDED"
    ],
    [
      "COVERAGE_TABLE.COVERAGE.CALIFORNIA",
      "COVERAGE_TABLE.DOWNLOAD_TURNAROUND.CALIFORNIA",
      "COVERAGE_TABLE.DELETE_TURNAROUND.CALIFORNIA",
      "COVERAGE_TABLE.DELETE_TURNAROUND.CALIFORNIA",
    ],
    [
      "COVERAGE_TABLE.COVERAGE.ALL_OTHER_STATES",
      "COVERAGE_TABLE.DOWNLOAD_TURNAROUND.ALL_OTHER_STATES",
      "COVERAGE_TABLE.DELETE_TURNAROUND.ALL_OTHER_STATES",
      "COVERAGE_TABLE.DELETE_TURNAROUND.CALIFORNIA",
    ],
  ]

  coverageDescription: string[] = [
    "COVERAGE_TABLE.DESCRIPTION1",
    "COVERAGE_TABLE.DESCRIPTION2",
    "COVERAGE_TABLE.DESCRIPTION3",
  ]

  constructor(
    public ts: TranslateService,
    private renderer: Renderer2,
    private newRelicService: NewRelicService,
    private oneTagService: OneTagService)
  {

    this.newRelicService.logCustomError("User visited transparency report page.", {
      event: newRelicEvent.transparencyReport,
      logLevel: ErrorLoggingLevel.Info,
    })
  }

  ngOnInit(): void {
    this.oneTagService.setPageView();
  }
}
