<c1-privacy-dialog>
  <c1-privacy-subheader [subheaderContent]="subheaderContent"> </c1-privacy-subheader>
  <form [formGroup]="emailFormGroup">
    <div formGroupName="emailGroup">
      <div class="grv-form-field">
        <label for="emailInput" class="grv-textfield__label" [innerHTML]="content.firstEmailLabel | translate"></label>
        <input
          id="emailInput"
          name="emailInput"
          maxlength=""
          type="email"
          formControlName="initialEmail"
          class="grv-textfield__input"
          [placeholder]="content.placeholderText | translate"
          data-cy="emailInput"
          noCopy
        />
        <span
          class="grv-text--tiny error"
          *ngIf="showInvalidEmail()"
          [innerHTML]="content.invalidEmail | translate"
        ></span>
      </div>
      <div class="grv-form-field">
        <label
          for="confirmEmailInput"
          class="grv-textfield__label"
          [innerHTML]="content.secondEmailLabel | translate"
        ></label>
        <input
          id="confirmEmailInput"
          name="confirmEmailInput"
          maxlength=""
          type="email"
          formControlName="confirmEmail"
          class="grv-textfield__input"
          [placeholder]="content.placeholderText | translate"
          data-cy="confirmEmailInput"
          noCopy
        />
        <span class="grv-text--tiny error" *ngIf="showUnmatchedEmail()">
          {{ showConfirmEmailErrorMessage() | translate }}
        </span>
      </div>
    </div>
  </form>
  <p class="grv-margin__top--medium" [innerHTML]="content.additionalInfo | translate"></p>
  <div class="grv-dialog__buttons">
    <button
      id="saveButton"
      class="grv-button--action"
      (click)="updateEmail()"
      (keyup.enter)="updateEmail()"
      [innerHTML]="content.saveButton | translate"
      [disabled]="!emailFormGroup.valid"
      data-cy="save-button"
    ></button>
  </div>
</c1-privacy-dialog>
