import { HttpClient, HttpErrorResponse } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"
import { throwError, Observable } from "rxjs"
import { catchError } from "rxjs/operators"

import { Category } from "src/app/models"
import { privacyCenterApiOptions } from "../http-options"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { CookieService } from "ngx-cookie-service"
import { ccpaTrackingCookie, ccpaCookies, ccpaActorTypeCookie } from "src/app/common"
import { v4 as uuidv4 } from "uuid"
import { IntrospectService } from "../intropsect/introspect.service"
import { ActorType, ActorTypeOLMap } from "src/app/models/common/ActorType"
import { AppConfig, APP_CONFIG } from "src/app.config"

export enum AuthType {
  SignIn = "SignIn",
  NonCustomerSignIn = "NonCustomerSignIn",
  DownloadStepUp = "DownloadStepUp",
  DeleteStepUp = "DeleteStepUp",
  CorrectionStepUp = "CorrectionStepUp",
}

interface AuthUrlParams {
  scope: string
  state: string
  login_hint?: "step_up" | "public"
}

export interface DeleteStepUpData {
  email: string
  categories: Category[]
}

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private newRelicService: NewRelicService,
    private cookieService: CookieService,
    private introspectService: IntrospectService
  ) {
    this.config = config

    this.universalAuthParams = [
      ["client_id", this.config.oauthClientID],
      ["redirect_uri", this.config.redirectUri],
      ["response_type", "code"],
    ]

    this.callLogoutEndpoint$ = this.http.get(`${this.config.apiURL}/logout`, privacyCenterApiOptions)

    this.logout$ = this.callLogoutEndpoint$.pipe(
      retryWithBackoff((msg) =>
        this.newRelicService.logCustomError(msg, {
          logLevel: ErrorLoggingLevel.Error,
        })
      ),
      catchError((err: HttpErrorResponse) => {
        this.newRelicService.logCustomError(
          `Error in the GET call to /logout. The user was navigated to the Front Door. ${JSON.stringify(err)}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        window.location.href = this.config.LogoutLandingUrl
        return throwError(err)
      })
    )

    this.clearSessionPreGovId$ = this.callLogoutEndpoint$.pipe(
      retryWithBackoff((msg) =>
        this.newRelicService.logCustomError(msg, {
          logLevel: ErrorLoggingLevel.Error,
        })
      ),
      catchError((err: HttpErrorResponse) => {
        this.newRelicService.logCustomError(
          `Error in the GET call to /logout. The user was navigated to the GovId Page. ${JSON.stringify(err)}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        window.location.href = this.authUrl(AuthType.NonCustomerSignIn)
        return throwError(err)
      })
    )
  }

  guid: string
  config: AppConfig
  initialInjection: boolean = true
  logout$: Observable<any>
  clearSessionPreGovId$: Observable<any>
  private universalAuthParams: string[][]
  private callLogoutEndpoint$: Observable<any>

  private queryParamMap: { [key: string]: AuthUrlParams } = {
    [AuthType.DeleteStepUp]: {
      scope: "ccpa_request ccpa_retrieve openid",
      state: "",
      login_hint: "step_up",
    },
    [AuthType.CorrectionStepUp]: {
      scope: "ccpa_request ccpa_retrieve openid",
      state: "",
      login_hint: "step_up",
    },
    [AuthType.DownloadStepUp]: {
      scope: "ccpa_request ccpa_retrieve openid",
      state: "",
      login_hint: "step_up",
    },
    [AuthType.SignIn]: {
      scope: "ccpa_request openid",
      state: "",
    },
    [AuthType.NonCustomerSignIn]: {
      scope: "ccpa_request openid",
      state: "",
      login_hint: "public",
    },
  }

  authUrl = (authType: AuthType): string => {
    this.queryParamMap[authType].state = this.getStateForAuthType(authType)
    return this.buildAuthUrl(authType)
  }

  buildAuthUrl(authType: AuthType): string {
    const authParams = [...this.universalAuthParams, ...new Map(Object.entries(this.queryParamMap[authType]))]

    return `${this.config.gatewayOauthSignIn}?${authParams
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&")}`
  }

  getStateForAuthType(authType: AuthType, stateData?: any) {
    const states = {
      [AuthType.DownloadStepUp]: {
        eventType: "download_asset",
        actorType: `${this.introspectService.getActorType()}`,
        correlationId: this.getUniqueId(),
      },
      [AuthType.DeleteStepUp]: {
        eventType: "submit_delete",
        actorType: `${this.introspectService.getActorType()}`,
        correlationId: this.getUniqueId(),
      },
      [AuthType.CorrectionStepUp]: {
        eventType: "submit_correction",
        actorType: `${this.introspectService.getActorType()}`,
        correlationId: this.getUniqueId(),
      },
      [AuthType.SignIn]: {
        eventType: "login",
        actorType: "customer",
        correlationId: this.getUniqueId(),
      },
      [AuthType.NonCustomerSignIn]: {
        eventType: "login",
        actorType: "non-customer",
        correlationId: this.getUniqueId(),
      },
    }

    return btoa(JSON.stringify(states[authType]))
  }

  getUniqueId(): string {
    let ccpaLongUID = ""
    if (this.cookieService.check(ccpaCookies.ccpaTrackingGUID)) {
      ccpaLongUID = this.cookieService.get(ccpaCookies.ccpaTrackingGUID).trim()
    }
    return ccpaLongUID
  }

  setUserSessionCookie(): void {
    if (!this.cookieService.check(ccpaTrackingCookie.name)) {
      this.guid = this.guid ? this.guid : uuidv4()
      this.cookieService.set(
        ccpaTrackingCookie.name,
        this.guid,
        ccpaTrackingCookie.expires,
        ccpaTrackingCookie.path,
        this.config.cookieDomain,
        ccpaTrackingCookie.secure,
        ccpaTrackingCookie.sameSite
      )
    }
  }

  getTrackingCookie(): string {
    const ccId = this.cookieService.get(ccpaCookies.correlationId)
    const c1Myd = this.cookieService.get(ccpaCookies.ccpaTrackingGUID)

    return ccId ? ccId : c1Myd
  }

  public getUserSessionCookie(): string {
    return this.cookieService.get(ccpaTrackingCookie.name)
  }

  setActorType(actorType: ActorType): void {
    const formattedActor = actorType.replace("-", "_")
    const actorTypeValue = ActorTypeOLMap[formattedActor]
    this.cookieService.set(
      ccpaActorTypeCookie.name,
      actorTypeValue,
      ccpaActorTypeCookie.expires,
      ccpaActorTypeCookie.path,
      this.config.cookieDomain,
      ccpaActorTypeCookie.secure,
      ccpaActorTypeCookie.sameSite
    )
  }
}
