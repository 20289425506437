<header>
  <div class="header__wrapper">
    <div class="header__container">
      <div class="capital-one-logo">
        <a
          class="header-logo-container"
          role="button"
          aria-label="Capital One Logo"
          (click)="logout($event)"
          tabindex="0"
          (keyup.enter)="logout($event)"
          (keydown.space)="logout($event)"
        >
          <c1-privacy-icon [iconName]="'logo'" [size]="'default'"></c1-privacy-icon>
        </a>
      </div>

      <div *ngIf="headerContent.showNav" class="options-nav">
        <div class="options-nav-button">
          <a
            class="options-nav-container"
            (click)="logout($event)"
            role="button"
            tabindex="0"
            (keyup.enter)="logout($event)"
            (keydown.space)="logout($event)"
          >
            <c1-privacy-icon class="profile" [iconName]="'avatar'" [size]="'tiny'"></c1-privacy-icon>
            <span class="grv-text--normal">{{ headerContent.navLabel }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
