import { Component } from "@angular/core"

import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { HTMLConfig } from "src/app/interfaces/htmlConfig.interface"
import { AuthenticationService, AuthType } from "src/app/services/auth/authentication.service"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { newRelicEvent } from "src/app/common"
import { ActorType } from "src/app/models/common/ActorType"

@Component({
  selector: "c1-privacy-gov-id-verification",
  templateUrl: "./gov-id-verification.component.html",
  styleUrls: ["./gov-id-verification.component.scss"],
})
export class GovIdVerificationComponent {
  public subheaderContent: SubheaderContent = {
    icon: "identityServices",
    headerText: "GOV_ID_VERIFICATION.HEADER",
  }

  public htmlConfig: HTMLConfig = {
    cof_disclosure: {
      tag: "a",
      classes: "grv-color--core-blue-40 disclosure-link",
      attributes: [
        {
          key: "href",
          value: "https://www.capitalone.com/bank/personal-data/disclosures/",
        },
      ],
      textKey: "GOV_ID_VERIFICATION.DISCLOSURE_LINK_TEXT",
    },
  }

  constructor(private authenticationService: AuthenticationService, private newRelicService: NewRelicService) {}

  public navigateToGovIdPage(): void {
    this.authenticationService.clearSessionPreGovId$.subscribe(() => {
      // We do not need this call to setUserSessionCookie anymore because the cookie is no longer removed on logout.
      // There is no risk to keep the call here other than it will attempt to make a second call to generate a UUID for the user.
      // Given that cookie isn't deleted, it will skip regenerating a new UUID and will proceed to GW govID sign-on portal.
      // Leaving function in place in case team decides to revisit deleting cookie upon logout.
      this.authenticationService.setUserSessionCookie()
      this.authenticationService.setActorType(ActorType.NON_CUSTOMER)
      this.newRelicService.logCustomError("User was navigated to the GovID Experience.", {
        event: newRelicEvent.govIdLogin,
        stateField: `${this.authenticationService.getStateForAuthType(AuthType.NonCustomerSignIn)}`,
        logLevel: ErrorLoggingLevel.Info,
      })
      window.location.href = this.authenticationService.authUrl(AuthType.NonCustomerSignIn)
    })
  }
}
