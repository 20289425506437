import { HttpClient } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { map, catchError, publishReplay, refCount, takeUntil } from "rxjs/operators"
import { combineLatest, Observable, of, Subject } from "rxjs"

import { RequestType } from "src/app/models/common"
import { privacyCenterApiOptions } from "../http-options"
import { RouteName } from "src/app/route-name"
import { PrivacyDataRequestSummaryItem, PrivacyPortalHistoryResponse, PrivacyDataRequestStatus } from "src/app/models"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "../maintenance-banner/maintenance-banner.service"
import {MaintenanceTypes} from "../../enums/maintenance-types";

@Injectable({
  providedIn: "root",
})
export class PortalHistoryService {
  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private router: Router,
    private newRelicService: NewRelicService,
    private maintenanceBannerService: MaintenanceBannerService
  ) {
    this.baseUrl = config.apiURL
  }

  logHelper = (msg) =>
    this.newRelicService.logCustomError(msg, {
      logLevel: ErrorLoggingLevel.Error,
    })

  private reloadHistoryCache$ = new Subject<void>()
  private downloadHistory$: Observable<PrivacyDataRequestSummaryItem[]>
  private correctionHistory$: Observable<PrivacyDataRequestSummaryItem[]>
  private deleteHistory$: Observable<PrivacyDataRequestSummaryItem[]>
  private baseUrl: string
  private maintenance: boolean = this.maintenanceBannerService.bannerIsValid()
  private maintenanceType: MaintenanceTypes = this.maintenanceBannerService.getType()

  getDownloadHistory(): Observable<PrivacyDataRequestSummaryItem[]> {
    if (!this.downloadHistory$) {
      this.downloadHistory$ = this.http
        .get<PrivacyPortalHistoryResponse>(
          `${this.baseUrl}/request/history?type=${RequestType.DOWNLOAD}`,
          privacyCenterApiOptions
        )
        .pipe(
          retryWithBackoff(this.logHelper),
          catchError((err) => {
            const errMessage = `Error in the GET call to /history. The user was navigated to the Snag page. ${JSON.stringify(
              err
            )}`
            this.logHelper(errMessage)
            if (this.maintenance) {
              this.router.navigate([`/${RouteName.Unavailable}`])
            } else {
              this.router.navigate([`/${RouteName.Snag}`])
            }
            return of({
              privacyDataRequestSummaryItems: [],
            } as PrivacyPortalHistoryResponse)
          }),
          map((response) => {
            if (response.privacyDataRequestSummaryItems) {
              return response.privacyDataRequestSummaryItems
            }
            return []
          }),
          takeUntil(this.reloadHistoryCache$),
          publishReplay(1),
          refCount()
        )
    }
    return this.downloadHistory$
  }

  getCorrectionHistory(): Observable<PrivacyDataRequestSummaryItem[]> {
    if (!this.correctionHistory$) {
      this.correctionHistory$ = this.http
        .get<PrivacyPortalHistoryResponse>(
          `${this.baseUrl}/request/history?type=${RequestType.CORRECTION}`,
          privacyCenterApiOptions
        )
        .pipe(
          retryWithBackoff(this.logHelper),
          catchError((err) => {
            // TODO: add back snag redirect when API changes are added
            // const errMessage = `Error in the GET call to /history. The user was navigated to the Snag page. ${JSON.stringify(
            //   err
            // )}`
            // this.logHelper(errMessage)
            // this.router.navigate([`/${RouteName.Snag}`])
            return of({
              privacyDataRequestSummaryItems: [],
            } as PrivacyPortalHistoryResponse)
          }),
          map((response) => {
            if (response.privacyDataRequestSummaryItems) {
              return response.privacyDataRequestSummaryItems
            }
            return []
          }),
          takeUntil(this.reloadHistoryCache$),
          publishReplay(1),
          refCount()
        )
    }
    return this.correctionHistory$
  }

  getDeleteHistory(): Observable<PrivacyDataRequestSummaryItem[]> {
    if (!this.deleteHistory$) {
      this.deleteHistory$ = this.http
        .get<PrivacyPortalHistoryResponse>(
          `${this.baseUrl}/request/history?type=${RequestType.DELETE}`,
          privacyCenterApiOptions
        )
        .pipe(
          retryWithBackoff(this.logHelper),
          catchError((err) => {
            const errMessage = `Error in the GET call to /history. The user was navigated to the Snag page. ${JSON.stringify(
              err
            )}`
            this.logHelper(errMessage)
            if (this.maintenance) {
              this.router.navigate([`/${RouteName.Unavailable}`])
            } else {
              this.router.navigate([`/${RouteName.Snag}`])
            }
            return of({
              privacyDataRequestSummaryItems: [],
            } as PrivacyPortalHistoryResponse)
          }),
          map((response) => {
            if (response.privacyDataRequestSummaryItems) {
              return response.privacyDataRequestSummaryItems
            }
            return []
          }),
          takeUntil(this.reloadHistoryCache$),
          publishReplay(1),
          refCount()
        )
    }
    return this.deleteHistory$
  }

  getPortalHistoryData(): Observable<PrivacyDataRequestSummaryItem[]> {
    return combineLatest([this.getDownloadHistory(), this.getCorrectionHistory(), this.getDeleteHistory()]).pipe(
      map(([dwn, crt, dlt]) => {
        let history = dwn.concat(crt, dlt)
        return history
      })
    )
  }

  getDownloadPendingRequests(): Observable<PrivacyDataRequestSummaryItem[]> {
    return this.getDownloadHistory().pipe(
      map((prs: PrivacyDataRequestSummaryItem[]) =>
        prs.filter((pr) => {
          return (
            pr.requestStatus !== PrivacyDataRequestStatus.COMPLETED &&
            pr.requestStatus !== PrivacyDataRequestStatus.EXPIRED
          )
        })
      )
    )
  }

  getCorrectionPendingRequests(): Observable<PrivacyDataRequestSummaryItem[]> {
    return this.getCorrectionHistory().pipe(
      map((prs: PrivacyDataRequestSummaryItem[]) =>
        prs.filter((pr) => {
          return (
            pr.requestStatus !== PrivacyDataRequestStatus.COMPLETED &&
            pr.requestStatus !== PrivacyDataRequestStatus.EXPIRED
          )
        })
      )
    )
  }

  getDeletePendingRequests(): Observable<PrivacyDataRequestSummaryItem[]> {
    return this.getDeleteHistory().pipe(
      map((prs: PrivacyDataRequestSummaryItem[]) =>
        prs.filter((pr) => {
          return (
            pr.requestStatus !== PrivacyDataRequestStatus.COMPLETED &&
            pr.requestStatus !== PrivacyDataRequestStatus.EXPIRED
          )
        })
      )
    )
  }

  invalidateHistoryCache() {
    this.reloadHistoryCache$.next()
    this.downloadHistory$ = null
    this.correctionHistory$ = null
    this.deleteHistory$ = null
  }
}
