export enum ApplicationImages {
  USER = "C1_PrimaryIcon_RGB_user_Circle.svg",
  FRAUD = "C1_PrimaryIcon_RGB_fraud_Circle.svg",
  FEATURES = "C1_PrimaryIcon_RGB_features_Circle.svg",
  OFFERS = "C1_PrimaryIcon_RGB_offers_Circle.svg",
  FILLED_USER = "C1_PrimaryIcon_RGB_filled-user_Circle.svg",
  DOCUMENT = "C1_PrimaryIcon_RGB_documents_Circle.svg",
  SUCCESS = "C1_PrimaryIcon_RGB_success_Circle.svg",
  GAUGE = "C1_PrimaryIcon_RGB_gauge_Circle.svg",
  CAUTION = "C1_PrimaryIcon_RGB_caution_Circle.svg",
  CAUTION2 = "ui-filled-caution-1-24.svg",
  PLANE = "C1_PrimaryIcon_RGB_plane_Circle.svg",
  CLOCK = "C1_PrimaryIcon_RGB_clock_Circle.svg",
  ERROR = "C1_PrimaryIcon_RGB_error_Circle.svg",
  BRANCH = "C1_PrimaryIcon_RGB_branch_Circle.svg",
  SECURITY_APPROVAL = "C1_PrimaryIcon_RGB_security-approval_Circle.svg",
  GO_ONLINE = "C1_SelectiveIcon_RGB_go-online_Circle.svg",
  CORRECTION = "C1_SelectiveIcon_RGB_correction_Circle.svg",
  MAILBOX = "C1_SelectiveIcon_RGB_mailbox_Circle.svg",
  CONTACT = "C1_PrimaryIcon_RGB_contact_Circle.svg",
  DATA_STAGGERED = "C1_PrimaryIcon_RGB_data_staggered_Circle.svg",
  CHECKMARK = "ui-filled-checkmark-1-24.svg",
  AVATAR = "C1_PrimaryIcon_RGB_avatar_Circle.svg",
  IDENTITY_SERVICES = "C1_PrimaryIcon_RGB_identity-services_Circle.svg",
  LOGO = "capital_one_logo.svg",
  NOTIFICATION = "C1_PrimaryIcon_RGB_notification_Circle.svg",
  IDENTITY = "C1_PrimaryIcon_RGB_identity_Circle.svg",
  TOOLBOX = "C1_PrimaryIcon_RGB_toolbox_Circle.svg",
  HASDATA = "C1_PrimaryIcon_RGB_checkmark_asset_category.svg",
  HASNODATA = "C1_PrimaryIcon_RGB_cross_asset_category.svg",
  INFO = "C1_PrimaryIcon_RGB_information.svg",
  ARROW_UP = "ui-lined-arrow-up-1-24.svg",
  ARROW_DOWN = "ui-lined-arrow-down-1-24.svg",
  ARROW_RIGHT = "ui-lined-arrow-right-1-24.svg",
  CLOCK_FILLED = "ui-filled-clock-1-24.svg",
  TRASH = "ui-lined-trash-1-24.svg",
  TRASH_BLUE = "ui-lined-trash-blue-1-24.svg",
  PLUS = "ui-lined-plus-1-24.svg",
  EDIT = "ui-filled-edit-1-24.svg",
  PAPER = "ui-lined-paper-1-24.svg",
  DOWNLOAD = "C1_SelectiveIcon_RGB_download_app_Circle.svg",
  MAKE_PLAN = "C1_SelectiveIcon_RGB_make_plan_Circle.svg",
  PEN = "ui-lined-pen-1-24.svg",
  EQUAL_OPPORTUNITY = "equal-housing-opportunity-new.svg",
  FDIC = "member-fdic-new.svg",
  XCLOSE = "ui-xclose.svg",
}
