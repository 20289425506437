import { Component, ViewChild } from "@angular/core"
import { privacyPolicyCa, privacyPolicyUk, newRelicEvent } from "src/app/common"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { CallCenterDialogComponent } from "../../components/call-center-dialog/call-center-dialog.component"
import { HTMLConfig } from "../../interfaces/htmlConfig.interface"
import { AuthenticationService, AuthType } from "../../services/auth/authentication.service"
import { RouteName } from "src/app/route-name"
import { Router } from "@angular/router"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { ActorType } from "src/app/models/common/ActorType"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"

@Component({
  selector: "c1-privacy-sign-in-options",
  templateUrl: "./sign-in-options.component.html",
  styleUrls: ["./sign-in-options.component.scss"],
})
export class SignInOptionsComponent {
  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()
  subheaderContent: SubheaderContent = {
    icon: "goOnline",
    headerText: "SIGN_IN_OPTIONS.HEADER",
  }
  singleSignOn: string
  govIdPage = `../${RouteName.GovIdVerification}`
  otherIdentityButtonContent = [
    {
      label: "SIGN_IN_OPTIONS.COMMERCIAL_BUTTON_LABEL",
      id: "commercial-button",
    },
    {
      label: "SIGN_IN_OPTIONS.FORMER_BUTTON_LABEL",
      id: "former-customer-button",
    },
    {
      label: "SIGN_IN_OPTIONS.OTHER_CAPITAL_ONE_PRODUCTS_BUTTON_LABEL",
      id: "other-products-button",
    },
    {
      label: "SIGN_IN_OPTIONS.NO_ACCOUNT_BUTTON_LABEL",
      id: "no-account-button",
    },
  ]

  htmlConfig: HTMLConfig = {
    check_sav_acct: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_DESCRIPTION.CHECKING_SAVING_HTML_TEXT",
    },
    credit_cards: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_DESCRIPTION.CREDIT_CARD_HTML_TEXT",
    },
    car_loans: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_DESCRIPTION.CAR_LOANS_HTML_TEXT",
    },
    small_bis: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.PERSONAL_SBB_BUTTON_DESCRIPTION.SMALL_BIS_HTML_TEXT",
    },
    cnda_privacy_policy: {
      tag: "a",
      classes: "grv-text__link--no-style link grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: privacyPolicyCa,
        },
        {
          key: "data-cy",
          value: "canada-link",
        },
      ],
      textKey: "SIGN_IN_OPTIONS.CANADA_DESCRIPTION.HTML_LINK_TEXT",
    },
    access_request: {
      tag: "a",
      classes: "grv-text__link--no-style link grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: privacyPolicyUk,
        },
        {
          key: "data-cy",
          value: "uk-link",
        },
      ],
      textKey: "SIGN_IN_OPTIONS.UK_DESCRIPTION.HTML_LINK_TEXT",
    },
    cof_cnda: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.CANADA_DESCRIPTION.HTML_STRONG_TEXT",
    },
    cof_uk: {
      tag: "strong",
      textKey: "SIGN_IN_OPTIONS.UK_DESCRIPTION.HTML_STRONG_TEXT",
    },
  }

  @ViewChild(CallCenterDialogComponent)
  private callCenterDialog: CallCenterDialogComponent

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private newRelicService: NewRelicService,
    private oneTagService: OneTagService,
    public maintenanceBannerService: MaintenanceBannerService,
  ) {}

  ngOnInit() {
    this.oneTagService.setPageView();
  }

  callCenterDialogOpen() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'signInCallCenterDialogOpen');
    this.callCenterDialog.open()
  }

  goToGovId() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'signInGovIdLogin');
    this.router.navigate([`../${RouteName.GovIdVerification}`])
  }

  goToGWLogin() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'signInPersonalAccLogin');
    const gwMessage = "User navigating to GW Customer login page"
    this.authenticationService.setActorType(ActorType.CUSTOMER)
    this.newRelicService.logInteraction(gwMessage)
    this.newRelicService.logCustomError(gwMessage, {
      event: newRelicEvent.login,
      stateField: `${this.authenticationService.getStateForAuthType(AuthType.SignIn)}`,
      logLevel: ErrorLoggingLevel.Info,
    })
    window.location.href = this.authenticationService.authUrl(AuthType.SignIn)
  }

  caPrivacyPolicy() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'signInCanadaPrivacyPolicy');
    window.location.href=privacyPolicyCa
  }

  ukPrivacyPolicy() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'signInUKPrivacyPolicy');
    window.location.href=privacyPolicyUk
  }
}
