<c1-privacy-dialog class="dialog-content-container">
  <ng-container *ngIf="type === InEligibileDialogType.DownloadInProgress">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.IN_PROGRESS.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.IN_PROGRESS.DESCRIPTION1" | translate }}
    </p>
    <div class="dialog-button-container" style="justify-content: flex-end">
      <button
        class="grv-button--action"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="download-in-progress-okay-button"
      >
        {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.IN_PROGRESS.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === InEligibileDialogType.DeleteInProgress">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_DELETE_REQUEST_DIALOG.IN_PROGRESS.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_DELETE_REQUEST_DIALOG.IN_PROGRESS.DESCRIPTION1" | translate }}
    </p>
    <div class="dialog-button-container" style="justify-content: flex-end">
      <button
        class="grv-button--progressive"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="delete-in-progress-okay-button"
      >
        {{ "INELIGIBLE_DELETE_REQUEST_DIALOG.IN_PROGRESS.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="type === InEligibileDialogType.CorrectionInProgress">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.ALREADY_SUBMITTED.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.ALREADY_SUBMITTED.DESCRIPTION1" | translate }}
    </p>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.ALREADY_SUBMITTED.DESCRIPTION2" | translate }}
      <a [href]="content.helpLink" target="_blank" rel="noopener noreferrer">{{
        "INELIGIBLE_CORRECTION_REQUEST_DIALOG.ALREADY_SUBMITTED.LINK_TEXT" | translate
      }}</a
      >.
    </p>
    <div class="dialog-button-container" style="justify-content: flex-end">
      <button
        class="grv-button--progressive"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="already-submitted-close-button"
      >
        {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.ALREADY_SUBMITTED.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container class="dialog-content-container" *ngIf="type === InEligibileDialogType.CorrectionNoDownload">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.DESCRIPTION1" | translate }}
    </p>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.DESCRIPTION2" | translate }}
      <a [href]="content.helpLink">{{
        "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.LINK_TEXT" | translate
      }}</a
      >.
    </p>
    <div class="dialog-button-container">
      <button
        class="grv-button--text"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="request-download-close-button"
      >
        {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.CLOSE_BUTTON" | translate }}
      </button>
      <button
        class="grv-button--progressive"
        (click)="goToCreate()"
        (keyup.enter)="goToCreate()"
        tabindex="0"
        data-cy="request-download-button"
      >
        {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.REQUEST_TO_DOWNLOAD.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container class="dialog-content-container" *ngIf="type === InEligibileDialogType.DownloadAvailable">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.AVAILABLE.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.AVAILABLE.DESCRIPTION" | translate }}
    </p>
    <div class="dialog-button-container">
      <button
        class="grv-button--text"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="request-download-close-button"
      >
        {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.AVAILABLE.CLOSE_BUTTON" | translate }}
      </button>
      <button
        class="grv-button--progressive"
        (click)="goToDownloadStepUp()"
        (keyup.enter)="goToDownloadStepUp()"
        tabindex="0"
        data-cy="request-download-button"
      >
        {{ "INELIGIBLE_DOWNLOAD_REQUEST_DIALOG.AVAILABLE.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>
  <ng-container class="dialog-content-container" *ngIf="type === InEligibileDialogType.CorrectionDownloadInProgress">
    <h1 class="grv-text__heading--large" id="ineligible-modal-header">
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.HEADER" | translate }}
    </h1>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.DESCRIPTION1" | translate: { requestId: this.requestId} }}
    </p>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.DESCRIPTION2" | translate }}
    </p>
    <p>
      {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.DESCRIPTION3" | translate }}
      <a [href]="content.helpLink">{{
        "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.LINK_TEXT" | translate
      }}</a
      >.
    </p>
    <div class="dialog-button-container-right-justified">
      <button
        class="grv-button--progressive"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        data-cy="correction-download-in-progress-close-button"
      >
        {{ "INELIGIBLE_CORRECTION_REQUEST_DIALOG.DOWNLOAD_IN_PROGRESS.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </ng-container>
</c1-privacy-dialog>
