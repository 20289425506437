import { Component, Input } from "@angular/core"
import { Observable } from "rxjs"
import { Category, PrivacyDataRequestStatus, PrivacyDataRequestSummaryItem } from "src/app/models"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { RequestType } from "src/app/models/common/RequestType"
import { Status } from "../connex-skeleton/connex-skeleton.component"

@Component({
  selector: "c1-privacy-in-progress",
  templateUrl: "./in-progress.component.html",
  styleUrls: ["./in-progress.component.scss"],
})
export class InProgressComponent {
  @Input() request: PrivacyDataRequestSummaryItem
  @Input() type: RequestType
  @Input() queryParams: any = {}
  @Input() divider: boolean = false
  selectedCategories$: Observable<Category[]>

  public get RequestType() {
    return RequestType
  }

  public get PrivacyDataRequestStatus() {
    return PrivacyDataRequestStatus
  }

  public get ConnexStatus() {
    return Status
  }

  checkmarkSize = document.body.offsetWidth <= 600 ? "angstrem" : "micro"

  constructor(public privacyRequestService: PrivacyRequestService) {}

  onExpanded() {
    this.selectedCategories$ = this.privacyRequestService.getDownloadCategories(this.request.privacyDataRequestId)
  }
}
