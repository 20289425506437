import { HttpClient } from "@angular/common/http"
import { Inject, Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { privacyCenterApiOptions } from "../http-options"

interface MonitorPayload {
  errorOccurenceTimestamp: string
  errorDescription: string
  errorId?: string
  errorCategory: string
  messageId: string
  correlationId: string
}

@Injectable({
  providedIn: "root",
})
export class MonitorService {
  constructor(@Inject(APP_CONFIG) config: AppConfig, private http: HttpClient) {
    this.baseUrl = config.apiURL
  }

  baseUrl: string

  logVipMonitor$(error, ccid): Observable<any> {
    const payload: MonitorPayload = {
      errorOccurenceTimestamp: new Date().toISOString(),
      errorDescription: error.message,
      errorCategory: "UI Error",
      messageId: ccid,
      correlationId: ccid,
    }

    return this.http.post(`${this.baseUrl}/customer/errors`, payload, privacyCenterApiOptions)
  }
}
