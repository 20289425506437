import { Component, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from "@angular/core"
import { HTMLConfig } from "src/app/interfaces/htmlConfig.interface"
import { FocusTitleService } from "src/app/services/focusTitle/focus-title.service"
import { ccpaDisclosure, emailPreferences, collectData, privacyRights } from "src/app/common"

@Component({
  selector: "c1-privacy-data-categories",
  templateUrl: "./data-categories.component.html",
  styleUrls: ["./data-categories.component.scss"],
})
export class DataCategoriesComponent implements AfterViewInit {
  @ViewChild("focusRef") focusRef: ElementRef
  @ViewChild("expandCollapseAllBtn")
  expandCollapseAllBtn: ElementRef
  @ViewChildren("accordionButton") accordionButtons: QueryList<ElementRef>
  @ViewChildren("accordionSection") accordionSections: QueryList<ElementRef>
  public expandText: string = "DATA_CATEGORIES.EXPAND_ALL"
  public collapseText: string = "DATA_CATEGORIES.COLLAPSE_ALL"
  public expandCollapseBtnText: string = this.expandText
  public allExpanded: boolean = false

  constructor(private focusTitleService: FocusTitleService) {}

  ngAfterViewInit() {
    this.focusTitleService.focusElement(this.focusRef)
  }

  htmlConfig: HTMLConfig = {
    ccpa_disclosure: {
      tag: "a",
      classes: "grv-text--normal-semibold small-text-on-mobile grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: ccpaDisclosure,
        },
        {
          key: "data-cy",
          value: "ccpa-disclosure-link",
        },
      ],
      textKey: "DATA_CATEGORIES.CCPA_DISCLOSURE_LINK",
    },
    email_preferences: {
      tag: "a",
      classes: "grv-text--normal-semibold small-text-on-mobile grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: emailPreferences,
        },
        {
          key: "data-cy",
          value: "email-preferences-link",
        },
      ],
      textKey: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.EMAIL_PREFERENCES_LINK",
    },
    collect: {
      tag: "a",
      classes: "grv-text--normal-semibold small-text-on-mobile grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: collectData,
        },
        {
          key: "data-cy",
          value: "collect-link",
        },
      ],
      textKey: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.COLLECT_LINK",
    },
    privacy_rights: {
      tag: "a",
      classes: "grv-text--normal-semibold small-text-on-mobile grv-color--core-blue-40",
      attributes: [
        {
          key: "href",
          value: privacyRights,
        },
        {
          key: "data-cy",
          value: "privacy-rights-link",
        },
      ],
      textKey: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.PRIVACY_RIGHTS_LINK",
    },
  }

  public accordionClick(button, section): void {
    button.classList.toggle("grv-accordion__title--active")
    button.getAttribute("aria-expanded") === "true"
      ? button.setAttribute("aria-expanded", "false")
      : button.setAttribute("aria-expanded", "true")
    section.classList.toggle("grv-accordion__section--active")
    section.getAttribute("aria-hidden") === "true"
      ? section.setAttribute("aria-hidden", "false")
      : section.setAttribute("aria-hidden", "true")
    if (section.classList.contains("grv-accordion__section--active")) {
      section.style.display = "block"
    } else {
      section.style.display = "none"
    }

    this.updateExpandCollapseBtn()
  }

  public updateExpandCollapseBtn() {
    this.allExpanded = this.accordionButtons.toArray().every((button) => {
      return button.nativeElement.classList.contains("grv-accordion__title--active")
    })
    if (!this.allExpanded) {
      this.expandCollapseBtnText = this.expandText
    } else {
      this.expandCollapseBtnText = this.collapseText
    }
  }

  private expandCollapseButton(button, expand: boolean) {
    if (expand) {
      button.nativeElement.classList.add("grv-accordion__title--active")
      button.nativeElement.setAttribute("aria-expanded", "true")
    } else {
      button.nativeElement.classList.remove("grv-accordion__title--active")
      button.nativeElement.setAttribute("aria-expanded", "false")
    }
  }

  private expandCollapseSection(section, expand: boolean) {
    if (expand) {
      section.nativeElement.classList.add("grv-accordion__section--active")
      section.nativeElement.setAttribute("aria-hidden", "false")
      section.nativeElement.style.display = "block"
    } else {
      section.nativeElement.classList.remove("grv-accordion__section--active")
      section.nativeElement.setAttribute("aria-hidden", "true")
      section.nativeElement.style.display = "none"
    }
  }

  public expandAll(): void {
    if (!this.allExpanded) {
      this.accordionButtons.forEach((button) => this.expandCollapseButton(button, true))
      this.accordionSections.forEach((section) => this.expandCollapseSection(section, true))
      this.expandCollapseBtnText = this.collapseText
      this.allExpanded = true
    } else {
      this.accordionButtons.forEach((button) => this.expandCollapseButton(button, false))
      this.accordionSections.forEach((section) => this.expandCollapseSection(section, false))
      this.expandCollapseBtnText = this.expandText
      this.allExpanded = false
    }
  }

  accordionItems = [
    {
      title: "DATA_CATEGORIES.ACCORDION.PERSONAL_IDENTIFIERS",
      content: "DATA_CATEGORIES.ACCORDION.PERSONAL_IDENTIFIERS_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.PROTECTED_CLASS",
      content: "DATA_CATEGORIES.ACCORDION.PROTECTED_CLASS_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.COMMERCIAL_INFO",
      content: "DATA_CATEGORIES.ACCORDION.COMMERCIAL_INFO_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.BIOMETRIC_INFO",
      content: "DATA_CATEGORIES.ACCORDION.BIOMETRIC_INFO_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.INTERNET_USE",
      content: "DATA_CATEGORIES.ACCORDION.INTERNET_USE_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.LOCATION_DATA",
      content: "DATA_CATEGORIES.ACCORDION.LOCATION_DATA_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.AUDIO_VISUAL",
      content: "DATA_CATEGORIES.ACCORDION.AUDIO_VISUAL_TEXT",
    },
    {
      title: "DATA_CATEGORIES.ACCORDION.INFERENCES",
      content: "DATA_CATEGORIES.ACCORDION.INFERENCES_TEXT",
    },
  ]

  iconItems = [
    {
      iconName: "notification",
      iconText: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.EMAIL_PREFERENCES",
    },
    {
      iconName: "identity",
      iconText: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.COLLECT",
    },
    {
      iconName: "toolbox",
      iconText: "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.PRIVACY_RIGHTS",
    },
  ]
}
