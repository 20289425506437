import { Component, ViewChild, OnInit, Inject } from "@angular/core"
import { AuthenticationService } from "../../services/auth/authentication.service"
import { SubheaderContent } from "../../components/subheader/SubheaderContent"
import { CallCenterDialogComponent } from "../../components/call-center-dialog/call-center-dialog.component"
import { RouteName } from "../../route-name"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { AppConfig, APP_CONFIG } from "src/app.config"

@Component({
  selector: "c1-privacy-multiple-sso",
  templateUrl: "./multiple-sso.component.html",
  styleUrls: ["./multiple-sso.component.scss"],
})
export class MultipleSsoComponent implements OnInit {
  subheaderContent: SubheaderContent = {
    icon: "caution",
    headerText: "MULTIPLE_SSO.HEADER",
  }

  @ViewChild(CallCenterDialogComponent)
  callCenterDialog: CallCenterDialogComponent
  logoutLandingUrl: string

  content = {
    deleteLink: "/" + RouteName.DeleteRequest,
  }

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    public authenticationService: AuthenticationService,
    private newRelicService: NewRelicService
  ) {
    this.logoutLandingUrl = config.LogoutLandingUrl
  }

  ngOnInit(): void {
    this.newRelicService.logCustomError("Landed on another-request-in-progress page", {
      event: newRelicEvent.multipleSSO,
      logLevel: ErrorLoggingLevel.Info,
    })
  }

  callCenterDialogOpen() {
    this.callCenterDialog.open()
  }

  routeToLogout() {
    //  TODO: Cover needs to be increased - David Chung
    window.location.href = this.logoutLandingUrl
  }

  signOutButton() {
    this.authenticationService.logout$.subscribe(this.routeToLogout)
  }
}
