import { Component, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core"
import { SubheaderContent } from "./SubheaderContent"
import { FocusTitleService } from "src/app/services/focusTitle/focus-title.service"

@Component({
  selector: "c1-privacy-subheader",
  templateUrl: "./subheader.component.html",
  styleUrls: ["./subheader.component.scss"],
})
export class SubheaderComponent implements AfterViewInit {
  @Input()
  subheaderContent: SubheaderContent

  @ViewChild("focusRef") focusRef: ElementRef

  constructor(private focusTitleService: FocusTitleService) {}

  ngAfterViewInit() {
    this.focusTitleService.focusElement(this.focusRef)
  }
}
