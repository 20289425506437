import { Component, Input, OnInit } from "@angular/core"

export enum Status {
  CREATE_REQUEST = "createRequest",
  DELETE_REQUEST = "deleteRequest",
  DELETE_IN_PROGRESS = "deleteInProgress",
  DOWNLOAD_IN_PROGRESS = "downloadInProgress",
  DOWNLOAD_READY = "downloadReady",
}

@Component({
  selector: "c1-privacy-connex-skeleton",
  templateUrl: "./connex-skeleton.component.html",
  styleUrls: ["./connex-skeleton.component.scss"],
})
export class ConnexSkeletonComponent implements OnInit {
  loading = true
  @Input() componentName: string
  connexStatus = Status
  constructor() {}

  @Input() data: any
  ngOnChanges(value) {
    if (value.data.currentValue) {
      this.loading = false
    }
  }

  ngOnInit(): void {}
}
