<c1-privacy-subheader-with-card [subheaderContent]="subheaderContent" class="c1-privacy-confirm-delete-request__main">
  <ng-container alert_content>
    <ng-container [ngSwitch]="guidanceBarState">
      <ng-container *ngSwitchCase="GuidanceBar.Updated" [ngTemplateOutlet]="updated"></ng-container>
      <ng-container *ngSwitchCase="GuidanceBar.Invalid" [ngTemplateOutlet]="invalid"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #card_content>
    <p class="grv-text--normal grv-text--semibold">
      {{ "CONFIRM_DELETE_REQUEST.CARD_HEADING" | translate }}
    </p>
    <ng-container *ngFor="let category of selectedCategories">
      <ng-container
        *ngIf="categoryService.testCategoryArray(selectedCategories)"
        [ngTemplateOutlet]="categoryWithSubcategories"
        [ngTemplateOutletContext]="{ category: category }"
      >
      </ng-container>
      <ng-container
        *ngIf="!categoryService.testCategoryArray(selectedCategories)"
        [ngTemplateOutlet]="singleCategory"
        [ngTemplateOutletContext]="{ category: category }"
      ></ng-container>
    </ng-container>
    <hr aria-hidden="true" />
    <p class="grv-text--normal grv-text--semibold">
      {{ "CONFIRM_DELETE_REQUEST.CARD_EMAIL_HEADING" | translate }}
    </p>
    <div class="grv-margin__bottom--large">
      <span
        *ngIf="validEmail; else noEmail"
        class="grv-text--normal"
        [innerHTML]="emailAddress"
        data-cy="email-address"
      ></span>
      <button
        class="grv-text--normal grv-text--semibold edit-text"
        (click)="openDialog()"
        (keyup.enter)="openDialog()"
        (keydown.space)="openDialog()"
        data-cy="edit-email"
      >
        {{ "CONFIRM_DELETE_REQUEST.EDIT" | translate }}
      </button>
      <c1-privacy-email-dialog
        [emailAddress]="emailAddress"
        (updatedEmailAddress)="updateEmail($event)"
      ></c1-privacy-email-dialog>
    </div>
    <div class="margin-fix button-container">
      <a
        href="javascript:void(0)"
        type="button"
        (click)="goBackToPreviousRoute()"
        class="grv-button--regressive back-button"
        data-cy="back-button"
        tabindex="0"
        (keyup.enter)="goBackToPreviousRoute()"
        (keydown.space)="goBackToPreviousRoute()"
        >{{ "CONFIRM_DELETE_REQUEST.BACK_BUTTON" | translate }}</a
      >
      <button
        type="button"
        (click)="handleSubmitClick()"
        (keyup.enter)="handleSubmitClick()"
        (keydown.space)="handleSubmitClick()"
        class="grv-button--destructive submit-button"
        [ngClass]="buttonClass"
        [disabled]="disableSubmitButton"
        data-cy="submit-button"
      >
        {{ "CONFIRM_DELETE_REQUEST.SUBIMT_BUTTON" | translate }}
      </button>
    </div>
  </ng-template>
</c1-privacy-subheader-with-card>

<ng-template #noEmail>
  <span class="grv-text--normal">
    {{ "CONFIRM_DELETE_REQUEST.NONE" | translate }}
  </span>
</ng-template>

<ng-template #updated>
  <div class="grv-alert--success">
    <p role="alert" class="grv-alert__message">
      {{ "CONFIRM_DELETE_REQUEST.GUIDANCE_BAR_SUCCESS" | translate }}
    </p>
  </div>
</ng-template>

<ng-template #invalid>
  <div class="grv-alert--error">
    <p role="alert" class="grv-alert__message">
      {{ "CONFIRM_DELETE_REQUEST.GUIDANCE_BAR_ERROR" | translate }}
    </p>
  </div>
</ng-template>

<ng-template #singleCategory let-category="category">
  <p class="grv-text--medium-large">
    <span class="grv-float--left icon">
      <c1-privacy-icon [iconName]="category.iconName" [size]="'tiny'"> </c1-privacy-icon>
    </span>
    <span class="category-title__header">{{ category.categoryName }}</span>
  </p>
</ng-template>
<ng-template #categoryWithSubcategories let-category="category">
  <p class="grv-text--medium-large subcategory-section">
    <span class="grv-float--left icon">
      <c1-privacy-icon [iconName]="category.iconName" [size]="'tiny'"></c1-privacy-icon>
    </span>
    <span class="category-title__header"
      >{{ category.categoryName }}
      <br />
      <ng-container *ngFor="let subcategory of category.subcategories">
        <span class="grv-text--small">
          {{ subcategory.subcategoryName }}
        </span>
        <br />
      </ng-container>
    </span>
  </p>
</ng-template>
