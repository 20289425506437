import { AfterViewInit, Component, Renderer2, ViewChild, OnDestroy } from "@angular/core"
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router"
import { TranslateService } from "@ngx-translate/core"
import { pageTitleConstant, routeNameToPageTitle } from "./page-titles-map"
import { AuthenticationService } from "./services/auth/authentication.service"
import { TokenManagementService } from "./services/token-management/token-management.service"
import { Title } from "@angular/platform-browser"
import { SessionDialogComponent } from "./components/session-dialog/session-dialog.component"
import { RouteName } from "./route-name"
import { Subscription } from "rxjs"
import { FeatureFlagService } from "./services/feature-flags/feature-flag.service"
import { IntrospectService } from "./services/intropsect/introspect.service"
import { NewRelicService } from "./services/new-relic/new-relic.service"
import { SessionRefreshService } from "./services/session-refresh/session-refresh.service"
import { QUERY_PARAMS } from "./common"

@Component({
  selector: "c1-privacy-app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild(SessionDialogComponent)
  dialog: SessionDialogComponent
  loading = false
  refreshSubscription: Subscription
  idleSubscription: Subscription
  routerSubscription: Subscription
  introspectSubscription: Subscription
  initialInjection = true
  loadSessionDialog = true
  readonly baseUrl = "/"
  loadTransparencyHeader = false
  nonGuardProtectedRoutes = [
    this.baseUrl,
    this.baseUrl + RouteName.SignIn,
    this.baseUrl + RouteName.Snag,
    this.baseUrl + RouteName.Unavailable,
    this.baseUrl + RouteName.DataCategories,
    this.baseUrl + RouteName.GovIdVerification,
    this.baseUrl + RouteName.TransparencyReport,
    this.baseUrl + RouteName.TransparencyReportSpanish,
    this.baseUrl + RouteName.VerificationError + `?query=${QUERY_PARAMS.GOV_ID_FAILURE}`,
    this.baseUrl + RouteName.SessionExpired
  ]

  transparencyRoutes = [
    this.baseUrl + RouteName.TransparencyReport,
    this.baseUrl + RouteName.TransparencyReportSpanish
  ]

  constructor(
    private router: Router,
    public translate: TranslateService,
    private tokenManagement: TokenManagementService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private featureFlagService: FeatureFlagService,
    private titleService: Title,
    private introspectService: IntrospectService,
    private newRelicService: NewRelicService,
    private sessionRefreshService: SessionRefreshService
  ) {
    translate.setDefaultLang("en")
    translate.use("en")

    this.routerSubscription = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        let pageTitle = routeNameToPageTitle.get(ev.url.split("?")[0].replace("/", ""))
        if (pageTitle == null) {
          pageTitle = "Page Not Found | "
        }
        this.titleService.setTitle(pageTitle + pageTitleConstant)
      } else if (ev instanceof NavigationEnd) {
        const authTriggerRoute = ["/", RouteName.SignIn, RouteName.GovIdVerification].some((route) =>
          ev.url.includes(route)
        )
        if (authTriggerRoute) {
          this.authenticationService.setUserSessionCookie()
        }
        this.newRelicService.logInteraction(ev.url)
      }
    })
  }

  ngAfterViewInit(): void {
    this.routerSubscription = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.loading = true
      } else if (evt instanceof NavigationEnd || evt instanceof NavigationCancel || evt instanceof NavigationError) {
        this.loading = false
        // refresh token only on pages not protected by a guard
        if (
          !this.nonGuardProtectedRoutes.includes(evt.url) &&
          this.authenticationService.initialInjection &&
          window.self === window.top
        ) {
          this.refreshSession()
          this.authenticationService.initialInjection = false
          this.loadSessionDialog = true
        }
        if(this.nonGuardProtectedRoutes.includes(evt.url))
        {
          if(this.refreshSubscription)
          {
            this.refreshSubscription.unsubscribe()
          }
          if(this.idleSubscription)
          {
            this.idleSubscription.unsubscribe()
          }
          this.loadSessionDialog = false
        }

        if(this.transparencyRoutes.includes(evt.url))
        {
          this.loadTransparencyHeader = true
        }
        else
        {
          this.loadTransparencyHeader = false
        }
      }
    })

    if(this.loadSessionDialog)
    {
      this.refreshSubscription = this.tokenManagement.refreshTokenTimer$.subscribe(() => {
        this.refreshSession()
      })

      this.idleSubscription = this.tokenManagement.idleDialogTimer$.subscribe(() => {
        if((!this.dialog || !this.dialog.isShowing) && this.loadSessionDialog) {
          this.dialog.open()
        }
      })
    }

    let wdw = window as any
    if (this.cempDigitalIsEnabled() === true) {
      this.initializeCempDataLayer(wdw)
      this.initializeCempDigital(wdw)
    }
  }

  private refreshSession(): void {
    this.sessionRefreshService.sessionRefresh().subscribe(() => {})
  }

  cempDigitalIsEnabled() {
    return this.featureFlagService.hasFlags("cemp_digital")
  }

  initializeCempDataLayer(wdw: any) {
    wdw.cempDataLayer = wdw.cempDataLayer || {}
    wdw.cempDataLayer.country = "USA"
  }

  startCempDigital() {
    const wdw = window as any
    if (wdw.activateCempDigital != null && typeof wdw.activateCempDigital === "function") {
      wdw.activateCempDigital()

      // Remove duplicate svg
      const svgs = document.querySelectorAll("body > [id^=SvgjsSvg]")
      if (svgs.length > 1) {
        svgs[0].remove()
      }

      return true
    }
    return false
  }

  initializeCempDigital(wdw: any) {
    if (this.startCempDigital() === false) {
      if (wdw.addEventListener) {
        window.addEventListener("cempDigitalInjected", this.startCempDigital, false)
      } else if (wdw.attachEvent) {
        wdw.attachEvent("on" + "cempDigitalInjected", this.startCempDigital)
      }
    }
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe()
    this.idleSubscription.unsubscribe()
    this.routerSubscription.unsubscribe()
    this.introspectSubscription.unsubscribe()
  }
}
