<main>
  <c1-privacy-banner
    class="maintenance-banner grv-margin--none"
    [alertType]="'GLOBAL'"
    [canClose]="false"
    [showMaintenance]="true"
    *ngIf="maintenance"
  >
  </c1-privacy-banner>
  <div class="grv-grid grv-padding__bottom--xxlarge" id="front-door">
    <div class="grv-row grv-color-swatch__color-panel grv-background--core-blue-50 grv-padding--large" id="first-row">
      <div class="grv-wrapper">
        <div class="grv-row">
          <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4 grv-color--white">
            <h1 #focusRef class="grv-text__heading--xlarge">
              {{ "FRONT_DOOR.HEADER" | translate }}
            </h1>
            <p class="grv-text__block grv-text__block--medium">
              {{ "FRONT_DOOR.SUBHEADING" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grv-wrapper">
      <div class="grv-row" id="second-row">
        <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4 grv-margin__top--normal data-use-text">
          <h2 class="grv-text--large grv-margin__bottom--medium-1 grv-margin__top--small-2">
            {{ "FRONT_DOOR.DATA_USE_SECTION.TITLE" | translate }}
          </h2>
        </div>
        <div class="grv-col--sm-4 grv-col--md-4 grv-col--lg-6 icon-block">
          <c1-privacy-icon
            [iconName]="'gauge'"
            [size]="'small'"
            class="grv-margin__right--medium use-text__block"
          ></c1-privacy-icon>
          <p class="use-text__block grv-text__block--medium">
            {{ "FRONT_DOOR.DATA_USE_SECTION.SERVICE" | translate }}
          </p>
        </div>
        <div class="grv-col--sm-4 grv-col--md-4 grv-col--lg-6 icon-block">
          <c1-privacy-icon [iconName]="'offers'" [size]="'small'" class="grv-margin__right--medium"></c1-privacy-icon>
          <p class="use-text__block grv-text__block--medium">
            {{ "FRONT_DOOR.DATA_USE_SECTION.ADVERTISE" | translate }}
          </p>
        </div>
        <div class="grv-col--sm-4 grv-col--md-4 grv-col--lg-6 icon-block">
          <c1-privacy-icon
            [iconName]="'securityApproval'"
            [size]="'small'"
            class="grv-margin__right--medium"
          ></c1-privacy-icon>
          <p class="use-text__block grv-text__block--medium">
            {{ "FRONT_DOOR.DATA_USE_SECTION.VERIFY" | translate }}
          </p>
        </div>
        <div class="grv-col--sm-4 grv-col--md-4 grv-col--lg-6 icon-block">
          <c1-privacy-icon [iconName]="'branch'" [size]="'small'" class="grv-margin__right--medium"></c1-privacy-icon>
          <p class="use-text__block grv-text__block--medium">
            {{ "FRONT_DOOR.DATA_USE_SECTION.COMPLY" | translate }}
          </p>
        </div>
        <span
          class="grv-col--sm-4 grv-text--normal grv-margin__bottom--large-1">
          {{"FRONT_DOOR.PRIVACY_POLICY" | translate}} <a role="button" tabindex="0" (click)="privacyPolicy()" class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none cursor">{{"FRONT_DOOR.PRIVACY_POLICY_LINK_TEXT" | translate}}</a> {{"FRONT_DOOR.LEARN_MORE" | translate}}
        </span>
      </div>
    </div>
    <div class="grv-row grv-color-swatch__color-panel grv-background--digital-gray-5" id="third-row">
      <div class="grv-wrapper">
        <div class="grv-row grv-margin__bottom--medium-1">
          <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4">
            <h2 class="grv-text--large-1 grv-weight--light grv-margin__bottom--medium-1">
              {{ "FRONT_DOOR.MANAGE_ACCT_INFO.TITLE" | translate }}
            </h2>
            <p class="grv-text--medium-1 grv-margin__bottom--none grv-margin__top--none">
              {{ "FRONT_DOOR.MANAGE_ACCT_INFO.SUBHEADER" | translate }}
            </p>
          </div>
          <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4">
            <p class="grv-text--normal-semibold grv-margin__bottom--none grv-margin__top--none">
              {{ "FRONT_DOOR.MANAGE_ACCT_INFO.DESCRIPTION" | translate }}
            </p>
          </div>
          <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4">
            <ul class="grv-list">
              <li class="grv-list__item" role="listitem">
                <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
                  <a
                    class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none cursor"
                    data-cy="personal-accounts-link"
                    role="button" tabindex="0"
                    (click)="clickPersonalAccounts()"
                  >
                    {{"RIGHT_TO_CORRECT.PERSONAL_ACCOUNT_LINK_TEXT" | translate}}
                  </a>
                </p>
              </li>
              <li class="grv-list__item" role="listitem">
                <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
                  <a
                    class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none cursor"
                    data-cy="business-accounts-link"
                    role="button" tabindex="0"
                    (click)="clickBusinessAccounts()"
                  >
                    {{"RIGHT_TO_CORRECT.BUSINESS_ACCOUNTS_LINK_TEXT" | translate}}
                  </a>
                </p>
              </li>
              <li class="grv-list__item" role="listitem">
                <p class="grv-text--normal small-text-on-mobile grv-margin__bottom--none grv-margin__top--none">
                  <a
                    class="grv-text--normal-semibold small-text-on-mobile canada-link text-decoration-none grv-color--interaction-blue-50 text-decoration-none cursor"
                    data-cy="commerciala-accounts-link"
                    role="button" tabindex="0"
                    (click)="clickCommercialAccounts()"
                  >
                    {{"RIGHT_TO_CORRECT.COMMERCIAL_ACCOUNTS_LINK_TEXT" | translate}}
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="grv-wrapper">
      <div class="grv-row" id="fourth-row">
        <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4 grv-margin__top--normal">
          <h2 class="grv-text__heading--large">
            {{ "FRONT_DOOR.DATA_MGMT_SECTION.TITLE" | translate }}
          </h2>
          <p class="grv-text--normal">
            {{ "FRONT_DOOR.DATA_MGMT_SECTION.DESCRIPTION" | translate }}
          </p>
        </div>
        <div class="grv-col--sm-4 grv-col--md-8 grv-col--lg-12 grv-margin__bottom--small">
          <c1-privacy-icon [iconName]="'download'" [size]="iconSize$ | async" class="grv-margin__right--normal">
          </c1-privacy-icon>
          <div class="grv-text__block">
            <p class="grv-text__heading--normal data-mgmt-text grv-padding__bottom--small-2">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.DOWNLOAD_DATA.TITLE" | translate }}
            </p>
            <p class="grv-text--normal data-mgmt-text data-mgmt-text-description grv-padding__bottom--small">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.DOWNLOAD_DATA.DESCRIPTION" | translate }}
            </p>
          </div>
        </div>
        <div class="grv-col--sm-4 grv-col--md-8 grv-col--lg-12 grv-margin__bottom--small">
          <c1-privacy-icon [iconName]="'makePlan'" [size]="iconSize$ | async" class="grv-margin__right--normal">
          </c1-privacy-icon>
          <div class="grv-text__block">
            <p class="grv-text__heading--normal data-mgmt-text grv-padding__bottom--small-2">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.DELETE_DATA.TITLE" | translate }}
            </p>
            <p class="grv-text--normal data-mgmt-text data-mgmt-text-description">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.DELETE_DATA.DESCRIPTION" | translate }}
            </p>
          </div>
        </div>
        <div class="grv-col--sm-4 grv-col--md-8 grv-col--lg-12 grv-margin__bottom--small">
          <c1-privacy-icon [iconName]="'goOnline'" [size]="iconSize$ | async" class="grv-margin__right--normal">
          </c1-privacy-icon>
          <div class="grv-text__block">
            <p class="grv-text__heading--normal data-mgmt-text grv-padding__bottom--small-2">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.CORRECT_DATA.TITLE" | translate }}
            </p>
            <p class="grv-text--normal data-mgmt-text data-mgmt-text-description">
              {{ "FRONT_DOOR.DATA_MGMT_SECTION.CORRECT_DATA.DESCRIPTION" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="grv-row grv-row grv-row--sm-center" id="fifth-row">
        <div class="grv-col--sm-3 grv-col--md-5 grv-col--lg-4" id="button-block">
          <a
            class="grv-button grv-button--action grv-margin__bottom--small"
            id="sign-in-button"
            data-cy="sign-in-button"
            role="button" tabindex="0"
            (click)="clickGetStarted()"
            >{{ "FRONT_DOOR.GET_STARTED" | translate }}</a
          >
          <span class="grv-text--normal"
            >{{ "FRONT_DOOR.PREVIOUS_REQUEST" | translate }}
            <a
              [routerLink]="signIn"
              data-cy="download-link"
              class="grv-text--normal-semibold ods-text__link grv-color--interaction-blue-50"
            >
              {{ "FRONT_DOOR.DOWNLOAD" | translate }}
            </a>
          </span>
        </div>
        <div class="grv-col--md-8 grv-text__block grv-padding__top--normal" id="cnda-uk-block">
          <p
            class="grv-text--normal small-text-on-mobile"
          >{{"FRONT_DOOR.ALT_CUSTOMER" | translate}}
            <b>{{"FRONT_DOOR.CNDA_HTML_TEXT" | translate}}</b>
            {{"FRONT_DOOR.ALT_CUSTOMER_2" | translate}}
            {{"FRONT_DOOR.CNDA" | translate}}
            <a (click)="caPrivacyPolicy()" role="button" tabindex="0" class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none cursor" >{{"FRONT_DOOR.CNDA_LINK_TEXT" | translate}}</a>.
          </p>
          <p class="grv-text--normal small-text-on-mobile">
            {{"FRONT_DOOR.ALT_CUSTOMER" | translate}}
            <b>{{"FRONT_DOOR.UK_HTML_TEXT" | translate}}</b>
            {{"FRONT_DOOR.ALT_CUSTOMER_2" | translate}}
            {{"FRONT_DOOR.UK" | translate}}
            <a (click)="ukPrivacyPolicy()" role="button" tabindex="0" class="grv-text--normal-semibold small-text-on-mobile canada-link grv-color--interaction-blue-50 text-decoration-none cursor" >{{"FRONT_DOOR.UK_LINK_TEXT" | translate}}</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
</main>
