export enum RouteName {
  CreateRequest = "create-request",
  CreateRequestConfirm = "create-request/confirm",
  CreateRequestSuccess = "create-request/success",
  DeleteRequest = "delete",
  DeleteRequestConfirm = "delete/confirm",
  DeleteRequestSuccess = "delete/success",
  FrontDoor = "",
  GovIdVerification = "sign-in/verification",
  VerificationError = "verification-error",
  MultipleSso = "another-request-in-progress",
  SessionExpired = "timeout",
  SignIn = "sign-in",
  Snag = "snag",
  Unavailable = "temporarily-unavailable",
  DataCategories = "data-categories",
  TransparencyReport = "transparency-report",
  TransparencyReportSpanish = "informe-de-transparencia",
  Manage = "manage",
  IntakeFormPage = "correct/create",
  IntakeFormConfirmationPage = "correct/confirm",
  IntakeFormSuccessPage = "correct/success",
  CorrectionLanding = "correct",
}
