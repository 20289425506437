import { Component, Input, OnInit } from "@angular/core"

@Component({
  selector: "c1-privacy-connex-placeholder",
  templateUrl: "./connex-placeholder.component.html",
  styleUrls: ["./connex-placeholder.component.scss"],
})
export class ConnexPlaceholderComponent implements OnInit {
  @Input() width: string = "100%"
  @Input() height: string = "16px"
  @Input() spacing: string = "8px"

  constructor() {}

  ngOnInit(): void {}
}
