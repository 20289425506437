import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'c1-privacy-request-table',
    templateUrl: './request-table.component.html',
    styleUrl: './request-table.component.scss'
})
export class RequestTableComponent {

  isMobile:boolean = false;

  ngOnInit() {
    if (window.screen.width <= 450) {
      this.isMobile = true;
    }
  }

  constructor(
    public ts: TranslateService)
  {}

}
