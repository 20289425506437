import { Injectable } from '@angular/core';
import { ApplicationEvent, PageViewEvent } from 'src/app/models/onetag-event-model';

declare global {
  interface Window {
    oneTag: {
      track: (name, data) => void;
    };
  }
}

export enum ONETAG_APPLICATION_EVENTS {
  attempt = 'application_submit_attempt',
  fail = 'application_submit_failure',
  complete = 'application_submit_complete',
  appStart = 'application_start',
  linkClick = 'link_click'
}

@Injectable({
  providedIn: "root",
})
export class OneTagService {

  constructor() { }

  trackApplicationEvent(event: ONETAG_APPLICATION_EVENTS, eventAction?: string) {
    if (!window.oneTag) {
      return;
    }

    const data = this.getApplicationEventParams(event);

    switch (event) {
      case ONETAG_APPLICATION_EVENTS.attempt:
        data.ga_event_action = 'submit attempt';
        data.ga_event_category = 'application';
        break;
      case ONETAG_APPLICATION_EVENTS.appStart:
        break;
      case ONETAG_APPLICATION_EVENTS.linkClick:
        const eventDetail = 'linkClick-' + eventAction;
        data.ui_interaction_action = 'click';
        data.ui_interaction_element_name = eventDetail;
        data.ui_interaction_element_type = 'button';
        break;
      case ONETAG_APPLICATION_EVENTS.complete:
        data.user_engagement_type = eventAction;
        break;
      case ONETAG_APPLICATION_EVENTS.fail:
        data.user_engagement_type = eventAction;
        break;
    }

    try {
      window.oneTag.track('link', data);
    } catch (e) { }
  }

  getApplicationEventParams(eventName: string): ApplicationEvent {
    return {
      event_name: eventName,
      product_name: 'ccpa_privacy_center',
      application_name: 'ccpa_privacy_center',
      lob_identifier: 'EDML',
      ga_event_label: 'ccpa_privacy_center'
    };
  }

  setPageView() {
    if (!window.oneTag) {
      return;
    }

    const data = this.getPageViewDimensions();

    try {
      window.oneTag.track('view', data);
    } catch (e) { }
  }

  getPageViewDimensions(): PageViewEvent {
    return {
      event_name: 'page_view',
      page_level_1: 'ccpa_privacy_portal',
      page_level_2: 'privacy_center',
      app_id: 'ccpa_privacy_portal',
      site_country: 'us',
      page_language: 'english',
      lob_identifier: 'EDML',
      visitor_type: 'existing',
    };
  }
}
