import { Component, ViewChild } from "@angular/core"
import { DialogComponent } from "../dialog/dialog.component"
import { DialogWrapper } from "../dialog/dialog-wrapper"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"

@Component({
  selector: "c1-privacy-call-center-dialog",
  templateUrl: "./call-center-dialog.component.html",
  styleUrls: ["./call-center-dialog.component.scss"],
})
export class CallCenterDialogComponent implements DialogWrapper {
  @ViewChild(DialogComponent)
  dialog: DialogComponent

  dialogContent: SubheaderContent = {
    icon: "contact",
    headerText: "CALL_CENTER_DIALOG.TITLE",
  }

  open() {
    this.dialog.disableClose = false
    this.dialog.openDialog()
  }
}
