import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core"
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms"
import { IntrospectService } from "src/app/services/intropsect/introspect.service"

import { DialogWrapper } from "../dialog/dialog-wrapper"
import { DialogComponent } from "../dialog/dialog.component"
import { SubheaderContent } from "../subheader/SubheaderContent"

@Component({
  selector: "c1-privacy-email-dialog",
  templateUrl: "./email-dialog.component.html",
  styleUrls: ["./email-dialog.component.scss"],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailDialogComponent implements OnInit, DialogWrapper {
  @Input()
  emailAddress: string

  @Output() readonly updatedEmailAddress: EventEmitter<any> = new EventEmitter()

  @ViewChild(DialogComponent) dialog: DialogComponent

  emailFormGroup: UntypedFormGroup

  disableClose: boolean

  content: { [key: string]: string } = {
    firstEmailLabel: "EMAIL_DIALOG.FIRST_EMAIL_LABEL",
    secondEmailLabel: "EMAIL_DIALOG.SECOND_EMAIL_LABEL",
    saveButton: "EMAIL_DIALOG.SAVE_BUTTON",
    nonMatchingEmails: "EMAIL_DIALOG.NON_MATCHING_EMAILS",
    invalidEmail: "EMAIL_DIALOG.INVALID_EMAIL",
    additionalInfo: "EMAIL_DIALOG.ADDITIONAL_INFO_NON_CUSTOMER",
    placeholderText: "EMAIL_DIALOG.PLACEHOLDER_TEXT",
  }

  subheaderContent: SubheaderContent = {
    icon: "mailbox",
    headerText: "EMAIL_DIALOG.HEADER",
  }

  constructor(private formBuilder: UntypedFormBuilder, private introspectService: IntrospectService) {}

  ngOnInit(): void {
    if (this.introspectService.getActorType() == "customer") {
      this.content.additionalInfo = "EMAIL_DIALOG.ADDITIONAL_INFO_CUSTOMER"
    } else {
      this.content.additionalInfo = "EMAIL_DIALOG.ADDITIONAL_INFO_NON_CUSTOMER"
    }

    this.emailFormGroup = this.formBuilder.group({
      emailGroup: this.formBuilder.group(
        {
          initialEmail: ["", [Validators.required, Validators.email]],
          confirmEmail: ["", [Validators.required, Validators.email]],
        },
        { validator: this.emailCompareValidator }
      ),
    })
  }

  public showUnmatchedEmail(): boolean {
    const emailGroup: AbstractControl = this.emailFormGroup.get("emailGroup")
    const confirmEmail: AbstractControl = emailGroup.get("confirmEmail")
    return !!emailGroup.errors && !!emailGroup.errors.emailCompare && confirmEmail.dirty
  }

  public showInvalidEmail(): boolean {
    const emailGroup = this.emailFormGroup.get("emailGroup")
    const initialEmail = emailGroup.get("initialEmail")
    return initialEmail.invalid && initialEmail.dirty
  }

  public showConfirmEmailErrorMessage(): string {
    return this.showUnmatchedEmail() ? this.content.nonMatchingEmails : ""
  }

  public updateEmail(): void {
    this.updatedEmailAddress.emit(this.emailFormGroup.value.emailGroup.confirmEmail)
    this.dialog.disableClose = this.disableClose
    this.dialog.closeDialog()
  }

  public open() {
    this.dialog.disableClose = this.disableClose
    const firstInputElementIndex = this.disableClose ? 0 : 1
    this.dialog.openDialog()
    this.dialog.setFocusOnElement(firstInputElementIndex)
  }

  private emailCompareValidator(control: UntypedFormGroup): ValidationErrors | null {
    const initialEmail: AbstractControl = control.get("initialEmail")
    const emailConfirm: AbstractControl = control.get("confirmEmail")
    return initialEmail && emailConfirm && initialEmail.value === emailConfirm.value ? null : { emailCompare: true }
  }
}
