<main class="c1-privacy-session-expired grv-padding__bottom--xxlarge">
  <c1-privacy-subheader [subheaderContent]="subheaderContent"></c1-privacy-subheader>
  <p class="grv-text--medium grv-text--light info-text margin-fix">
    {{ "SESSION_EXPIRED.SUBHEADING_1" | translate }}
  </p>
  <p class="grv-text--medium grv-text--light">
    {{ "SESSION_EXPIRED.SUBHEADING_2" | translate }}
  </p>
  <a [routerLink]="signIn" class="grv-button--progressive submit-button margin-fix" data-cy="front-door-button">
    {{ "SESSION_EXPIRED.CONTINUE_BUTTON" | translate }}
  </a>
  <div class="grv-margin__top--medium-1">
    <a class="grv-text--medium grv-color--interaction-blue-50" [href]="logoutLandingUrl" data-cy="restart-flow-button">
    {{ "SESSION_EXPIRED.RESTART" | translate }}
    </a>
  </div>
</main>
