<c1-privacy-dialog [closeDialogHelper]="closeDialogHelper">
  <c1-privacy-subheader [subheaderContent]="subheaderContent"> </c1-privacy-subheader>
  <p class="grv-text--small text-center">{{ "SESSION_DIALOG.SESSION_EXPIRE" | translate }} <b style="text-align: center;"> {{ "SESSION_DIALOG.COUNTDOWN" | translate: { countdownMin: countdownTimerMinutes, countdownSec: countdownTimerSeconds } }}</b></p>
  <p class="grv-text--small text-center">
    {{ "SESSION_DIALOG.SESSION_TEXT" | translate }} "{{ "SESSION_DIALOG.SESSION_TEXT_REFRESH" | translate }}"
  </p>
  <div class="grv-dialog__buttons">
    <button
      class="grv-button grv-button--text"
      [innerHTML]="'SESSION_DIALOG.SIGN_OUT_TEXT' | translate"
      (click)="signOut()"
      (keyup)="signOut()"
    ></button>
    <button
      class="grv-button"
      [innerHTML]="'SESSION_DIALOG.REFRESH_TEXT' | translate"
      (click)="closeDialog()"
      (keyup)="closeDialog()"
    ></button>
  </div>
</c1-privacy-dialog>
