import { Router } from "@angular/router"
import { Injectable } from "@angular/core"
import { RouteName } from "src/app/route-name"
import { IntakeFormService } from "src/app/services/intake-form/intake-form.service"

@Injectable({ providedIn: "root" })
export class IntakeSuccessGuard  {
  constructor(private intakeFormService: IntakeFormService, private router: Router) {}

  canActivate() {
    if (this.intakeFormService.requestSuccessful) {
      return true
    } else {
      this.router.navigate([RouteName.IntakeFormPage])
    }
  }
}
