import {Component, Inject, Input, OnInit} from "@angular/core"
import { Location } from "@angular/common"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import {APP_CONFIG, AppConfig} from "../../../app.config";
import {Router} from "@angular/router";
import {ONETAG_APPLICATION_EVENTS, OneTagService} from "../../services/one-tag/one-tag.service";
import {RouteName} from "../../route-name";
@Component({
  selector: "c1-privacy-page-unavailable",
  templateUrl: "./unavailable.component.html",
  styleUrls: ["./unavailable.component.scss"],
})

export class UnavailableComponent implements OnInit {
  @Input() type: string
  config: AppConfig

  constructor(private location: Location, @Inject(APP_CONFIG) config: AppConfig, private router: Router, private oneTagService: OneTagService) {
    this.config = config
    this.type = config.maintenanceType
  }

  ngOnInit() {
    this.oneTagService.setPageView();
  }

  public goToFrontDoor(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'maintenanceNavigateToFrontDoor');
    this.router.navigate([RouteName.FrontDoor])
  }

  subheaderContent: SubheaderContent = {
    icon: "error",
    headerText: "MAINTENANCE.HEADER",
  }
  subheaderContent1: SubheaderContent = {
    icon: "caution",
    headerText: "UNAVAILABLE.HEADER"
  }
  subheaderContent2: SubheaderContent = {
    icon: "caution",
    headerText: "UNAVAILABLE.HEADER_2"
  }
  subheaderContent3: SubheaderContent = {
    icon: "caution",
    headerText: "UNAVAILABLE.HEADER_3"
  }
}
