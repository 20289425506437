import { Router, ActivatedRouteSnapshot } from "@angular/router"
import { Injectable } from "@angular/core"

import { CategoryService } from "src/app/services/category/category.service"
import { RouteName } from "src/app/route-name"

@Injectable({ providedIn: "root" })
export class ConfirmDeleteGuard  {
  constructor(private categoryService: CategoryService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const shouldNavigateToConfirmPage: boolean = !!(
      this.categoryService.selectedCategories && this.categoryService.selectedCategories.length
    )

    if (shouldNavigateToConfirmPage) {
      return true
    } else {
      this.router.navigate([RouteName.DeleteRequest], { queryParamsHandling: "merge" })
      return false
    }
  }
}
