import { Component, ViewEncapsulation, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { RouteName } from "src/app/route-name"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"

export enum FlowType {
  TO_DELETE = "TO_DELETE",
  TO_DOWNLOAD = "TO_DOWNLOAD",
  TO_CORRECT = "TO_CORRECT",
}

@Component({
  selector: "c1-privacy-request-received",
  templateUrl: "./request-received.component.html",
  styleUrls: ["./request-received.component.scss"],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class RequestReceivedComponent implements OnInit {
  subheaderContent: SubheaderContent = {
    icon: "success",
    headerText: "REQUEST_RECEIVED.HEADER",
  }

  content = {
    requestIdText: "REQUEST_RECEIVED.REQUEST_ID_TEXT",
    requestText: "",
    requestDeleteText: "REQUEST_RECEIVED.DELETE_DESCRIPTION",
    anotherRequest: "",
    linkText: "",
  }

  privacyDataRequestId: string
  descriptionText: string
  isDeleteFlow: boolean
  flowType: FlowType
  routerState: any | undefined
  redirectLink: string

  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()

  /**
   * @returns this.router.getCurrentNavigation().extras.state
   */
  getRouterState() {
    return (
      this.router &&
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state &&
      Object.keys(this.router.getCurrentNavigation().extras.state).length > 0 &&
      this.router.getCurrentNavigation().extras.state
    )
  }

  setPrivacyId(): void {
    const state = this.getRouterState()
    this.privacyDataRequestId = state && state.requestId
  }

  setFlowType(): void {
    const state = this.getRouterState()
    if (state && state.isDeleteFlow) {
      this.flowType = FlowType.TO_DELETE
      this.isDeleteFlow = true
    } else if (state && state.isCorrectFlow) {
      this.flowType = FlowType.TO_CORRECT
    } else {
      this.flowType = FlowType.TO_DOWNLOAD
    }
  }

  setAnotherRequestText(): void {
    if (this.flowType) {
      this.content.anotherRequest = `REQUEST_RECEIVED.${this.flowType}.DESCRIPTION`
      this.content.linkText = `REQUEST_RECEIVED.${this.flowType}.LINK`
      this.content.requestText = `REQUEST_RECEIVED.${this.flowType}.CONFIRMATION_TEXT`
    }
  }

  redirect(): void {
    if (
      this.flowType === FlowType.TO_DELETE ||
      this.flowType === FlowType.TO_DOWNLOAD ||
      this.flowType === FlowType.TO_CORRECT
    ) {
      this.router.navigate([RouteName.Manage])
    } else {
      if (this.maintenance) {
        this.router.navigate([RouteName.Unavailable])
      } else {
        this.router.navigate([RouteName.Snag])
      }
    }
  }

  constructor(private maintenanceBannerService: MaintenanceBannerService, private router: Router) {
    this.setPrivacyId()
    this.setFlowType()
    this.setAnotherRequestText()
  }

  ngOnInit() {}
}
