<hr aria-hidden="true" />
<div *ngIf="hasDataCategories.length > 0" class="data" id="assets-data-true">
  <c1-privacy-asset-categories-list
    [assetCategories]="hasDataCategories"
    [hasData]="true"
  ></c1-privacy-asset-categories-list>
</div>

<div *ngIf="hasNoDataCategories.length > 0" class="data">
  <c1-privacy-asset-categories-list
    [assetCategories]="hasNoDataCategories"
    [hasData]="false"
  ></c1-privacy-asset-categories-list>
</div>
