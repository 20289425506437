<main class="c1-privacy-request-received grv-padding__bottom--xxlarge">
  <c1-privacy-subheader [subheaderContent]="subheaderContent"> </c1-privacy-subheader>
  <div [c1PrivacyLoading]="privacyDataRequestId"></div>
  <div class="request-id-text-container">
    <div class="request-content">
      <p *ngIf="privacyDataRequestId" class="grv-text--normal grv-text--semibold">
        {{ content.requestIdText | translate: { privacyDataRequestId: privacyDataRequestId } }}
      </p>
      <p class="grv-text--normal received">
        {{ content.requestText | translate }}
      </p>
      <div *ngIf="isDeleteFlow" class="grv-text--normal grv-margin__top--normal">
        {{ content.requestDeleteText | translate }}
      </div>
    </div>
  </div>
  <div class="redirect-button-container grv-text--normal grv-margin__top--xlarge">
    <button
      class="grv-button grv-button--text-directional-right grv-text--normal-semibold"
      (click)="redirect()"
      (keyup.enter)="redirect()"
      data-cy="redirect-link"
    >
      {{ content.linkText | translate }}
    </button>
  </div>
</main>
