import { Component, Input, OnInit } from "@angular/core"
import { AssetCategory } from "src/app/models/assetCategory"

@Component({
  selector: "c1-privacy-asset-categories",
  templateUrl: "./asset-categories.component.html",
  styleUrls: ["./asset-categories.component.scss"],
})
export class AssetCategoriesComponent implements OnInit {
  @Input()
  assetCategories: AssetCategory[]
  hasDataCategories: AssetCategory[] = []
  hasNoDataCategories: AssetCategory[] = []
  constructor() {}

  ngOnInit() {
    this.assetCategories.forEach((category) =>
      category.hasData ? this.hasDataCategories.push(category) : this.hasNoDataCategories.push(category)
    )
  }
}
