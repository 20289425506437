import { Router } from "@angular/router"
import { Injectable } from "@angular/core"
import { CategoryService } from "src/app/services/category/category.service"
import { RouteName } from "src/app/route-name"
import { IntakeFormService } from "src/app/services/intake-form/intake-form.service"

@Injectable({ providedIn: "root" })
export class IntakeConfirmGaurd  {
  constructor(private intakeFormService: IntakeFormService, private router: Router) {}

  canActivate(): boolean {
    if (this.intakeFormService.intakeFormData) {
      return true
    } else {
      this.router.navigate([RouteName.IntakeFormPage], { queryParamsHandling: "merge" })
      return false
    }
  }
}
