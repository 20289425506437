import { Component, OnInit } from "@angular/core"
import { ccpaDisclosure, privacyProlicyCaSpanish } from "src/app/common"
import { HTMLConfig } from "src/app/interfaces/htmlConfig.interface"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { newRelicEvent } from "src/app/common"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"

import { TranslateService } from "@ngx-translate/core"
import { Renderer2 } from "@angular/core"

@Component({
  selector: "c1-privacy-transparency-report-spanish",
  templateUrl: "./transparency-report-spanish.component.html",
  styleUrls: ["./transparency-report-spanish.component.scss"],
})
export class TransparencyReportSpanishComponent implements OnInit {

  htmlConfig: HTMLConfig = {
    ccpa_disclosure_spanish: {
      tag: "a",
      classes: "grv-text--normal-normal small-text-on-mobile grv-color--core-blue-40 no-underline",
      attributes: [
        {
          key: "href",
          value: privacyProlicyCaSpanish,
        },
        {
          key: "data-cy",
          value: "ccpa-disclosure-link",
        },
      ],
      textKey: "TRANSPARENCY_REPORT_SPANISH.CCPA_LINK_TEXT",
    },
  }

  content: { [key: string]: string } = {
    title: "TRANSPARENCY_REPORT_SPANISH.HEADER",
    subtitle: "TRANSPARENCY_REPORT_SPANISH.SUBHEADING",
    description: "TRANSPARENCY_REPORT_SPANISH.PDF",
    link: "https://ecm.capitalone.com/WCM/privacy/informe-de-transparencia-para-peticiones-hechas-en-el-2023.pdf"
  }

  coverageTableEn: [string[], string[], string[]] = [
    [
      "COVERAGE_TABLE_SPANISH.COVERAGE.HEADER",
      "COVERAGE_TABLE_SPANISH.DOWNLOAD_TURNAROUND.HEADER",
      "COVERAGE_TABLE_SPANISH.DELETE_TURNAROUND.HEADER",
    ],
    [
      "COVERAGE_TABLE_SPANISH.COVERAGE.CALIFORNIA",
      "COVERAGE_TABLE_SPANISH.DOWNLOAD_TURNAROUND.CALIFORNIA",
      "COVERAGE_TABLE_SPANISH.DELETE_TURNAROUND.CALIFORNIA",
    ],
    [
      "COVERAGE_TABLE_SPANISH.COVERAGE.ALL_OTHER_STATES",
      "COVERAGE_TABLE_SPANISH.DOWNLOAD_TURNAROUND.ALL_OTHER_STATES",
      "COVERAGE_TABLE_SPANISH.DELETE_TURNAROUND.ALL_OTHER_STATES",
    ],
  ]

  coverageDescription: string[] = [
    "COVERAGE_TABLE_SPANISH.DESCRIPTION1",
    "COVERAGE_TABLE_SPANISH.DESCRIPTION2",
    "COVERAGE_TABLE_SPANISH.DESCRIPTION3",
  ]

  constructor(public ts: TranslateService, private renderer: Renderer2, private newRelicService: NewRelicService) {

    this.newRelicService.logCustomError("User visited Spanish version of transparency report page.", {
      event: newRelicEvent.transparencyReportSpanish,
      logLevel: ErrorLoggingLevel.Info,
    })
  }

  isMobile:boolean = false;

  ngOnInit() {
    if (window.screen.width <= 450) {
      this.isMobile = true;
    }
    console.log(this.isMobile)
  }
}
