import { Component, OnInit, Input, ContentChildren, TemplateRef } from "@angular/core"
import { SubheaderContent } from "../subheader/SubheaderContent"

@Component({
  selector: "c1-privacy-subheader-with-card",
  templateUrl: "./subheader-with-card.component.html",
  styleUrls: ["./subheader-with-card.component.scss"],
})
export class SubheaderWithCardComponent implements OnInit {
  @ContentChildren("card_content") cardRefs: TemplateRef<any>[]

  @Input()
  subheaderContent: SubheaderContent

  constructor() {}

  ngOnInit() {}
}
