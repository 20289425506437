import {Inject, Injectable} from "@angular/core"
import {add, format, isAfter, isBefore, sub} from "date-fns"
import {APP_CONFIG, AppConfig} from "src/app.config"
import {MaintenanceTypes} from "../../enums/maintenance-types";

@Injectable({
  providedIn: "root",
})
export class MaintenanceBannerService {
  private startTime: Date
  private previewStartTime: Date
  private previewDuration: Duration
  private duration: Duration
  private endTime: Date
  private type: MaintenanceTypes

  config: AppConfig

  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    this.config = config
    this.startTime = new Date(0)
    this.startTime.setUTCSeconds(config.maintenanceStart)
    this.duration = { seconds: config.maintenanceDuration }
    this.endTime = add(this.startTime, this.duration)
    this.previewDuration = {seconds : config.maintenancePreviewDuration}
    this.previewStartTime = sub(this.startTime, this.previewDuration)
    this.type = config.maintenanceType as MaintenanceTypes;
  }

  public getStartTime(): Date {
    return this.startTime
  }

  public getType(): MaintenanceTypes {
    return this.type
  }

  public getEndTime(): Date {
    return this.endTime
  }

  public getMaintenanceDurationString(): string {
    if (this.startTime.toDateString() === this.endTime.toDateString()) {
      const startDay = format(this.startTime, "EEEE MMMM do")
      const startTimeString = format(this.startTime, "h:mm aaaa—")
      const endString = format(this.endTime, "h:mm aaaa ")
      return startDay.concat(", from ").concat(startTimeString).concat(endString).concat("ET.")
    } else {
      const startDay = format(this.startTime, "EEEE MMMM do, h:mm aaaa—")
      const endDay = format(this.endTime, "EEEE MMMM do, h:mm aaaa ")
      return "from ".concat(startDay).concat(endDay).concat("ET.")
    }
  }

  public bannerIsValid(): boolean { // check this for routing
    switch(this.type) {
      case MaintenanceTypes.Maintenance: {
        return isBefore(Date.now(), this.endTime) && isAfter(Date.now(), this.startTime)
      }
      case MaintenanceTypes.Feature: {
        return true
      }
      case MaintenanceTypes.Partial: {
        return true
      }
      case MaintenanceTypes.Full: {
        return true
      }
    }
  }

  public bannerIsPreviewed(): boolean { // check this for displaying
    switch(this.type) {
      case MaintenanceTypes.Maintenance: {
        return isBefore(Date.now(), this.endTime) && isAfter(Date.now(), this.previewStartTime)
      }
      case MaintenanceTypes.Feature: {
        return true
      }
      case MaintenanceTypes.Partial: {
        return true
      }
      case MaintenanceTypes.Full: {
        return true
      }
    }
  }
}
