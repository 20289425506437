<c1-privacy-banner
  class="maintenance-banner grv-margin--none"
  [alertType]="'GLOBAL'"
  [showMaintenance]="true"
  [canClose]="false"
  *ngIf="maintenance"
>
</c1-privacy-banner>
<main class="c1-privacy-page-not-found grv-padding__bottom--xxlarge">
  <c1-privacy-subheader class="grv-margin__top--large" [subheaderContent]="subheaderContent"></c1-privacy-subheader>
  <!-- TODO [Keenan]: The way the text wraps looks a bit odd on mobile screens. Find a fix. -->
  <p class="grv-text--medium grv-text--light info-text margin-fix">
    {{ "PAGE_NOT_FOUND.INFO_1" | translate }}
    <br />
    {{ "PAGE_NOT_FOUND.INFO_2" | translate }}
  </p>
  <div class="grv-row">
    <a
      href="javascript:void(0)"
      (click)="goBackToPreviousRoute()"
      class="grv-button--progressive back-button margin-fix"
      data-cy="back-button"
      tabindex="0"
      (keyup.enter)="goBackToPreviousRoute()"
      (keydown.space)="goBackToPreviousRoute()"
    >
      {{ "PAGE_NOT_FOUND.BACK_BUTTON" | translate }}
    </a>
  </div>
</main>
