import { Pipe, PipeTransform } from "@angular/core"
import * as moment from "moment-timezone"

@Pipe({
  name: "timestamp",
})
export class TimestampPipe implements PipeTransform {
  timezone = "America/New_York"

  transform(value: any, showTime = true, fullMonth = false): any {
    if (value && typeof value === "string") {
      value = moment.utc(value).tz(this.timezone)
      if (showTime && fullMonth) {
        return value.format("MMMM D[,] YYYY[, at] h:mm a [ET]").replace("am", "a.m.").replace("pm", "p.m.")
      } else if (showTime) {
        return value.format("MMM D[,] YYYY[, at] h:mm a [ET]").replace("am", "a.m.").replace("pm", "p.m.")
      } else if (fullMonth) {
        return value.format("MMMM D[,] YYYY")
      } else {
        return value.format("MMM D[,] YYYY")
      }
    }
    return ""
  }
}
