import { Injectable } from "@angular/core"
import features from "../../../assets/features/feature-flag"

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  public hasFlags(flag: string): boolean {
    return features[flag]
  }
}
