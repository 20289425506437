import { Router } from "@angular/router"
import { Injectable } from "@angular/core"
import { CategoryService } from "src/app/services/category/category.service"
import { RouteName } from "src/app/route-name"

@Injectable({ providedIn: "root" })
export class DeleteSuccessGuard  {
  constructor(private categoryService: CategoryService, private router: Router) {}

  canActivate() {
    if (this.categoryService.requestSuccessful) {
      return true
    } else {
      this.router.navigate([RouteName.DeleteRequest])
    }
  }
}
