import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from "@angular/core"
import { Router } from "@angular/router"
import { newRelicEvent } from "src/app/common"
import { IntakeFormComponent } from "src/app/components/intake-form/intake-form.component"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { HTMLConfig } from "src/app/interfaces/htmlConfig.interface"
import { RouteName } from "src/app/route-name"
import { IntakeFormService } from "src/app/services/intake-form/intake-form.service"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"

@Component({
  selector: "c1-privacy-intake-form-page",
  templateUrl: "./intake-form-page.component.html",
  styleUrls: ["./intake-form-page.component.scss"],
})
export class IntakeFormPageComponent implements OnInit, AfterViewInit {
  @ViewChildren("form") intakeForms: QueryList<IntakeFormComponent>
  corrections: any[] = [{ isValid: false, hasDirtyAndInvalidInputs: false }]

  subheaderContent: SubheaderContent = {
    icon: "correction",
    headerText: "INTAKE_FORM.HEADER",
    subheaderText: "INTAKE_FORM.SUBHEADER",
  }

  htmlConfig: HTMLConfig = {
    delete_request: {
      tag: "a",
      classes: "grv-text--normal-semibold small-text-on-mobile canada-link grv-color--core-blue-40",
      attributes: [
        {
          key: "routerLink",
          value: RouteName.DeleteRequest,
        },
        {
          key: "data-cy",
          value: "delete-request",
        },
      ],
      textKey: "INTAKE_FORM.DELETE_DATA_LINK",
    },
  }

  constructor(
    public intakeFormService: IntakeFormService,
    public router: Router,
    private newRelicService: NewRelicService,
    private oneTagService: OneTagService
  ) {}

  ngOnInit(): void {
    this.oneTagService.setPageView();
    this.loadCachedFormData()
    this.newRelicService.logCustomError("User navigated to correction form from challenge app step-up", {
      event: newRelicEvent.correctionRequest,
      logLevel: ErrorLoggingLevel.Info,
    })
  }

  ngAfterViewInit(): void {
    this.setUpCachedFormData()
  }

  loadCachedFormData() {
    if (!this.intakeFormService.intakeFormData) return
    this.corrections = []
    this.intakeFormService.intakeFormData.forEach((d) => this.addCorrection())
  }

  setUpCachedFormData() {
    for (let i = 0; i < this.corrections.length; i++) {
      this.intakeForms.get(i).setFormData(this.intakeFormService.intakeFormData[i])
    }
  }

  isFormValid(): boolean {
    let valid = true
    this.corrections.forEach((obj: any) => {
      if(!obj.isValid){
        valid = false
      }
    })
    return valid
  }

  isFormDirty(): boolean {
    let dirty = false
    this.corrections.forEach((obj: any) => {
      if (obj.hasDirtyAndInvalidInputs) dirty = true
    })
    return dirty
  }

  onFormChanged(data) {
    this.corrections[data.i].isValid = data.$event.isValid
    this.corrections[data.i].hasDirtyAndInvalidInputs = data.$event.hasDirtyAndInvalidInputs
  }

  cancel() {
    this.router.navigate([RouteName.CorrectionLanding])
  }

  submit() {
    if (!this.isFormValid()) return false

    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionIntakeSubmit');
    this.intakeFormService.intakeFormData = []

    this.intakeForms.forEach((form) =>
      this.intakeFormService.intakeFormData.push({
        fileName: form.getValue("fileName"),
        field: form.getValue("field"),
        incorrectValue: form.getValue("incorrectValue"),
        correctValue: form.getValue("correctValue"),
        explanation: form.getValue("explanation"),
      })
    )

    this.router.navigate([RouteName.IntakeFormConfirmationPage], { queryParamsHandling: "merge" })
  }

  addCorrection() {
    if (this.corrections.length < 5) this.corrections.push({ isValid: false, hasDirtyAndInvalidInputs: false })
  }

  onRemoveCorrection(index) {
    this.corrections.splice(index, 1)
  }

  navigateToDelete() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionIntakeNavigateToDelete');
    this.router.navigate([RouteName.DeleteRequest], { queryParamsHandling: "merge" })
  }

}
