import { Component, ElementRef, ViewChild, AfterViewInit } from "@angular/core"

enum KeyCode {
  ESCAPE = 27,
}

@Component({
  selector: "c1-privacy-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements AfterViewInit {

  @ViewChild('footerAccordionButton') footerAccordionButton: ElementRef;
  @ViewChild('footerAccordionSection') footerAccordionSection: ElementRef;

  content = {
    labels: {
      footnotesLabel: "FOOTER.LABELS.FOOTNOTES",
      fdicTextLabel: "FOOTER.LABELS.FDIC_TEXT",
      fdicTextButtonLabel: "FOOTER.LINK_NAME.FDIC_TEXT_BUTTON",
      copyrightLabel:"FOOTER.LABELS.COPYRIGHT",
      privacyButtonLabel:"FOOTER.LINK_NAME.PRIVACY",
      securityButtonLabel:"FOOTER.LINK_NAME.SECURITY",
      adChoicesButtonLabel:"FOOTER.LINK_NAME.AD_CHOICES",
      termsAndConditionButtonLabel:"FOOTER.LINK_NAME.TERMS_AND_CONDITIONS"
    },
    links: {
      fdicCoverage: "https://www.capitalone.com/bank/fdic/",
      privacy:"https://www.capitalone.com/privacy/",
      security:"https://www.capitalone.com/digital/identity-protection/",
      adChoices:"https://www.capitalone.com/privacy/online-privacy-policy#collecting-using-sharing-information-accordion-button-5",
      termsAndCondition:"https://www.capitalone.com/digital/terms-conditions/",
      fdicHome:"https://www.fdic.gov/"
    },
    disclaimer: {
      investmentAndInsurance: "FOOTER.DISCLAIMER.INVESTMENT_AND_INSURANCE",
      bankingAndLending: "FOOTER.DISCLAIMER.BANKING_AND_LENDING",
      investmentProducts: "FOOTER.DISCLAIMER.INVESTMENT_PRODUCTS",
      finra: "FINRA",
      sipc: "SIPC",
      advisoryServices: "FOOTER.DISCLAIMER.ADVISORY_SERVICES",
      subsidiaries: "FOOTER.DISCLAIMER.SUBSIDIARIES",
    },
    icons: {
      fdicIcon: "../../assets/icons/member-fdic.svg",
      housingLendingIcon: "../../assets/icons/equal-housing-opportunity.svg",
    },
  }

  constructor() {}

  ngAfterViewInit(): void {
      this.toogleFootNote();
  }

  toogleFootNote() {
    let footnotesButton = this.footerAccordionButton.nativeElement
    let buttonClasses = footnotesButton.classList;
    let menu = this.footerAccordionSection.nativeElement
    let menuClasses = menu.classList;
    let menuExpanded = !menuClasses.contains('hide-contents');
    if (menuExpanded) {
      buttonClasses.remove('site-footer__footNotes-title--active');
      buttonClasses.add('site-footer__footNotes-title--inactive');
      footnotesButton.setAttribute('aria-expanded', 'false');
      menuClasses.remove('grv-accordion__section--active');
      menuClasses.add('hide-contents');
      menuClasses.add('grv-accordion__section--inactive');
      menu.setAttribute('aria-hidden', 'true');
    } else {
      buttonClasses.remove('site-footer__footNotes-title--inactive');
      buttonClasses.add('site-footer__footNotes-title--active');
      footnotesButton.setAttribute('aria-expanded', 'true');
      menuClasses.remove('grv-accordion__section--inactive');
      menuClasses.remove('hide-contents');
      menuClasses.add('grv-accordion__section--active');
      menu.setAttribute('aria-hidden', 'false');
    }
  }

}
