import { Component, Input } from "@angular/core"

@Component({
  selector: "c1-privacy-title-banner",
  templateUrl: "./title-banner.component.html",
  styleUrls: ["./title-banner.component.scss"],
})
export class TitleBannerComponent {
  @Input() title: string
  @Input() subtitle: string
  @Input() description: string
  @Input() link: string
}
