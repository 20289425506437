<main>
  <div class="grv-grid grv-padding__bottom--xxlarge">
    <div class="grv-row grv-color-swatch__color-panel grv-background--core-blue-50 grv-padding--large" id="first-row">
      <div class="section-wrapper">
        <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4 grv-color--white">
          <h1 #focusRef class="grv-text__heading--xlarge">
            {{ "DATA_CATEGORIES.HEADER" | translate }}
          </h1>
          <p class="grv-text__block grv-text__block--medium">
            {{ "DATA_CATEGORIES.SUBHEADING" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="grv-row" id="second-row">
      <div class="section-wrapper">
        <div class="grv-col--lg-12 grv-col--md-8 grv-col--sm-4">
          <p class="grv-text--normal grv-color--digital-gray-120 grv-margin__top--normal grv-margin__bottom--medium">
            {{ "DATA_CATEGORIES.CATEGORIES_TEXT" | translate }}
          </p>
          <div class="grv-margin__bottom--tiny" id="expandButton">
            <a
              href="javascript:void(0)"
              #expandCollapseAllBtn
              [attr.aria-expanded]="allExpanded"
              class="grv-text--normal grv-text--semibold grv-color--core-blue-40"
              id="expandCollapseAllBtn"
              (click)="expandAll()"
              (keyup.enter)="expandAll()"
              (keydown.enter)="expandAll()"
              data-cy="expand-all-button"
            >
              {{ expandCollapseBtnText | translate }}</a
            >
          </div>

          <hr aria-hidden="true" class="grv-divider--small grv-divider--dark divider grv-margin__top--tiny" />

          <div class="grv-accordion">
            <div class="grv-accordion__item" *ngFor="let accordionItem of accordionItems; let i = index">
              <button
                #accordionButton
                class="grv-accordion__title grv-color--core-blue-40 accordion-button"
                id="{{ 'accordion-button' + i }}"
                [attr.data-cy]="'accordion-button' + i"
                [attr.aria-controls]="'accordion-region' + i"
                aria-expanded="false"
                (click)="accordionClick(accordionButton, accordionSection)"
                (keyup)="accordionClick(accordionButton, accordionSection)"
              >
                {{ accordionItem.title | translate }}
              </button>
              <div
                #accordionSection
                class="grv-accordion__section"
                role="region"
                id="{{ 'accordion-region' + i }}"
                [attr.aria-labelledby]="'accordion-button' + i"
                aria-hidden="true"
              >
                <div class="grv-accordion__content">
                  <span class="grv-text--normal">
                    {{ accordionItem.content | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr aria-hidden="true" class="grv-divider--small grv-divider--dark divider" />
          <span
            class="grv-col--sm-4 grv-text--normal grv-margin__top--medium"
            c1PrivacyTranslateWithHtml
            [translateKey]="'DATA_CATEGORIES.CCPA_DISCLOSURE'"
            [htmlConfig]="htmlConfig"
          >
          </span>
          <div class="short-divider"></div>
          <p class="grv-text--medium grv-text--light options-text">
            {{ "DATA_CATEGORIES.OPTIONS_AND_PROTECTIONS_SECTION.OPTIONS_PROTECTIONS" | translate }}
          </p>
        </div>
        <div class="grv-row icon-section">
          <div
            class="grv-col--sm-3 grv-col--md-2 grv-col--lg-3 icon-block"
            *ngFor="let iconItem of iconItems; let i = index"
            id="{{ iconItem.iconName }}"
          >
            <c1-privacy-icon [iconName]="iconItem.iconName" [size]="'large'"></c1-privacy-icon>
            <div
              class="grv-text--normal grv-margin__top--small"
              c1PrivacyTranslateWithHtml
              [translateKey]="iconItem.iconText"
              [htmlConfig]="htmlConfig"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
