<main>
  <div class="grv-grid grv-padding__bottom--xxlarge" id="transparency-report">
    <c1-privacy-title-banner [title]="content.title" [subtitle]="content.subtitle" [description]="content.description"
      [link]="content.link"></c1-privacy-title-banner>

    <div class="grv-padding__top--normal grv-row description">
      <div class="grv-wrapper">
        <p class="grv-col--sm-4 grv-text--normal" c1PrivacyTranslateWithHtml
          [translateKey]="'TRANSPARENCY_REPORT.DESCRIPTION1'" [htmlConfig]="htmlConfig"></p>
        <p class="grv-col--sm-4 grv-text--normal">{{ "TRANSPARENCY_REPORT.RIGHTS_HEADER" | translate }}</p>
        <ul>
          <li class="grv-text--normal">{{ "TRANSPARENCY_REPORT.RIGHTS_DOWNLOAD" | translate }}</li>
          <li class="grv-text--normal">{{ "TRANSPARENCY_REPORT.RIGHTS_DELETE" | translate }}</li>
          <li class="grv-text--normal">{{ "TRANSPARENCY_REPORT.RIGHTS_CORRECT" | translate }}</li>
          <li class="grv-text--normal">{{ "TRANSPARENCY_REPORT.RIGHTS_OPT_OUT" | translate }}</li>
        </ul>
        <p class="grv-col--sm-4 grv-text--normal">{{ "TRANSPARENCY_REPORT.DESCRIPTION2" | translate }}</p>
        <p class="grv-col--sm-4 grv-text--normal">{{ "TRANSPARENCY_REPORT.DESCRIPTION3" | translate }}</p>
      </div>
    </div>

    <div class="grv-row grv-padding__top--normal">
      <c1-privacy-request-table class="grv-wrapper coverage-table"></c1-privacy-request-table>
    </div>


    <div class="grv-padding__top--large-1 grv-row description">
      <div class="grv-wrapper">

        <p class="grv-text__heading--medium">{{ "TRANSPARENCY_REPORT.GPC_HEADER" | translate }}</p>
        <p class="grv-text--normal">{{ "TRANSPARENCY_REPORT.GPC_DESCRIPTION" | translate }}</p>
        <div>
          <p class="coverage-table-description grv-text--small-1">
            <sup>1 </sup>{{ "TRANSPARENCY_REPORT.SUPERSCRIPT_1" | translate }}
          </p>
          <p class="coverage-table-description grv-text--small-1">
            <sup>2 </sup>{{ "TRANSPARENCY_REPORT.SUPERSCRIPT_2" | translate }}
          <p class="coverage-table-description grv-text--small-1">
            <sup>3 </sup>{{ "TRANSPARENCY_REPORT.SUPERSCRIPT_3" | translate }}
          </p>
        </div>
      </div>
    </div>




  </div>
</main>
