<main class="c1-privacy-gov-id-verification grv-padding__bottom--xxlarge">
  <c1-privacy-subheader [subheaderContent]="subheaderContent"> </c1-privacy-subheader>

  <section class="margin-fix">
    <p class="grv-text--normal page-info">
      {{ "GOV_ID_VERIFICATION.PAGE_INFO" | translate }}
    </p>
    <p
      class="grv-text--small margin-fix"
      c1PrivacyTranslateWithHtml
      [translateKey]="'GOV_ID_VERIFICATION.DISCLOSURE'"
      [htmlConfig]="htmlConfig"
      data-cy="gov-id-disclosure-text"
    ></p>
  </section>

  <button
    class="grv-button--progressive margin-fix continue-button"
    (click)="navigateToGovIdPage()"
    (keyup)="navigateToGovIdPage()"
    data-cy="gov-id-button"
  >
    {{ "GOV_ID_VERIFICATION.CONTINUE" | translate }}
  </button>
</main>
