import { Injectable, Inject } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { privacyCenterApiOptions } from "../http-options"
import { catchError } from "rxjs/operators"
import { Router } from "@angular/router"
import { of } from "rxjs"

import { RouteName } from "src/app/route-name"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "../maintenance-banner/maintenance-banner.service"
import {MaintenanceTypes} from "../../enums/maintenance-types";

@Injectable({
  providedIn: "root",
})
export class SessionRefreshService {
  baseUrl: string
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()
  maintenanceType: MaintenanceTypes = this.maintenanceBannerService.getType()
  constructor(
    private http: HttpClient,
    private router: Router,
    private newRelicService: NewRelicService,
    private maintenanceBannerService: MaintenanceBannerService,
    @Inject(APP_CONFIG) config: AppConfig
  ) {
    this.baseUrl = config.apiURL
  }

  sessionRefresh() {
    return this.http.get(`${this.baseUrl}/session-refresh`, privacyCenterApiOptions).pipe(
      retryWithBackoff((msg) =>
        this.newRelicService.logCustomError(msg, {
          logLevel: ErrorLoggingLevel.Error,
        })
      ),
      catchError((err) => {
        this.newRelicService.logCustomError(
          `Error in the GET call to /session-refresh/. The user was navigated to the Snag page. ${JSON.stringify(err)}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        if (this.maintenance) {
          this.router.navigate([`/${RouteName.Unavailable}`])
        } else {
          this.router.navigate([`/${RouteName.SessionExpired}`])
        }
        return of(err)
      })
    )
  }
}
