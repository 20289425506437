import { Injectable, ErrorHandler, Injector } from "@angular/core"
import { HttpErrorResponse } from "@angular/common/http"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { IntrospectService } from "../intropsect/introspect.service"
import { Subscription } from "rxjs"
import { ccpaCookies } from "src/app/common"
import { MonitorService } from "../monitor/monitor.service"
import { AuthenticationService } from "../auth/authentication.service"
import { ActorType } from "src/app/models/common"

interface NewRelicAPI {
  interaction: () => { save: () => void; setName: (name: string) => void; setAttribute: any }
  noticeError: (error: Error | string, customAttributes?: { [key: string]: string }) => {}
  addPageAction: any
  setCustomAttribute: any
}

@Injectable({
  providedIn: "root",
})
export class NewRelicService implements ErrorHandler {
  private newRelicAgentAPI: NewRelicAPI
  private introspectSubscription: Subscription
  constructor(
    private introspectService: IntrospectService,
    private monitorService: MonitorService,
    private injector: Injector
  ) {
    this.setNewRelicAgentAPI()
  }

  public handleError(error: Error | HttpErrorResponse) {
    const customAttributes = { logLevel: ErrorLoggingLevel.Error }

    if (error instanceof HttpErrorResponse) {
      if (!navigator.onLine) {
        this.logCustomError(`Unhandled connection error: ${error.message}`, customAttributes)
      } else {
        this.logCustomError(`Unhandled HTTP error: ${error.message}`, customAttributes)
      }
    } else {
      this.logCustomError(`Unhandled error: ${error.stack}`, customAttributes)
      this.introspectService.actorType$.subscribe((res) => {
        if (res !== ActorType.NON_CUSTOMER) {
          this.monitorService.logVipMonitor$(error, this.ccIdCookie).subscribe()
        }
      })
    }
  }

  public logInteraction(eventName: string) {
    const authService = this.injector.get(AuthenticationService)
    const trackingCookie = authService.getUserSessionCookie()
    if (this.newRelicAgentAPI) {
      const cb = (actorType) => {
        this.newRelicAgentAPI
          .interaction()
          .setAttribute("ccId", this.ccIdCookie.split("=")[1] || "none")
          .setAttribute("actorType", actorType)
          .setAttribute("ccpaCookie", trackingCookie)
          .setName(eventName)
          .save()
      }
      this.introspectService.actorType$.subscribe({
        next: cb.bind(this),
        error: cb.bind(this),
      })
    }
  }

  public logCustomError(error: Error | string, customAttributes?: { [key: string]: string }): void {
    if (this.newRelicAgentAPI) {
      const errorObject: Error = error instanceof Error ? error : new Error(error)
      customAttributes = Object.assign({}, { ...customAttributes, ccId: this.ccIdCookie })
      const cb = (res) => {
        try {
          // tslint:disable-next-line
          customAttributes["actorType"] = res
          this.newRelicAgentAPI.noticeError(errorObject, customAttributes)
        } catch (error) {
          console.log(`There was an error in the call to logCustomError in the NewRelicService: ${error}`)
        }
      }
      this.introspectService.actorType$.subscribe({
        next: cb.bind(this),
        error: cb.bind(this),
      })
    }
  }

  private setNewRelicAgentAPI(): void {
    if (window && (window as any).newrelic) {
      this.newRelicAgentAPI = (window as any).newrelic
    } else {
      console.log("New Relic Browser Agent was not found.")
    }
  }

  private get ccIdCookie(): string | undefined {
    const ccId = document.cookie.split(";").find((item) => item.trim().indexOf(`${ccpaCookies.correlationId}=`) === 0)

    const c1MYD = document.cookie
      .split(";")
      .find((item) => item.trim().indexOf(`${ccpaCookies.ccpaTrackingGUID}=`) === 0)

    return ccId ? ccId : c1MYD
  }
}
