<main>
  <div class="grv-grid grv-padding__bottom--xxlarge" id="transparency-report">
    <c1-privacy-title-banner [title]="content.title" [subtitle]="content.subtitle" [description]="content.description"
      [link]="content.link"></c1-privacy-title-banner>

    <div class="grv-padding__top--normal grv-row description">
      <div class="grv-wrapper">
        <p class="grv-col--sm-4 grv-text--normal" c1PrivacyTranslateWithHtml
          [translateKey]="'TRANSPARENCY_REPORT_SPANISH.DESCRIPTION1'" [htmlConfig]="htmlConfig"></p>
        <p class="grv-col--sm-4 grv-text--normal">Capital One respeta los derechos de los residentes de California bajo la CCPA a:</p>
        <ul>
          <li class="grv-text--normal">Conocer sus datos (descargándolos)</li>
          <li class="grv-text--normal">Pedir que se eliminen sus datos</li>
          <li class="grv-text--normal">Pedir que se corrijan sus datos</li>
          <li class="grv-text--normal">Excluirse voluntariamente de “compartir” ciertos datos personales con fines de publicidad conductual de contexto cruzado.</li>
        </ul>
        <p class="grv-col--sm-4 grv-text--normal">{{ "TRANSPARENCY_REPORT_SPANISH.DESCRIPTION2" | translate }}</p>
        <p class="grv-col--sm-4 grv-text--normal">{{ "TRANSPARENCY_REPORT_SPANISH.DESCRIPTION3" | translate }}</p>
      </div>
    </div>

    <div class="grv-row grv-padding__top--normal grv">
      <ng-template [ngIf]="!isMobile" [ngIfElse]="mobileView">
        <div class="grv-wrapper coverage-table">
        <table class="grv-table grv-table--small">
          <caption class="grv-table__caption">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de descarga</p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12">Residencia</th>
              <th scope="col" class="grv-table__header">Total recibido<sub>1</sub></th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">Peticiones en las que proporcionamos datos
              </th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">
                Peticiones en las que no se proporcionaron datos<sub>2</sub>
              </th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell">California</td>
              <td class="grv-table__cell">411</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                286
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">125</td>
            </tr>
            <tr class="grv-table__row">
              <td class="grv-table__cell">Fuera de California</td>
              <td class="grv-table__cell">1943</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                1267
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">676</td>
            </tr>
          </tbody>
        </table>
        <table class="grv-table grv-table--small">
          <caption class="grv-table__caption grv-margin__top--large-2">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de eliminación</p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header">Residencia</th>
              <th scope="col" class="grv-table__header">Total recibido<sub>1</sub></th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">Peticiones en las que eliminamos datos
              </th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">
                Peticiones en las que no se eliminaron datos<sub>2</sub>
              </th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell">California</td>
              <td class="grv-table__cell">250</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                1
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">249</td>
            </tr>
            <tr class="grv-table__row">
              <td class="grv-table__cell">Fuera de California</td>
              <td class="grv-table__cell">703</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                3
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">700</td>
            </tr>
          </tbody>
        </table>

        <table class="grv-table grv-table--small">
          <caption class="grv-table__caption grv-margin__top--large-2">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de corrección</p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header">Residencia</th>
              <th scope="col" class="grv-table__header">Total recibido<sub>1</sub></th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">Peticiones en las que eliminamos datos
              </th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">
                Peticiones en las que no se corregimos datos<sub>2</sub>
              </th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell">California</td>
              <td class="grv-table__cell">0</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                N/A
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">N/A</td>
            </tr>
            <tr class="grv-table__row">
              <td class="grv-table__cell">Fuera de California</td>
              <td class="grv-table__cell">0</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                N/A
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">N/A</td>
            </tr>
          </tbody>
        </table>

        <table class="grv-table grv-table--small">
          <caption class="grv-table__caption grv-margin__top--large-2">
            <p class="grv-text--medium-2 grv-margin--none">Mediana de tiempo para responder de manera detallada (en días)<sub
                class="grv-text--small-2">3</sub></p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header">Residencia</th>
              <th scope="col" class="grv-table__header">Peticiones de descarga</th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">Peticiones de eliminación
              </th>
              <th scope="col" class="grv-table__header grv-table__header--right-align">
                Peticiones de corrección
              </th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell">California</td>
              <td class="grv-table__cell">11.00</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                5.00
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">N/A</td>
            </tr>
            <tr class="grv-table__row">
              <td class="grv-table__cell">Fuera de California</td>
              <td class="grv-table__cell">11.00</td>
              <td class="grv-table__cell grv-table__cell--right-align">
                5.00
              </td>
              <td class="grv-table__cell grv-table__cell--right-align">N/A</td>
            </tr>
          </tbody>
        </table>
        </div>
        </ng-template>

        <ng-template #mobileView>
        <table *ngIf="isMobile" class="grv-table grv-table--small no-border">
          <caption class="grv-table__caption ">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de descarga</p>
          </caption>
          <thead class="grv-table__head grv-margin__top--small-1">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">411</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que proporcionamos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">286</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se proporcionaron datos<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">125</td>
            </tr>
          </tbody>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">Fuera de California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">1943</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que proporcionamos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">1267</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se proporcionaron datos<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">676</td>
            </tr>
          </tbody>
        </table>

        <hr class="solid">

        <table *ngIf="isMobile" class="grv-table grv-table--small no-border">
          <caption class="grv-table__caption">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de eliminación</p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">250</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que eliminamos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">1</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se eliminaron datos<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">249</td>
            </tr>
          </tbody>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">Fuera de California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">703</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que eliminamos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">3</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se eliminaron datos<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">700</td>
            </tr>
          </tbody>
        </table>

        <hr class="solid">

        <table *ngIf="isMobile" class="grv-table grv-table--small no-border">
          <caption class="grv-table__caption">
            <p class="grv-text--medium-2 grv-margin--none">Peticiones de corrección</p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">0</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que corregimos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se corrigieron dato<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">Non-California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Total recibido<sub>1</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">0</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que corregimos datos</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones en las que no se corrigieron datos<sub>2</sub></td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
        </table>

        <hr class="solid">

        <table *ngIf="isMobile" class="grv-table grv-table--small no-border">
          <caption class="grv-table__caption">
            <p class="grv-text--medium-2 grv-margin--none">Mediana de tiempo para responder de manera detallada (en días)<sub class="grv-text--small-2">3</sub></p>
          </caption>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de descarga</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">11.00</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de eliminación</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">5.00</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de corrección</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
          <thead class="grv-table__head">
            <tr class="grv-table__row">
              <th scope="col" class="grv-table__header grv-col--sm-12 table-pad">Fuera de California</th>
            </tr>
          </thead>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de descarga</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">11.00</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de eliminación</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">5.00</td>
            </tr>
          </tbody>
          <tbody class="grv-table__body">
            <tr class="grv-table__row">
              <td class="grv-table__cell table-pad">Peticiones de corrección</td>
              <td class="grv-table__cell grv-table__cell--right-align table-pad">N/A</td>
            </tr>
          </tbody>
        </table>

        <hr class="solid no-margin">

        </ng-template>

    </div>


    <div class="grv-padding__top--large-1 grv-row description">
      <div class="grv-wrapper">

        <p class="grv-text__heading--medium">Peticiones de exclusión de GPC</p>
        <p class="grv-text--normal">Capital One respalda el uso del Control de Privacidad Global (GPC), que es una configuración que le permite comunicar sus preferencias de privacidad a los sitios web y servicios por Internet que visita. Cuando detectamos una señal GPC de un navegador, nuestros Servicios por Internet están diseñados para considerar el navegador como excluido del intercambio de información y para dejar de compartir datos personales con determinados fines de publicidad personalizada, de acuerdo con las leyes aplicables. En el 2023, la tasa de exclusión voluntaria promedio para los residentes de California fue del 0.77% y la tasa de exclusión voluntaria medio para los no residentes de California fue del 0.81%.</p>
        <div>
          <p class="coverage-table-description grv-text--small-1">
            <sup>1 </sup>Número de peticiones verificables que recibimos en el 2023. Es posible que no recibamos o no tramitemos una petición si no podemos verificar la identidad de quien hace la petición.
          </p>
          <p class="coverage-table-description grv-text--small-1">
            <sup>2 </sup>Número de peticiones en las que no encontramos ningún dato, todos los datos que teníamos estaban exentos de los requisitos de divulgación, corrección o eliminación, o la petición fue denegada por otros motivos legales.</p>
          <p class="coverage-table-description grv-text--small-1">
            <sup>3 </sup>La CCPA requiere que todas las peticiones se atiendan en un plazo de 45 días y permite una extensión adicional de 45 días.
          </p>
        </div>
      </div>
    </div>




  </div>
</main>
