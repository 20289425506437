import { Component, OnInit } from "@angular/core"
import { Location } from "@angular/common"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"

@Component({
  selector: "c1-privacy-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  subheaderContent: SubheaderContent = {
    icon: "error",
    headerText: "PAGE_NOT_FOUND.HEADER",
  }
  maintenance: boolean = this.maintenanceBannerService.bannerIsPreviewed()
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()

  constructor(private location: Location,
  public maintenanceBannerService: MaintenanceBannerService
) {}

  ngOnInit() {}

  public goBackToPreviousRoute() {
    this.location.back()
  }
}
