<main class="grv-margin__top--large grv-padding__bottom--xxlarge grv-grid">
  <c1-privacy-subheader
    [subheaderContent]="subheaderContent"
    class="grv-row grv-row--sm-center grv-margin__bottom--normal subheader-container"
  >
  </c1-privacy-subheader>
  <div
    id="mno-snag-text-container"
    class="grv-padding__top--medium grv-padding__bottom--medium grv-padding__left--large grv-padding__right--large grv-text__body--normal"
    *ngIf="errorType$ | async"
  >
    <div>
      {{ content.reasonsText | translate }}
    </div>

    <div *ngIf="definedError === 'GOV_ID'" class="grv-padding__top--normal">
      <span>
        {{ content.preTryText | translate }}
      </span>

      <a
        class="ods-text__link--no-style"
        (click)="navigateToGovId()"
        (keyup.enter)="navigateToGovId()"
        (keydown.enter)="navigateToGovId()"
        tabindex="0"
        href="javascript:void(0)"
      >
        {{ content.tryText | translate }}
      </a>

      <span>
        {{ content.postTryText | translate }}
      </span>
    </div>

    <div *ngIf="definedError === 'MNO'" class="grv-padding__top--normal">
      <span>
        {{ content.tryText | translate }}
      </span>
    </div>
  </div>

  <div *ngIf="definedError === 'GOV_ID'" class="grv-text__body--normal link-text-container grv-padding__top--medium-2">
    <a
      class="grv-text__link--no-style"
      data-cy="data-categories-link"
      (click)="navigateToDataCategories()"
      (keyup.enter)="navigateToDataCategories()"
      (keydown.space)="navigateToDataCategories()"
      tabindex="0"
      href="javascript:void(0)"
    >
      {{ content.dataCategory | translate }}
    </a>
  </div>

  <div *ngIf="definedError === 'MNO'" class="grv-row grv-row grv-row--sm-center">
    <button
      class="grv-button--progressive"
      [innerHTML]="content.mnoReturnToManage | translate"
      (click)="navigateToManage()"
      (keyup.enter)="navigateToManage()"
      (keyup.space)="navigateToManage()"
      data-cy="mno-return-manage"
    ></button>
  </div>

  <div class="grv-text__body--normal link-text-container">
    <div class="grv-margin__top--medium-2">
      <span>
        {{ content.questions | translate }}
      </span>
      <a
        class="grv-text__link--no-style"
        (click)="callCenterDialogOpen()"
        data-cy="phone-number-link"
        tabindex="0"
        (keyup.enter)="callCenterDialogOpen()"
        href="javascript:void(0)"
      >
        {{ content.questionsLink | translate }}
      </a>
    </div>
  </div>
  <c1-privacy-call-center-dialog></c1-privacy-call-center-dialog>
</main>
