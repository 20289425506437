<c1-privacy-subheader-with-card [subheaderContent]="subheaderContent" class="c1-privacy-in-progress__main">
  <div class="page-content">
    <c1-privacy-banner [alertType]="'ERROR'" [canClose]="false" *ngIf="!isFormValid() && isFormDirty()">
      {{ "INTAKE_FORM.FORM_ERROR" | translate }}
    </c1-privacy-banner>

    <p
      class="grv-text--normal small-text-on-mobile">
      {{"INTAKE_FORM.PAGE_DESCRIPTION" | translate}}
    <a
    class="grv-text__link no-underline"
    data-cy="data-categories-link"
    (click)="navigateToDelete()"
    (keyup.enter)="navigateToDelete()"
    (keydown.space)="navigateToDelete()"
    tabindex="0"
    href="javascript:void(0)">
    {{ "INTAKE_FORM.DELETE_DATA_LINK" | translate }}
  </a>

  </p>

    <div *ngFor="let c of corrections; index as i" class="grv-margin__top--medium-1">
      <c1-privacy-intake-form
        #form
        [showRemoveButton]="corrections.length > 1"
        (onFormChanged)="onFormChanged({$event, i})"
        (onRemoved)="onRemoveCorrection(i)"
      ></c1-privacy-intake-form>
    </div>

    <div class="add-button-content">
      <span *ngIf="corrections.length < 5" class="grv-text--medium-1">
        <c1-privacy-icon [iconName]="'plus'" [size]="'quark'"> </c1-privacy-icon>
        <a id="addNewForm" role="button" tabindex="0" (click)="addCorrection()">
          {{ "INTAKE_FORM.ADD_NEW_BUTTON" | translate }}
        </a>
      </span>
    </div>

    <div class="grv-margin__top--normal margin-fix submit-button-container">
      <button
        type="button"
        class="grv-button--regressive back-button grv-margin__right--medium-2"
        data-cy="back-button"
        tabindex="0"
        (click)="cancel()"
      >
        {{ "INTAKE_FORM.CANCEL_BUTTON" | translate }}
      </button>

      <button
        class="grv-button--progressive submit-button"
        type="button"
        id="confirm-button"
        [disabled]="!isFormValid()"
        (click)="submit()"
        (keyup)="submit()"
        data-cy="confirm-button"
      >
        {{ "INTAKE_FORM.SUBMIT_BUTTON" | translate }}
      </button>
    </div>
  </div>
</c1-privacy-subheader-with-card>
