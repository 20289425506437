import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core"
import {MaintenanceBannerService} from "src/app/services/maintenance-banner/maintenance-banner.service"
import {APP_CONFIG, AppConfig} from "../../../app.config";

export enum AlertType {
  INFORMATIONAL = "INFORMATIONAL",
  WARNING = "WARNING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  GLOBAL = "GLOBAL",
}

@Component({
  selector: "c1-privacy-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  @Input() alertType: AlertType = AlertType.INFORMATIONAL
  @Input() canClose: boolean = true
  @Input() showMaintenance: boolean = false
  @Input() hasLinkButton: boolean = false
  @Input() linkMessage: string = "test"
  @Input() linkText: string = ""
  @Input() errorComponent: any
  @Output() onClosed: EventEmitter<null> = new EventEmitter<null>()
  @Output() onLinkClicked: EventEmitter<null> = new EventEmitter<null>()
  @Input() type: string
  config: AppConfig
  bannerText = this.maintenanceBannerService.getMaintenanceDurationString()
  alertClasses: string = ""
  closed = false

  constructor(public maintenanceBannerService: MaintenanceBannerService,@Inject(APP_CONFIG) config: AppConfig) {
    this.config = config
    this.type = config.maintenanceType
  }

  ngOnInit(): void {
    this.setAlertClasses()
  }

  setAlertClasses() {
    switch (this.alertType) {
      case AlertType.INFORMATIONAL:
        this.alertClasses = "grv-alert--informational grv-alert--active grv-margin--medium-1 privacy-alert"
        break
      case AlertType.WARNING:
        this.alertClasses = "grv-alert--warning grv-alert--active grv-margin--medium-1 privacy-alert"
        break
      case AlertType.ERROR:
        this.alertClasses = "grv-alert--error grv-alert--active grv-margin--medium-1 privacy-alert"
        break
      case AlertType.SUCCESS:
        this.alertClasses = "grv-alert--success grv-alert--active grv-margin--medium-1 privacy-alert"
        break
      case AlertType.GLOBAL:
        this.alertClasses = "grv-alert--global grv-alert--active grv-margin--none"
        break
    }
  }

  close() {
    this.closed = true
    this.onClosed.emit()
  }

  linkClick() {
    this.onLinkClicked.emit()
  }
}
