import { Component, Input } from "@angular/core"
import { SVGScriptEvalMode } from "ng-inline-svg-2"
import { ApplicationImages } from "./icon.const"

enum IconHeight {
  quark = "12px",
  angstrem = "16px",
  nano = "18px",
  micro = "24px",
  tiny = "36px",
  footer_icon_size = "32px",
  xsmall = "48px",
  small = "63px",
  large = "96px",
  default = "100%",
}

interface Icon {
  file: string
}

const icons: { [key: string]: Icon } = {
  user: {
    file: ApplicationImages.USER,
  },
  fraud: {
    file: ApplicationImages.FRAUD,
  },
  features: {
    file: ApplicationImages.FEATURES,
  },
  offers: {
    file: ApplicationImages.OFFERS,
  },
  filledUser: {
    file: ApplicationImages.FILLED_USER,
  },
  document: {
    file: ApplicationImages.DOCUMENT,
  },
  success: {
    file: ApplicationImages.SUCCESS,
  },
  gauge: {
    file: ApplicationImages.GAUGE,
  },
  caution: {
    file: ApplicationImages.CAUTION,
  },
  caution2: {
    file: ApplicationImages.CAUTION2,
  },
  plane: {
    file: ApplicationImages.PLANE,
  },
  clock: {
    file: ApplicationImages.CLOCK,
  },
  error: {
    file: ApplicationImages.ERROR,
  },
  branch: {
    file: ApplicationImages.BRANCH,
  },
  securityApproval: {
    file: ApplicationImages.SECURITY_APPROVAL,
  },
  goOnline: {
    file: ApplicationImages.GO_ONLINE,
  },
  correction: {
    file: ApplicationImages.CORRECTION,
  },
  mailbox: {
    file: ApplicationImages.MAILBOX,
  },
  contact: {
    file: ApplicationImages.CONTACT,
  },
  dataStaggered: {
    file: ApplicationImages.DATA_STAGGERED,
  },
  checkmark: {
    file: ApplicationImages.CHECKMARK,
  },
  avatar: {
    file: ApplicationImages.AVATAR,
  },
  identityServices: {
    file: ApplicationImages.IDENTITY_SERVICES,
  },
  logo: {
    file: ApplicationImages.LOGO,
  },
  notification: {
    file: ApplicationImages.NOTIFICATION,
  },
  identity: {
    file: ApplicationImages.IDENTITY,
  },
  toolbox: {
    file: ApplicationImages.TOOLBOX,
  },
  hasdata: {
    file: ApplicationImages.HASDATA,
  },
  hasnodata: {
    file: ApplicationImages.HASNODATA,
  },
  info: {
    file: ApplicationImages.INFO,
  },
  arrowUp: {
    file: ApplicationImages.ARROW_UP,
  },
  arrowDown: {
    file: ApplicationImages.ARROW_DOWN,
  },
  arrowRight: {
    file: ApplicationImages.ARROW_RIGHT,
  },
  clockFilled: {
    file: ApplicationImages.CLOCK_FILLED,
  },
  trash: {
    file: ApplicationImages.TRASH,
  },
  trash_blue: {
    file: ApplicationImages.TRASH_BLUE,
  },
  edit: {
    file: ApplicationImages.EDIT,
  },
  paper: {
    file: ApplicationImages.PAPER,
  },
  plus: {
    file: ApplicationImages.PLUS,
  },
  makePlan: {
    file: ApplicationImages.MAKE_PLAN,
  },
  download: {
    file: ApplicationImages.DOWNLOAD,
  },
  pen: {
    file: ApplicationImages.PEN,
  },
  equal_opportunity: {
    file: ApplicationImages.EQUAL_OPPORTUNITY,
  },
  fdic: {
    file: ApplicationImages.FDIC,
  },
  xclose: {
    file: ApplicationImages.XCLOSE,
  },
}

@Component({
  selector: "c1-privacy-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
  @Input() iconURI: string
  @Input() iconName: string
  @Input() size: string = "small"
  @Input() ariaHidden: boolean = true
  disableScriptEval = SVGScriptEvalMode.NEVER

  private iconsBaseLocation: string = "assets/icons/"

  get icon(): Icon {
    return icons[this.iconName]
  }

  get height(): IconHeight {
    return IconHeight[this.size] ? IconHeight[this.size] : IconHeight.small
  }

  get fileName(): string {
    return `${this.iconsBaseLocation}${this.icon.file}`
  }
}
