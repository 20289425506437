import { HttpErrorResponse } from "@angular/common/http"
import { Component, OnInit, ViewChild } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { newRelicEvent } from "src/app/common"
import { EmailDialogComponent } from "src/app/components/email-dialog/email-dialog.component"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { ErrorCodes } from "src/app/enums/error-codes.enum"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { GuidanceBar } from "src/app/enums/guidance-bar.enum"
import { Category, Introspect } from "src/app/models"
import { RequestType } from "src/app/models/common/RequestType"
import { IntakeFormData } from "src/app/models/intakeFormData"
import { UserIntrospect } from "src/app/models/Introspect"
import { RelatedEligibilityRequest } from "src/app/models/PrivacyRequest"
import { RouteName } from "src/app/route-name"
import { CategoryService } from "src/app/services/category/category.service"
import { IntakeFormService } from "src/app/services/intake-form/intake-form.service"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"

@Component({
  selector: "c1-privacy-intake-form-confirmation-page",
  templateUrl: "./intake-form-confirmation-page.component.html",
  styleUrls: ["./intake-form-confirmation-page.component.scss"],
})
export class IntakeFormConfirmationPageComponent implements OnInit {
  @ViewChild(EmailDialogComponent)
  emailDialog: EmailDialogComponent
  selectedCategories!: Category[]
  submitLoading: boolean = false
  emailAddress: string
  GuidanceBar = GuidanceBar
  guidanceBarState: GuidanceBar = GuidanceBar.None
  validEmail: boolean = false
  disableClose: boolean
  needsEmailUpdate: boolean
  corrections: IntakeFormData[] = []
  relatedEligibilityRequest: RelatedEligibilityRequest
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()

  subheaderContent: SubheaderContent = {
    icon: "document",
    headerText: "INTAKE_CONFIRMATION.HEADER",
    subheaderText: "INTAKE_CONFIRMATION.SUBHEADER",
  }

  constructor(
    public intakeFormService: IntakeFormService,
    public router: Router,
    public privacyRequestService: PrivacyRequestService,
    public categoryService: CategoryService,
    private newRelicService: NewRelicService,
    private activatedRoute: ActivatedRoute,
    private maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService
  ) {
    this.getCachedFormData()
  }

  ngOnInit(): void {

    this.oneTagService.setPageView();
    this.intakeFormService.relatedEligibilityRequest$.subscribe((req) => (this.relatedEligibilityRequest = req))

    this.categoryService.introspect$(RequestType.DELETE).subscribe(
      (introspect: Introspect) => {
        const userIntrospect = new UserIntrospect(introspect)
        if (userIntrospect.primaryEmailAddress) {
          this.emailAddress = userIntrospect.primaryEmailAddress
          this.validEmail = true
          this.disableClose = false
        } else {
          this.needsEmailUpdate = true
          this.disableClose = true
          this.openDialog()
        }
      },
      (error) => {
        this.newRelicService.logCustomError(
          `Error in cagetoryService.introspect$ subscription on create-request/confirm. User was navigated to Snag page. ${JSON.stringify(
            error
          )}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        if (this.maintenance) {
          this.router.navigate(["/", RouteName.Unavailable], {
            relativeTo: this.activatedRoute,
          })
        } else {
          this.router.navigate(["/", RouteName.Snag], {
            relativeTo: this.activatedRoute,
          })
        }
      }
    )

    this.newRelicService.logCustomError("User navigated from correct/confirm.", {
      event: newRelicEvent.correctionConfirm,
      logLevel: ErrorLoggingLevel.Info,
    })
  }

  getCachedFormData() {
    if (!this.intakeFormService.intakeFormData) this.router.navigate([RouteName.IntakeFormConfirmationPage])
    this.corrections = this.intakeFormService.intakeFormData
  }

  goBack() {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionConfirmationNavigateToIntakeForm');
    this.router.navigate([RouteName.IntakeFormPage])
  }

  get disableSubmitButton() {
    return this.needsEmailUpdate || this.submitLoading
  }

  get buttonClass() {
    return this.submitLoading ? ["grv-button--loading", "grv-button--loading-active"] : []
  }

  public updateEmail(updatedEmail: string): void {
    if (updatedEmail !== this.emailAddress) {
      this.emailAddress = updatedEmail
      this.validEmail = true
      this.guidanceBarState = this.GuidanceBar.Updated
      this.needsEmailUpdate = false
    }
    this.disableClose = false
    this.emailDialog.disableClose = false
  }

  public openDialog(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'confirmCorrectionEmailDialog');
    this.guidanceBarState = GuidanceBar.None
    this.emailDialog.disableClose = this.disableClose
    return this.emailDialog.open()
  }

  public submit(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'correctionConfirmationSubmitRequest');
    this.submitLoading = true
    this.intakeFormService
      .submitRequest$(this.intakeFormService.intakeFormData, this.relatedEligibilityRequest, this.emailAddress)
      .subscribe(
        (res) => {
          this.submitLoading = false
          this.intakeFormService.requestSuccessful = true
          this.newRelicService.logCustomError("User navigated from correct/confirm to correct/success after POST.", {
            event: newRelicEvent.correctionSuccess,
            logLevel: ErrorLoggingLevel.Info,
          })
          this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.complete, 'correction request');

          this.router.navigate(["../success"], {
            relativeTo: this.activatedRoute,
            state: {
              requestId: res.privacyDataRequestId,
              isCorrectFlow: true,
            },
          })
        },
        (error: HttpErrorResponse) => {
          this.submitLoading = false
          this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.fail, 'correction request');
          if (
            error.status === 400 &&
            error.error &&
            (error.error.id === ErrorCodes.cantVerify || error.error.id === ErrorCodes.invalidDomain)
          ) {
            this.newRelicService.logCustomError(
              `User received an email related error on POST from correct/confirm: ${JSON.stringify(error)}`,
              { logLevel: ErrorLoggingLevel.Error }
            )
            this.needsEmailUpdate = true
            this.guidanceBarState = GuidanceBar.Invalid
          } else {
            this.newRelicService.logCustomError(
              // tslint:disable-next-line:max-line-length
              `Error in intakeFormService.submitRequest$ subscription on correct/confirm. User was navigated to Snag page. ${JSON.stringify(
                error
              )}`,
              { logLevel: ErrorLoggingLevel.Error }
            )
            if (this.maintenance) {
              this.router.navigate(["/", RouteName.Unavailable], {
                relativeTo: this.activatedRoute,
              })
            } else {
              this.router.navigate(["/", RouteName.Snag], {
                relativeTo: this.activatedRoute,
              })
            }
          }
        }
      )
  }
}
