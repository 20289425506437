import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core"
import { Location } from "@angular/common"
import { HttpErrorResponse } from "@angular/common/http"
import { Router, ActivatedRoute } from "@angular/router"

import { CategoryService } from "src/app/services/category/category.service"
import { Category, Introspect } from "src/app/models"
import { SubheaderContent } from "src/app/components/subheader/SubheaderContent"
import { GuidanceBar } from "src/app/enums/guidance-bar.enum"
import { EmailDialogComponent } from "src/app/components/email-dialog/email-dialog.component"
import { AuthenticationService } from "src/app/services/auth/authentication.service"
import { RequestType } from "src/app/models/common"
import { ErrorCodes } from "src/app/enums/error-codes.enum"
import { PrivacyRequestService } from "src/app/services/privacy-request/privacy-request.service"
import { RouteName } from "../../../route-name"
import { NewRelicService } from "src/app/services/new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { newRelicEvent } from "src/app/common"
import { MaintenanceBannerService } from "src/app/services/maintenance-banner/maintenance-banner.service"
import { ONETAG_APPLICATION_EVENTS, OneTagService } from "src/app/services/one-tag/one-tag.service"

@Component({
  selector: "c1-privacy-confirm-delete-request",
  templateUrl: "./confirm-delete-request.component.html",
  styleUrls: ["./confirm-delete-request.component.scss"],
})
export class ConfirmDeleteRequestComponent implements OnInit, AfterViewInit {
  @ViewChild(EmailDialogComponent)
  emailDialog: EmailDialogComponent
  submitLoading: boolean
  selectedCategories: Category[] = this.categoryService.selectedCategories
  subheaderContent: SubheaderContent = {
    icon: "caution",
    headerText: "CONFIRM_DELETE_REQUEST.HEADER",
    subheaderText: "CONFIRM_DELETE_REQUEST.SUBHEADING",
  }

  emailAddress: string

  GuidanceBar = GuidanceBar
  guidanceBarState: GuidanceBar = GuidanceBar.None
  validEmail: boolean = false
  disableButton: boolean = false
  emailValueFromRedirect: string = ""
  disableClose: boolean
  needsEmailUpdate: boolean
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()

  constructor(
    public categoryService: CategoryService,
    private location: Location,
    private privacyRequestService: PrivacyRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private newRelicService: NewRelicService,
    private maintenanceBannerService: MaintenanceBannerService,
    private oneTagService: OneTagService
  ) {}

  ngAfterViewInit() {
    if (this.disableClose) {
      this.openDialog()
    }
  }

  ngOnInit() {

    this.oneTagService.setPageView();

    this.newRelicService.logCustomError("User navigated to delete/confirm", {
      event: newRelicEvent.deleteRequestConfirm,
      logLevel: ErrorLoggingLevel.Info,
    })
    this.categoryService.introspect$(RequestType.DELETE).subscribe(
      (introspect: Introspect) => {
        if (introspect.primaryEmailAddress) {
          this.emailAddress = introspect.primaryEmailAddress
          this.validEmail = true
          this.disableClose = false
        } else {
          this.disableButton = true
          this.needsEmailUpdate = true
          this.disableClose = true
        }
      },
      (error) => {
        this.newRelicService.logCustomError(
          `Error in cagetoryService.introspect$ subscription on delete/confirm. User was navigated to Snag page. ${JSON.stringify(
            error
          )}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        if (this.maintenance) {
          this.router.navigate(["/", RouteName.Unavailable], {
            relativeTo: this.activatedRoute,
          })
        }  else {
          this.router.navigate(["/", RouteName.Snag], {
            relativeTo: this.activatedRoute,
          })
        }
      }
    )
  }

  get buttonClass(): string[] {
    return this.submitLoading ? ["grv-button--loading", "grv-button--loading-active"] : []
  }

  get disableSubmitButton() {
    return this.needsEmailUpdate || this.submitLoading
  }

  public goBackToPreviousRoute(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'confirmDeleteNavigateBack');
    this.location.back()
  }

  public handleSubmitClick(): void {
    this.submitLoading = true
    this.privacyRequestService
      .submitRequest$(this.categoryService.categoryValue, RequestType.DELETE, this.emailAddress)
      .subscribe(
        (res: any) => {
          this.submitLoading = false
          this.categoryService.requestSuccessful = true
          this.newRelicService.logCustomError("User navigated from delete/confirm to delete/success after POST.", {
            event: newRelicEvent.deleteRequestSuccess,
            logLevel: ErrorLoggingLevel.Info,
          })

          this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.complete, 'delete request');
          this.router.navigate(["../success"], {
            relativeTo: this.activatedRoute,
            state: {
              isDeleteFlow: true,
              requestId: res.privacyDataRequestId,
            },
          })
        },
        (error: HttpErrorResponse) => {
          this.submitLoading = false
          this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.fail, 'delete request');
          if (
            error.status === 400 &&
            error.error &&
            (error.error.id === ErrorCodes.cantVerify || error.error.id === ErrorCodes.invalidDomain)
          ) {
            this.newRelicService.logCustomError(
              `User received an email related error on POST from delete/confirm: ${JSON.stringify(error)}`,
              { logLevel: ErrorLoggingLevel.Error }
            )
            this.disableButton = true
            this.needsEmailUpdate = true
            this.guidanceBarState = GuidanceBar.Invalid
          } else {
            this.newRelicService.logCustomError(
              // tslint:disable-next-line:max-line-length
              `Error in privacyRequestService.submitRequest$ subscription on delete/confirm. User was navigated to Snag page. ${JSON.stringify(
                error
              )}`,
              { logLevel: ErrorLoggingLevel.Error }
            )
            if(this.maintenance) {
              this.router.navigate(["/", RouteName.Unavailable], {
                relativeTo: this.activatedRoute,
              })
            }  else {
              this.router.navigate(["/", RouteName.Snag], {
                relativeTo: this.activatedRoute,
              })
            }
          }
        }
      )
  }

  public openDialog(): void {
    this.oneTagService.trackApplicationEvent(ONETAG_APPLICATION_EVENTS.linkClick, 'confirmDeleteEmailDialog');
    this.emailDialog.disableClose = this.disableClose
    return this.emailDialog.open()
  }

  public updateEmail(updatedEmail: string): void {
    if (updatedEmail !== this.emailAddress) {
      this.emailAddress = updatedEmail
      this.validEmail = true
      this.guidanceBarState = this.GuidanceBar.Updated
      this.disableButton = false
      this.needsEmailUpdate = false
    }
    this.disableClose = false
    this.emailDialog.disableClose = false
  }
}
