import { Directive, Input, OnChanges, HostBinding } from "@angular/core"

@Directive({
  selector: "[c1PrivacyLoading]",
  // tslint:disable-next-line
  host: {
    "[style.background]": "'none'",
    "[style.position]": "'inherit'",
    "[style.padding-top]": "'3vh'",
    "[style.min-height]": "'30vh'",
    "[style.display]": "shouldDisplay",
  },
})
export class LoadingDirective implements OnChanges {
  constructor() {}

  private _loading = true

  shouldDisplay = ""

  @HostBinding("class") get loading() {
    if (this._loading) {
      return "ods-spinner ods-spinner--active"
    }
  }

  @Input() c1PrivacyLoading: any
  ngOnChanges(value) {
    this._loading = !(value && value.c1PrivacyLoading && value.c1PrivacyLoading.currentValue)

    if (!this._loading) {
      this.shouldDisplay = "none"
    }
  }
}
