// privacy policy url
export const privacyPolicy = "https://www.capitalone.com/privacy/online-privacy-policy"
export const privacyPolicyCa = "https://www.capitalone.ca/privacypolicy"
export const privacyProlicyCaSpanish =
  "https://www.capitalone.com/privacy/informacion-sobre-la-ley-de-privacidad-del-consumidor-de-california"
export const privacyPolicyUk = "https://www.capitalone.co.uk/support/faqs/subject-access-request.jsf"
export const ccpaDisclosure = "https://www.capitalone.com/privacy/ccpa-disclosure"
export const emailPreferences = "https://www.capitalone.com/SSP/preferences/"
export const collectData =
  "https://www.capitalone.com/privacy/online-privacy-policy/#collecting-using-sharing-information"
export const privacyRights = "https://www.capitalone.com/privacy/online-privacy-policy/#privacy-rights-choices"
export const helpCenter = "https://www.capitalone.com/help-center/"

// CCPA UI Tracking Cookie
export const ccpaTrackingCookie = {
  name: "C1_MYD",
  expires: null,
  path: "/",
  secure: true,
  sameSite: "Strict" as "Strict",
}

// CCPA UI ActorType Cookie
export const ccpaActorTypeCookie = {
  name: "C1_MYD_AT",
  expires: null,
  path: "/",
  secure: true,
  sameSite: "Strict" as "Strict",
}

// CCPA Cookies
export const ccpaCookies = {
  correlationId: "C1_CCID",
  ccpaTrackingGUID: ccpaTrackingCookie.name,
  ccpaActorType: "C1_MYD_AT",
}

export const clientCorrelationId = "client-correlation-id"

export const newRelicEvent = {
  annualLimit: "annual-limit",
  correctionLanding: "correction-landing",
  correctionToStepUp: "correction-landing-to-step-up",
  correctionRequest: "correction-request",
  correctionConfirm: "correction-request-confirm",
  correctionSuccess: "correction-request-success",
  createRequest: "create-request",
  dataRequestConfirm: "data-request-confirm",
  dataRequestSuccess: "data-request-success",
  deleteToStepUp: "delete-to-step-up",
  deleteRequest: "delete-request",
  deleteRequestConfirm: "delete-request-confirm",
  deleteRequestSuccess: "delete-request-success",
  downloadToStepUp: "download-to-step-up",
  downloadFromStepUp: "download-from-step-up",
  downloadingRequest: "downloading-request",
  downloadingComplete: "downloading-complete",
  download: "download",
  govIdLogin: "gov-id-login",
  govIdSnag: "gov-id-snag",
  login: "login",
  mnoDownload: "mno-download",
  mnoSnag: "mno-snag",
  multipleSSO: "multiple-sso",
  manage: "manage",
  snag: "snag",
  transparencyReport: "transparency-report",
  transparencyReportSpanish: "informe-de-transparencia",
}

export enum QUERY_PARAMS {
  GOV_ID_FAILURE = "1",
  MNO_FAILURE = "2",
  STEP_UP_SUCCESS = "3",
}
