import { RouteName } from "./route-name"

const pageTitleConstant = "Manage Your Data | Capital One"
const routeNameToPageTitle = new Map([
  [RouteName.FrontDoor, ""],
  [RouteName.SignIn, "Sign In | "],
  [RouteName.SignIn + "/verification", "Verify Your Identity With Government ID | "],
  [RouteName.CreateRequest, "Begin Your Data Request | "],
  [RouteName.CreateRequestConfirm, "Confirm Your Data Request | "],
  [RouteName.CreateRequestSuccess, "We Received Your Data Request | "],
  [RouteName.SessionExpired, "Session Expired | "],
  [RouteName.Snag, "Something Went Wrong | "],
  [RouteName.Unavailable, "Page temporarily unavailable | "],
  [RouteName.DeleteRequest, "Request to Delete Your Data | "],
  [RouteName.DeleteRequestConfirm, "Confirm Your Request to Delete Your Data | "],
  [RouteName.DeleteRequestSuccess, "We Received Your Deletion Request | "],
  [RouteName.MultipleSso, "You Have Another Request In Progress | "],
  [RouteName.VerificationError, "We Couldn’t Verify Your Identity | "],
  [RouteName.DataCategories, "Data Categories | "],
  [RouteName.TransparencyReport, "Transparency Report | "],
  [RouteName.TransparencyReportSpanish, "Informe de Transparencia | "],
  [RouteName.Manage, "Data Requests | "],
  [RouteName.CorrectionLanding, "Correct Your Data | "],
  [RouteName.IntakeFormPage, "Request to Correct Your Data | "],
  [RouteName.IntakeFormConfirmationPage, "Confirm Your Request to Correct Your Data | "],
  [RouteName.IntakeFormSuccessPage, "We Received Your Correction Request | "],
])
export { pageTitleConstant, routeNameToPageTitle }
