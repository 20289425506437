<ng-container *ngIf="{ pendingRequests: pendingRequest$ | async } as data">
  <c1-privacy-banner
    class="maintenance-banner grv-margin--none"
    [alertType]="'GLOBAL'"
    [canClose]="false"
    *ngIf="maintenance"
    [showMaintenance]="true"
  >
  </c1-privacy-banner>
  <ng-container *ngIf="data.pendingRequests">
    <c1-privacy-subheader-with-card [subheaderContent]="subheaderContent" class="c1-privacy-in-progress__main">
      <ng-container *ngIf="queryParams$ | async as queryParams">
        <c1-privacy-banner class="mnoFailureBanner" [alertType]="'ERROR'" [canClose]="false" [showMaintenance]="false" *ngIf="queryParams.query === '2'">
          {{ "RIGHT_TO_CORRECT.VERIFICATION_FAILED" | translate }}
        </c1-privacy-banner>
      </ng-container>
      <!-- Download requests -->
      <ng-container *ngIf="downloadRequests$ | async as downloadRequests">
        <div class="history-section-wrapper-download">
          <div class="grv-text--medium-3 grv-weight--semibold request-header-container">
            <h3 class="request-section-header">
              {{ "PORTAL_OF_HISTORY.DOWNLOAD.HEADER" | translate }}
            </h3>
            <span id="download-create-option">
              <a role="button" tabindex="0" (click)="navigateToCreateRequest()" data-cy="download-request-link">
                {{ "PORTAL_OF_HISTORY.DOWNLOAD.LINK" | translate }}
              </a>
            </span>
          </div>
          <div *ngIf="downloadRequests.length === 0" class="no-request-download">
            <c1-privacy-icon [iconName]="requestContent.downloadIcon" [size]="'xsmall'"></c1-privacy-icon>
            <span class="grv-text--normal">{{ "PORTAL_OF_HISTORY.DOWNLOAD.NO_REQUEST" | translate }}</span>
          </div>
          <c1-privacy-banner class="downloadFailureBanner" [alertType]="'ERROR'" [canClose]="false" *ngIf="downloadFailures == 1" (downloadEndEvent)="handleDownloadEnd($event)">
            {{ "DOWNLOAD_READY_DATA.FIRST_FAILURE" | translate }}
          </c1-privacy-banner>
          <c1-privacy-banner class="downloadFailureBannerSubsequent" [alertType]="'ERROR'" [canClose]="false" [showMaintenance]="false" [hasLinkButton]="true" [linkMessage]="'DOWNLOAD_READY_DATA.SUBSEQUENT_FAILURES_LINK_MESSAGE' | translate" *ngIf="downloadFailures > 1 && showSubsequentError" (onLinkClicked)="handleSubsequentLink($event)">
            {{ "DOWNLOAD_READY_DATA.SUBSEQUENT_FAILURES" | translate }}
          </c1-privacy-banner>
          <c1-privacy-card *ngIf="downloadRequests.length > 0" role="table">
            <div class="download-case"
            role="rowgroup"
            *ngFor="let request of downloadSublists[indexMap.downloads]; index as i">
              <c1-privacy-in-progress
                *ngIf="
                  request.requestStatus === PrivacyDataRequestStatus.CREATED ||
                    request.requestStatus === PrivacyDataRequestStatus.IN_PROGRESS;
                  else showDownloadReady
                "
                [request]="request"
                [type]="'DOWNLOAD'"
                [queryParams]="queryParams$ | async"
                [divider]="i !== 0"
              >
              </c1-privacy-in-progress>
              <ng-template #showDownloadReady>
                <c1-privacy-download-ready-data
                  [request]="request"
                  [type]="'DOWNLOAD'"
                  [queryParams]="queryParams$ | async"
                  [divider]="i !== 0"
                  (downloadEndEvent)="handleDownloadEnd($event)"
                ></c1-privacy-download-ready-data>
              </ng-template>
            </div>
          </c1-privacy-card>
        </div>
        <fs-pagination-numbers *ngIf="downloadSublists.length > 1"
          class="fs-pagination-download"
          accessibility-label="Downloads Pagination"
          current-page="1"
          navigation-type="in-page"
          [pageCount]="downloadSublists.length"
          (change)="paginate($event, 'downloads')"
        ></fs-pagination-numbers>
      </ng-container>

      <!-- Correction requests -->
      <ng-container *ngIf="correctionRequests$ | async as correctionRequests">
        <div class="history-section-wrapper-correction">
          <div class="grv-text--medium-3 grv-weight--semibold request-header-container">
            <h3 class="request-section-header">
              {{ "PORTAL_OF_HISTORY.CORRECTION.HEADER" | translate }}
            </h3>
            <span id="correction-create-option">
              <a role="button" tabindex="0" (click)="navigateToCorrectionRequest()">
                {{ "PORTAL_OF_HISTORY.CORRECTION.LINK" | translate }}
              </a>
            </span>
          </div>

          <div *ngIf="correctionRequests.length === 0" class="no-request-correction">
            <c1-privacy-icon [iconName]="requestContent.correctionIcon" [size]="'xsmall'"></c1-privacy-icon>
            <span class="grv-text--normal">{{ "PORTAL_OF_HISTORY.CORRECTION.NO_REQUEST" | translate }}</span>
          </div>

          <c1-privacy-card *ngIf="correctionRequests.length > 0" role="table">
            <div
            class="correction-case"
            *ngFor="let request of correctionSublists[indexMap.corrections]; index as i">
              <c1-privacy-in-progress
                [request]="request"
                [type]="'CORRECTION'"
                [queryParams]="queryParams$ | async"
                [divider]="i !== 0"
              ></c1-privacy-in-progress>
            </div>
          </c1-privacy-card>
          <!-- TODO: add boolean to show text block -->
          <div class="request-another-copy" *ngIf="shouldRequestAnotherCopy$ | async">
            <span class="grv-text--normal">
              {{ "PORTAL_OF_HISTORY.CORRECTION.REQUEST_ANOTHER_COPY" | translate }}
              <a role="button" tabindex="0" (click)="navigateToCreateRequest(true)">{{
                "PORTAL_OF_HISTORY.CORRECTION.REQUEST_ANOTHER_COPY_LINK" | translate
              }}</a
              >.
            </span>
          </div>
        </div>
        <fs-pagination-numbers *ngIf="correctionSublists.length > 1"
          class="fs-pagination-correction"
          accessibility-label="Corrections Pagination"
          current-page="1"
          navigation-type="in-page"
          [pageCount]="correctionSublists.length"
          (change)="paginate($event, 'corrections')"
        ></fs-pagination-numbers>
      </ng-container>

      <!-- Delete requests -->
      <ng-container *ngIf="deleteRequests$ | async as deleteRequests">
        <div class="history-section-wrapper-delete">
          <div class="grv-text--medium-3 grv-weight--semibold request-header-container">
            <h3 class="request-section-header">
              {{ "PORTAL_OF_HISTORY.DELETE.HEADER" | translate }}
            </h3>
            <span id="delete-create-option">
              <a role="button" tabindex="0" (click)="navigateToDeleteRequest()" data-cy="delete-request-link">
                {{ "PORTAL_OF_HISTORY.DELETE.LINK" | translate }}
              </a>
            </span>
          </div>

          <div *ngIf="deleteRequests.length === 0" class="no-request-delete">
            <c1-privacy-icon [iconName]="requestContent.deleteIcon" [size]="'xsmall'"></c1-privacy-icon>
            <span class="grv-text--normal">{{ "PORTAL_OF_HISTORY.DELETE.NO_REQUEST" | translate }}</span>
          </div>

          <c1-privacy-card *ngIf="deleteRequests.length > 0" role="table">
            <div
            class="delete-case"
            *ngFor="let request of deleteSublists[indexMap.deletes]; index as i">
              <c1-privacy-in-progress
                [request]="request"
                [type]="'DELETE'"
                [queryParams]="queryParams$ | async"
                [divider]="i !== 0"
              ></c1-privacy-in-progress>
            </div>
          </c1-privacy-card>
        </div>
        <fs-pagination-numbers *ngIf="deleteSublists.length > 1"
          class="fs-pagination-delete"
          accessibility-label="Deletions Pagination"
          current-page="1"
          navigation-type="in-page"
          [pageCount]="deleteSublists.length"
          (change)="paginate($event, 'deletes')"
        ></fs-pagination-numbers>
      </ng-container>
    </c1-privacy-subheader-with-card>
  </ng-container>
</ng-container>

<c1-privacy-ineligible-request-dialog></c1-privacy-ineligible-request-dialog>
