import { Inject, Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { downloadApiOptions } from "../http-options"
import { catchError } from "rxjs/operators"
import { Router } from "@angular/router"
import { of } from "rxjs"
import { NewRelicService } from "../new-relic/new-relic.service"
import { ErrorLoggingLevel } from "src/app/enums/error-logging-level.enum"
import { retryWithBackoff } from "src/app/utils/operators/retryWithBackoff"
import { AppConfig, APP_CONFIG } from "src/app.config"
import { MaintenanceBannerService } from "../maintenance-banner/maintenance-banner.service"

@Injectable({
  providedIn: "root",
})
export class DownloadService {
  baseUrl: string
  maintenance: boolean = this.maintenanceBannerService.bannerIsValid()

  downloadFailure: boolean=false

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    private http: HttpClient,
    private router: Router,
    private newRelicService: NewRelicService,
    private maintenanceBannerService: MaintenanceBannerService
  ) {
    this.baseUrl = config.apiURL
  }

  downloadAsset(requestId: string) {

    return this.http.get(`${this.baseUrl}/retrieve/asset/${requestId}`, downloadApiOptions).pipe(
      retryWithBackoff((msg) =>
        this.newRelicService.logCustomError(msg, {
          logLevel: ErrorLoggingLevel.Error,
        })
      ),
      catchError((err) => {
        this.newRelicService.logCustomError(
          `Error in the GET call to /retrieve/asset/${requestId}. The download can be retried by user. ${JSON.stringify(
            err
          )}`,
          { logLevel: ErrorLoggingLevel.Error }
        )
        return of(err)
      })
    )
  }
}
