import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import { AppModule } from "./app/app.module"
import { APP_CONFIG, AppConfig } from "./app.config"
import { MaintenanceSettings } from "src/app/models/common/MaintenanceSettings"
import {MaintenanceTypes} from "./app/enums/maintenance-types";


fetch("./assets/config/environment.json")
  .then((res) => res.json())
  .then((config) => {
    if (config.production) {
      enableProdMode()
    }

    let maintenanceConfigBuilder: MaintenanceSettings =
      {'maintenanceType':'MAINTENANCE', 'maintenanceStart': 0, 'maintenanceDuration': 0, 'maintenancePreviewDuration': 0}

    fetch("./assets/config/maintenance.json")
    .then((res) => res.json())
    .then((maintenanceConfig) => {
      if (typeof(maintenanceConfig.maintenanceType) == 'string' && maintenanceConfig.maintenanceType.toUpperCase() as MaintenanceTypes){
        maintenanceConfigBuilder.maintenanceType = maintenanceConfig.maintenanceType.toUpperCase()
      }

      if (Number.isFinite(maintenanceConfig.maintenanceStart)){
        maintenanceConfigBuilder.maintenanceStart = maintenanceConfig.maintenanceStart
      }

      if (Number.isFinite(maintenanceConfig.maintenanceDuration)){
        maintenanceConfigBuilder.maintenanceDuration = maintenanceConfig.maintenanceDuration
      }

      if (Number.isFinite(maintenanceConfig.maintenancePreviewDuration)){
        maintenanceConfigBuilder.maintenancePreviewDuration = maintenanceConfig.maintenancePreviewDuration
      }
    }).catch ((err) => console.error(err)  // check in case maintenance.json is missing
    ).finally(() => {
      let appConfig : AppConfig = Object.assign(AppConfig, config, maintenanceConfigBuilder);
      platformBrowserDynamic([{ provide: APP_CONFIG, useValue: appConfig }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err))
    })
  })
